import { Button } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { getSubsManageList } from "apis/member/subs_manage_api";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import {
  generateObjectZeroId,
  getPermission,
  simpleAlert,
  SubLine,
} from "components/CommonLib/CommonLib";
import Loading from "components/Loading/Loading/Loading";
import _ from "lodash";
import { globalModalOff } from "modules/actions/Modal";
import { branch_search_init_state } from "pages/CommonModal/Search/Module/reducers/hq_branch_search_init_state";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSubsManageStore } from "./modules/store/subsManageState";
import SubsManageResultTable from "./ResultTable/SubsManageResultTable";
import DetailCondition from "./SearchCondition/DetailCondition";
import MemberInfoCondition from "./SearchCondition/MemberInfoCondition";

const SubsManageContent = () => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  // 전역 상태 관리
  const {
    subsManageSearchParams: searchParams,
    setSearchParams,
    subsManagePage: page,
    setPage,
    resetSearchParams,
  } = useSubsManageStore();

  // 페이지 상태 관리
  const [branchList, setBranchList] = useState(_.cloneDeep(branch_search_init_state));
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [resetFlag, setResetFlag] = useState(false);

  // 지점 권한 받아오기
  useEffect(() => {
    getPermission(storesDispatch, "SEARCH_MEMBER_MENU/SEARCH_MANAGE", true, true).then((res) => {
      setBranchList(res);
      if (
        res.branch_list.length > 0 &&
        searchParams.branch_info.branch_id === generateObjectZeroId()
      ) {
        setSearchParams("branch_info", res.branch_list[0]);
      }
    });
  }, []);
  // API 상태 관리
  const [isGetSubsManageList, setIsGetSubsManageList] = useState(false);
  const queryData = { page: page, searchParams: searchParams };

  // [GET] 정기결제 리스트 가져오기
  const { isFetching, data: subsManageList } = useQuery({
    queryKey: ["subsManage", queryData],
    queryFn: () => getSubsManageList(queryData),
    enabled: isGetSubsManageList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetSubsManageList(false);
    },
  });

  /** isExcel: 엑셀 다운로드 여부, page: 페이지네이션 */
  const onClickSearch = async (isExcel, page) => {
    setPage(page);
    // setIsExcel(isExcel);
    // 장기연기 리스트 받아오기
    setIsGetSubsManageList(true);
  };

  const onModalDone = (res) => {
    storesDispatch(globalModalOff());
  };

  const openModal = (order, param) => {
    // let Modal;
    // if (order === "save") {
    //   Modal = <SaveConditionModal modalParam={param} onModalDone={onModalDone} />;
    // } else if (order === "load") {
    //   Modal = <LoadConditionModal modalParam={param} onModalDone={onModalDone} />;
    // }
    // storesDispatch(
    //   globalModalOn({
    //     show: true,
    //     title: param.title,
    //     Content: Modal,
    //   }),
    // );
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 정기결제 리스트
    if (subsManageList) {
      setSearchResult(subsManageList.data.data);
      setTotalPage(subsManageList.data.total_page);
    }
  }, [subsManageList]);

  // 첫 페이지 진입 시 검색 결과를 가져오기
  useEffect(() => {
    setIsGetSubsManageList(true);
  }, []);

  return (
    <CardFixed
      midDom={
        <div>
          {isFetching && <Loading />}

          <div>
            <MemberInfoCondition
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              onClickSearch={onClickSearch}
            />
            <DetailCondition
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              branchList={branchList}
              resetFlag={resetFlag}
              onClickSearch={onClickSearch}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "0.75rem",
              }}
            >
              <Button
                className={clsx({
                  [btnStyleClass.btnRootWhite]: true,
                  [btnStyleClass.buttonType7]: true,
                })}
                onClick={() => {
                  resetSearchParams();
                  setResetFlag(!resetFlag);
                }}
              >
                조건 초기화
              </Button>
              <Button
                className={clsx({
                  [btnStyleClass.btnRoot]: true,
                  [btnStyleClass.buttonType6]: true,
                })}
                onClick={() => {
                  onClickSearch(false, 1);
                }}
                style={{ marginLeft: "0.5rem" }}
              >
                검색
              </Button>
            </div>
          </div>

          <SubLine />

          <div style={{ marginTop: "1rem" }}>
            <SubsManageResultTable
              searchResult={searchResult}
              curPage={page}
              setPage={setPage}
              totalPage={totalPage}
              onClickSearch={onClickSearch}
            />
          </div>
        </div>
      }
      isLoading={false}
      rootStyle={
        {
          // width: "80.437rem",
          // height: "74.875rem",
        }
      }
    />
  );
};
export default SubsManageContent;
