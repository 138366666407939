import { useQuery } from "@tanstack/react-query";
import { getTMListDetail } from "apis/member/tm_list_detail_api";
import CardFixed from "components/Card/CardFixed";
import { getDefaultValue, restEmpty, simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TmDetailModal from "../Modal/TmDetailModal";
import { useTMListDetailStore } from "./modules/store/tmListDetailState";
import SearchCondition from "./SearchCondition";
import TMListDetailResultTable from "./TMListDetailResultTable";

const TMListDetailView = ({ searchParams, setSearchParams }) => {
  const storesDispatch = useDispatch();

  // 전역 상태 관리
  const { tmListDetailPage: page, setPage, resetSearchParams } = useTMListDetailStore();

  // 페이지 상태 관리
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [resetFlag, setResetFlag] = useState(false);

  // API 상태 관리
  const [isGetTMListDetail, setIsGetTMListDetail] = useState(false);
  const queryData = { page: page, searchParams: searchParams };

  // [GET] TM 디테일 리스트 목록 가져오기
  const { isFetching, data: tmDetailListData } = useQuery({
    queryKey: ["tmDetailList", queryData],
    queryFn: () => getTMListDetail(queryData),
    enabled: isGetTMListDetail,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetTMListDetail(false);
    },
  });

  // TM 디테일 리스트 목록 검색
  const onClickSearch = async (page) => {
    setPage(page);
    setIsGetTMListDetail(true);
  };

  const onModalDone = (res) => {
    // console.log(res);
    storesDispatch(globalModalOff());
    onClickSearch(page);
  };

  const openModal = (order, param) => {
    let Modal;
    if (order === "tm") {
      Modal = <TmDetailModal modalParam={param} onModalDone={onModalDone} />;
    }
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: Modal,
      }),
    );
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // TM 디테일 리스트
    if (tmDetailListData && !restEmpty(tmDetailListData.data.data)) {
      setSearchResult(tmDetailListData.data.data);
      setTotalPage(tmDetailListData.data.total_page);
    }
  }, [tmDetailListData]);

  // 첫 페이지 진입 시 검색 결과를 가져오기
  useEffect(() => {
    setIsGetTMListDetail(true);
  }, []);

  return (
    <CardFixed
      midDom={
        <div>
          {isFetching && <Loading />}

          <div>
            <div style={{ display: "flex", margin: "1rem 1rem" }}>
              <div style={{ width: "25%" }}>
                <TextField
                  labelText="지점"
                  labelWidth="4.5rem"
                  labelMarginRight="0rem"
                  textMarginRight="0rem"
                  textBoxWidth="18rem"
                  defaultValue={getDefaultValue("field", searchParams.branch_info.branch_name)}
                  disabled
                />
              </div>
              <div style={{ width: "25%", display: "flex" }}>
                <TextField
                  labelText="등록일"
                  labelWidth="4.5rem"
                  labelMarginRight="0rem"
                  textMarginRight="0rem"
                  textBoxWidth="18rem"
                  defaultValue={getDefaultValue("field", searchParams.reg_date)}
                  disabled
                />
              </div>
              <div style={{ width: "50%" }}>
                <TextField
                  labelText="조건명"
                  labelWidth="4.5rem"
                  labelMarginRight="0rem"
                  textMarginRight="0rem"
                  fullWidth
                  defaultValue={getDefaultValue("field", searchParams.title)}
                  disabled
                />
              </div>
            </div>
            <SearchCondition
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              onClickSearch={onClickSearch}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "0.75rem",
                gap: "0.3rem",
              }}
            >
              <NormalBtn
                name="초기화"
                theme="gray"
                onClick={() => {
                  resetSearchParams();
                  setResetFlag(!resetFlag);
                }}
              />
              <NormalBtn
                name="검색"
                onClick={() => {
                  onClickSearch(1);
                }}
              />
            </div>
          </div>

          <SubLine />

          <div style={{ marginTop: "1rem" }}>
            <TMListDetailResultTable
              openModal={openModal}
              searchResult={searchResult}
              curPage={page}
              totalPage={totalPage}
              onClickSearch={onClickSearch}
              branchInfo={searchParams.branch_info}
            />
          </div>
        </div>
      }
      isLoading={false}
      rootStyle={{}}
    />
  );
};
export default TMListDetailView;
