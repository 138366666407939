import SubTitle from "components/PageTitle/SubTitle";
import PageFrame from "components/Panels/PageFrame";
// import RapsButton from "components/ButtonStyle/RapsButton";
const MainIndex = () => {
  // console.log("MainIndex rerender...");
  // const authData = useOutletContext();

  // const getAuthData = async () => {
  //   return await get_api.getPermissionAuth();
  // };

  // const { data } = getAuthData();
  // const [tdata, setTdata] = useState({
  //   prevState,
  // });
  // console.log(authData);
  // console.log(authStoreData);

  // const currentState = useSelector(
  //   (state) => state.global_auth.auth_permission_state,
  //   (prevState, currState) => {
  //     return !(prevState.stores.data === currState.stores.data);
  //     // return isRender(currState);
  //   },
  // );

  // const selectCompletedPermissionState = useMemo(makeSelectCompletedPermissionState, []);
  // const permissionComplete = useSelector((state) => state.global_auth.auth_permission_state);
  // const permissionState = useSelector((state) => selectCompletedPermissionState(state));
  // console.log(permissionComplete);
  // console.log(permissionState);
  // console.log(restData);

  // console.log(tdata);
  return (
    <div>
      <PageFrame
        isMain
        menuActive="락커/셋팅"
        parentMenu={{ 락커: true }}
        children={
          <div style={{ border: "2px solid red" }}>
            <SubTitle titleText="The page is under maintenance.." fontSize="3rem" />
            {/* <ExpandableRowTable /> */}
          </div>
        }
      />
    </div>
  );
};
export default MainIndex;
