import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import { useEffect } from "react";

// 당월 수업 진행 내역
const MonthlyClassRecordList = ({
  openList,
  setOpenList,
  classRecordList,
  popChangeSessionPriceModal,
}) => {
  const dataTableItem = new proceedClassTable(popChangeSessionPriceModal);

  // console.log("classList", classList);

  useEffect(() => {
    // 직원 클릭 시 화면 가장 하단으로 이동 (스무스하게)
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  }, [classRecordList]);

  return (
    <>
      <div
        style={{
          display: "flex",
          backgroundColor: `${openList[1] ? "#4C679D" : "#E8EDF7"}`,
          cursor: "pointer",
          alignItems: "center",
          boxSizing: "border-box",
        }}
        onClick={() => {
          setOpenList((cur) => {
            const arr = [...cur];
            arr[1] = !arr[1];
            return arr;
          });
        }}
      >
        <div
          style={{
            paddingLeft: "0.7rem",
            width: "100%",
            fontWeight: "bold",
            color: `${openList[1] ? "#FFFFFF" : "#032063"}`,
            textAlign: "left",
            fontSize: "0.9rem",
          }}
        >
          당월 수업 진행 내역
        </div>
        <div style={{ width: "2rem" }}>
          {!openList[1] ? (
            <KeyboardArrowDownIcon
              style={{ color: "#777777", fontSize: "1.7rem" }}
              onClick={() => {
                setOpenList((cur) => {
                  const arr = [...cur];
                  arr[1] = true;
                  return arr;
                });
              }}
            />
          ) : (
            <KeyboardArrowUpIcon
              style={{ color: "#FFFFFF", fontSize: "1.7rem" }}
              onClick={() => {
                setOpenList((cur) => {
                  const arr = [...cur];
                  arr[1] = false;
                  return arr;
                });
              }}
            />
          )}
        </div>
      </div>

      <Collapse in={openList[1]} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <CustomTable
            columns_head={dataTableItem.columns_head}
            table_title={dataTableItem.table_title}
            rest_call={dataTableItem.get_data_from_rest}
            row_render={dataTableItem.create_table}
            rest_data={classRecordList}
            maxHeight={"28rem"}
          />
        </Box>
      </Collapse>
    </>
  );
};
export default MonthlyClassRecordList;

class proceedClassTable {
  table_title = "";
  onClickChangeBtn = null;

  constructor(popChangeSessionPriceModal) {
    this.onClickChangeBtn = popChangeSessionPriceModal;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.pt_info.item_name}</CustomTableContents>
        <CustomTableContents>
          {data.pt_info.pt_price.per_price.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents>{data.pt_info.base_session_cnt}</CustomTableContents>
        <CustomTableContents>{data.pt_info.service_session_cnt}</CustomTableContents>
        <CustomTableContents>
          {data.pt_info.total_session_price.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents>
          {data.class_info.pt_pay_out.total_payout_price.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents> {data.enter_cnt ? data.enter_cnt : ""} </CustomTableContents>
        <CustomTableContents align="center">{data.class_info.memo}</CustomTableContents>
        <CustomTableContentsEND align="center" width="5%">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              type="session"
              width="3rem"
              onClick={(e) => {
                this.onClickChangeBtn(data.pt_info.pt_detail_id);
              }}
            />
          </div>
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "회원번호", width: "8%" },
    { title: "회원명", width: "9%" },
    { title: "계약상품명", width: "19%" },
    { title: "계약 세션가", width: "9%" },
    { title: "기본세션", width: "6%" },
    { title: "서비스 세션", width: "6%" },
    { title: "진행 세션가 합계", width: "9%" },
    { title: "지급금액", width: "9%" },
    { title: "당월 출입여부", width: "9%" },
    { title: "비고", width: "18%" },
    { title: "세션가 변경", width: "7%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
