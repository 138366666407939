import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { restEmpty } from "components/CommonLib/CommonLib";
import { useEffect, useState } from "react";

// MUI 커스텀
const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
            padding: "0.5px 4px 2.5px 8px",
          },
          ".MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
            right: "7px",
          },
        },
        option: {
          fontFamily: "AppleSDGothicNeo",
          fontSize: "0.9rem",
          color: "#000000",
        },
        noOptions: {
          fontSize: "0.9rem",
        },
        listbox: {
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: "#d3d9f3",
            borderRadius: "5px",
          },
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: "#d3d9f3",
            borderRadius: "5px",
          },
          "& .MuiAutocomplete-option.Mui-focused": {
            backgroundColor: "#eaedf7",
            borderRadius: "5px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: "AppleSDGothicNeo",
          fontSize: "0.9rem",
          borderRadius: "5px",
          color: "#000000",
        },
        sizeSmall: {
          height: "2.25rem",
        },
      },
    },
  },
});

const SearchComboBox = (props) => {
  const [value, setValue] = useState(props.defaultValue);

  const handleChange = (event, value) => {
    // 선택한 옵션 index 값 찾아서 return
    let selectedIndex;
    if (!restEmpty(props.data)) {
      selectedIndex = props.data.findIndex((branchName) => branchName === value);
    }

    if (props.onChangeCallback !== undefined) {
      props.onChangeCallback(event, value, selectedIndex);
      setValue(value);
    }
  };

  useEffect(() => {
    props.defaultValue ? setValue(props.defaultValue) : setValue("");
  }, [props.defaultValue]);

  const hStyle = {
    display: "flex",
    alignItems: "center",
    width: props.fullWidth ? "100%" : null,
    marginTop: props.marginTop ? props.marginTop : "0rem",
    marginLeft: props.marginLeft ? props.marginLeft : "0rem",
    marginRight: props.marginRight ? props.marginRight : "0.625rem",
  };

  const labelStyle = {
    width: props.labelWidth,
    marginRight: props.labelMarginRight === undefined ? "0.625rem" : props.labelMarginRight,
    marginLeft: props.labelMarginLeft,
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  };

  return (
    <div style={hStyle}>
      <div style={labelStyle}>{props.labelText}</div>
      <ThemeProvider theme={theme}>
        <Stack
          sx={{
            width: props.fullWidth ? "100%" : props.comboItemWidth,
            backgroundColor: "#fff",
            borderRadius: "5px",
          }}
        >
          <Autocomplete
            disableClearable
            autoHighlight={true}
            size="small"
            value={value}
            openOnFocus={true}
            options={props.data}
            defaultValue={props.defaultValue ? props.defaultValue : props.data[0]}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => {
              // value는 "전체" or "" 인데 옵션 리스트에 "전체"값이 없을 때 경고문 제외
              if ((value === "전체" || value === "") && !props.data.includes("전체")) {
                return true;
              }

              return option === value;
            }}
            onChange={handleChange}
            renderInput={(params) => {
              return <TextField {...params} placeholder={props.placeHolder} size="small" />;
            }}
            disabled={props.disabled}
          />
        </Stack>
      </ThemeProvider>
    </div>
  );
};

export default SearchComboBox;
