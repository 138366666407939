import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { searchStaffParamInit } from "pages/CommonModal/Search/Module/reducers/staff_search_params";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const SearchStaffModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const [searchParam, setSearchParam] = useState(searchStaffParamInit);
  const [staffSearchResult, setStaffSearchResult] = useState([]);
  const [hqIdx, setHqIdx] = useState(0);
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const phone1Ref = useRef("");
  const phone2Ref = useRef("");
  const phone3Ref = useRef("");

  const onClickSearch = async (page) => {
    setCurPage(page);
    try {
      const formData = new FormData();
      formData.append("page_num", curPage);
      formData.append("search_param", JSON.stringify(searchParam));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_STAFF_SERVER + "/staff/search_modal",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        setStaffSearchResult(res.data.data);
        setTotalPage(res.data.total_page);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onSelectStaff = (data, name) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText={`${name}을 선택하시겠습니까?`} />
          </>
        ),

        onBtnYes: () => {
          onModalDone(data);
        },
        onBtnNo: () => {},
      }),
    );
  };

  //페이지네이션
  useEffect(() => {
    if (modalParam.hq_list && modalParam.hq_list.length > 0) {
      setSearchParam((cur) => {
        return { ...cur, hq_info: modalParam.target_list[0].hq_info };
      });
    }

    if (curPage) {
      onClickSearch(curPage);
    }
  }, [curPage]);

  const searchResultData = new searchResultTable(onSelectStaff);

  return (
    <div style={{ width: "42rem" }}>
      {loading && <Loading />}

      <div>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="직원 검색" />
        </div>
        <div>
          <div style={{ display: "flex", gap: "1.5rem" }}>
            <ComboBox
              labelWidth={"3rem"}
              comboItemWidth={"12rem"}
              labelText="본부"
              data={modalParam.hq_list}
              defaultValue={modalParam.hq_list.length !== 0 && modalParam.hq_list[hqIdx]}
              onChangeCallback={(e) => {
                const idx = modalParam.hq_list.findIndex((el) => el === e.target.value);
                if (idx !== -1) {
                  setHqIdx(idx);
                }
                const hqInfo = modalParam.target_list[idx].hq_info;
                setSearchParam((cur) => {
                  return { ...cur, hq_info: hqInfo };
                });
              }}
            />
            <ComboBox
              labelWidth={"3rem"}
              comboItemWidth={"14rem"}
              labelText="지점"
              data={modalParam.branch_list[hqIdx]}
              onChangeCallback={(e) => {
                const idx = modalParam.branch_list[hqIdx].findIndex((el) => el === e.target.value);
                if (idx !== -1) {
                  const branchInfo = modalParam.target_list[hqIdx].branch_list[idx];
                  setSearchParam((cur) => {
                    return { ...cur, branch_info: branchInfo };
                  });
                }
              }}
            />
          </div>
          <div style={{ display: "flex", marginTop: "0.5rem", gap: "1.5rem" }}>
            <TextField
              labelWidth={"3rem"}
              textBoxWidth={"12rem"}
              labelText="직원명"
              onChangeCallback={(e) => {
                setSearchParam((cur) => {
                  return { ...cur, staff_name: e };
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onClickSearch(1);
                }
              }}
            />
            <div style={{ display: "flex" }}>
              <TextField
                labelWidth={"3rem"}
                textBoxWidth={"4rem"}
                textMarginRight="0.25rem"
                labelText="연락처"
                defaultValue={phone1Ref.current}
                maxLength={3}
                onChangeCallback={(e) => {
                  phone1Ref.current = e;
                }}
              />
              <TextField
                textBoxWidth={"4rem"}
                textMarginRight="0.25rem"
                labelMarginRight="0.25rem"
                labelText="-"
                maxLength={4}
                defaultValue={phone2Ref.current}
                onChangeCallback={(e) => {
                  phone2Ref.current = e;
                }}
              />
              <TextField
                textBoxWidth={"4rem"}
                labelMarginRight="0.25rem"
                labelText="-"
                maxLength={4}
                defaultValue={phone3Ref.current}
                onChangeCallback={(e) => {
                  phone3Ref.current = e;
                  const phone = phone1Ref.current + "-" + phone2Ref.current + "-" + e;
                  setSearchParam((cur) => {
                    return { ...cur, phone };
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onClickSearch(1);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
          <Button
            style={{ transform: "translateX(-0.7rem)" }}
            className={clsx({
              [btnStyleClass.btnRootWhite]: true,
              [btnStyleClass.buttonType3]: true,
            })}
            onClick={() => {
              onClickSearch(1);
            }}
          >
            검색
          </Button>
        </div>
      </div>
      <div style={{ marginTop: "1rem" }}>
        <CustomTable
          columns_head={searchResultData.columns_head}
          table_title={searchResultData.table_title}
          rest_call={searchResultData.get_data_from_rest}
          row_render={searchResultData.create_table}
          rest_data={staffSearchResult}
        />
        <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} />
      </div>
    </div>
  );
};

export default SearchStaffModal;

class searchResultTable {
  table_title = "";
  onSelectStaff = null;

  constructor(onSelectStaff) {
    this.onSelectStaff = onSelectStaff;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow
        key={idx}
        onClick={() => {
          this.onSelectStaff(data, data.personal_info.staff_name);
        }}
        hover
      >
        <CustomTableContents>{data.base_info.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.base_info.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.personal_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.base_info.position.org_name}</CustomTableContents>
        <CustomTableContentsEND align="center">{data.personal_info.phone}</CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "본부", width: "20%" },
    { title: "지점", width: "20%" },
    { title: "직원명", width: "20%" },
    { title: "직책", width: "20%" },
    { title: "연락처", width: "20%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
