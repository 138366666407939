import { generateObjectZeroId, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import Navbar from "components/NavMenu/Navbar";
import { permissionInitData } from "components/Panels/permissionInitData";
import Sidebar from "components/Sidebar/Sidebar";
import { HttpInstance, RefreshPublicIpHeader } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { SetAuthTokens } from "../../lib/HttpLib";
import { getAuthInfoStores } from "../../modules/actions/Auth/index";

const PrivateRoute = ({ auth_info }) => {
  const storesDispatch = useDispatch();
  const [permissionState, setPermissionState] = useState(permissionInitData);

  useEffect(() => {
    getAuthData();
  }, []);

  // 권한에 따른 메뉴 검증
  const getAuthData = async () => {
    try {
      await RefreshPublicIpHeader();

      const permData = await HttpInstance.get(
        process.env.REACT_APP_AUTH_SERVER + "/global/perm_auth",
        {},
      );
      if (!restEmpty(permData.data.data)) {
        //
        // TEMPORAL AUTH. (실적관리 권한 = 급여관리 권한)
        //
        if (permData.data.data && permData.data.data.side_menu.branch_menu.children) {
          const branchMenuList = permData.data.data.side_menu.branch_menu.children;
          const salaryIdx = branchMenuList.findIndex((x) => x.menu_title === "급여 관리");
          if (salaryIdx !== -1) {
            if (branchMenuList[salaryIdx].menu_active) {
              const recordIdx = branchMenuList.findIndex((x) => x.menu_title === "실적관리");
              if (recordIdx !== -1) {
                branchMenuList[recordIdx].menu_active = true;
              }
            }
          }
        }

        //
        // StaffInfo를 가져와서 permData에 staffName을 추가해준다 (환영메시지에 이름 띄우기 위함)
        //
        const staffId = permData.data.data.staff_id;
        let staffName = "";
        let authId = "";
        if (permData.data.data.staff_id === generateObjectZeroId()) {
          if (permData.data.data.is_admin) {
            staffName = "관리자";
            authId = "admin";
          } else {
            staffName = "";
            authId = "";
          }
        } else {
          if (staffId) {
            const staffInfo = await HttpInstance.get(
              process.env.REACT_APP_STAFF_SERVER + "/staff/get_staff_info",
              {
                params: {
                  staff_id: staffId,
                },
              },
            );
            if (!restEmpty(staffInfo.data.data)) {
              staffName = staffInfo.data.data.contents.staff_obj.personal_info.staff_name;
              authId = staffInfo.data.data.contents.auth_id;
            }
          }
        }
        permData.data.data.staff_name = staffName;
        permData.data.data.auth_id = authId;
        setPermissionState(permData.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  // 로그인 인증 여부
  const auth = () => {
    if (auth_info.stores === undefined) {
      return false;
    }
    if (auth_info.stores === null) {
      return false;
    }
    if (auth_info.stores.auth_info !== undefined) {
      if (auth_info.stores.auth_info.stores === null) {
        return false;
      }
    }

    SetAuthTokens(auth_info.stores);

    return true;
  };

  const retAuth = auth();

  return retAuth ? (
    <>
      <Navbar permissionState={permissionState} />
      <Sidebar permissionState={permissionState} />
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

function mapStateToProps(state) {
  return {
    auth_info: state.auth_info,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAuthStore: () => dispatch(getAuthInfoStores()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
