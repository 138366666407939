import {
  calMonthFirstAndLast,
  dataEmpty,
  getPermission,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import LockerDetailModal from "./LockerDetailModal";

const LockerRecoverModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const viewModelRef = useRef();

  const textBoxWidth = "12rem";

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const [recoveredList, setRecoveredList] = useState([]);
  const [selectedDate, setSelectedDate] = useState({
    from_date: calMonthFirstAndLast(new Date(), "first"),
    to_date: calMonthFirstAndLast(new Date(), "last"),
  });
  const [canExcel, setCanExcel] = useState(false);

  //락커상세모달 POP
  const popLockerDetailModal = (param) => {
    setModalInfo({
      show: true,
      title: "락커 상세보기",
      Content: <LockerDetailModal modalParam={param} onModalDone={lockerDetailModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };
  //락커상세모달 DONE
  const lockerDetailModalDone = () => {
    viewModelRef.current.setClose();
  };

  const getRecoveredList = async (curPage) => {
    setCurPage(curPage);
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_BRANCH_SERVER + "/locker/recover_list",
        {
          params: {
            branch_id: modalParam && modalParam.selectedBranch.branch_id,
            page_num: curPage,
            from_date: selectedDate.from_date,
            to_date: selectedDate.to_date,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setRecoveredList(res.data.data);
        setTotalPage(res.data.total_page);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  const checkPermission = async () => {
    const result = await getPermission(storesDispatch, "ACCOUNT_PERMISSION/EXCEL");
    setCanExcel(result);
  };

  //첫 랜더링때 기본 날짜로 랜더링
  useEffect(() => {
    if (modalParam && modalParam.selectedBranch.branch_name) {
      getRecoveredList(1);
      checkPermission();
    }
  }, [modalParam]);

  //페이지네이션
  useEffect(() => {
    if (curPage) {
      getRecoveredList(curPage);
    }
  }, [curPage]);

  const refresh = (branchId, floorId, zoneId) => {
    if (modalParam) {
      modalParam.refreshView(branchId, floorId, zoneId);
      getRecoveredList(1);
      lockerDetailModalDone();
    }
  };

  const unSignList = new unSignTable(
    popLockerDetailModal,
    modalParam && modalParam.navigate,
    refresh,
  );
  return (
    <div
      style={{
        width: "50rem",
      }}
    >
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <DatePicker
            textBoxWidth={textBoxWidth}
            labelWidth="3.5rem"
            labelText="종료일"
            defaultValue={selectedDate.from_date}
            onChangeCallback={(e) => {
              if (selectedDate.to_date && new Date(e) > new Date(selectedDate.to_date)) {
                storesDispatch(
                  globalAlertOn({
                    show: true,
                    titleText: "알림",
                    bodyText: (
                      <>
                        <Label labelText="시작일은 종료일보다 클 수 없습니다." />
                      </>
                    ),
                    onBtnOk: () => {},
                  }),
                );
                e = "";
              } else {
                setSelectedDate((cur) => {
                  const newObj = { ...cur };
                  newObj.from_date = e;
                  return newObj;
                });
              }
            }}
          />

          <DatePicker
            textBoxWidth={textBoxWidth}
            labelText="~"
            defaultValue={selectedDate.to_date}
            onChangeCallback={(e) => {
              if (selectedDate.from_date && new Date(selectedDate.from_date) > new Date(e)) {
                storesDispatch(
                  globalAlertOn({
                    show: true,
                    titleText: "알림",
                    bodyText: (
                      <>
                        <Label labelText="시작일은 종료일보다 클 수 없습니다." />
                      </>
                    ),
                    onBtnOk: () => {},
                  }),
                );
                e = "";
              } else {
                setSelectedDate((cur) => {
                  const newObj = { ...cur };
                  newObj.to_date = e;
                  return newObj;
                });
              }
            }}
          />
          <NormalBtn
            name="검색"
            onClick={() => {
              getRecoveredList(1);
            }}
          />
        </div>
        {canExcel && (
          <NormalBtn
            name="엑셀 다운로드"
            theme="green"
            onClick={() => {
              storesDispatch(
                globalAlertOn({
                  show: true,
                  titleText: "알림",
                  bodyText: (
                    <>
                      <Label labelText="서비스 준비중입니다." />
                    </>
                  ),
                  onBtnOk: () => {},
                }),
              );
            }}
          />
        )}
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <CustomTable
          columns_head={unSignList.columns_head}
          table_title={unSignList.table_title}
          rest_call={unSignList.get_data_from_rest}
          row_render={unSignList.create_table}
          rest_data={recoveredList}
        />
        {/* <CustomPagination curPage={curPage} doSearch={setCurPage} totalCnt={totalPage} /> */}
      </div>
    </div>
  );
};
export default LockerRecoverModal;

class unSignTable {
  table_title = "";
  popLockerDetailModal = null;
  navigate = null;
  refreshView = null;

  constructor(popLockerDetailModal, navigate, refreshView) {
    this.popLockerDetailModal = popLockerDetailModal;
    this.navigate = navigate;
    this.refreshView = refreshView;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow
        key={idx}
        hover
        onClick={() => {
          this.popLockerDetailModal({
            branch_id: data.branch_info.branch_id,
            floor_id: data.floor_id,
            zone_id: data.zone_id,
            floor_name: data.floor_name,
            zone_name: data.zone_name,
            locker_total_idx: data.locker_info.locker_total_idx,
            locker_no: data.locker_no,
            navigate: this.navigate,
            refreshView: this.refreshView,
            isFromMemPage: false,
          });
        }}
      >
        <CustomTableContents>{data.floor_name}</CustomTableContents>
        <CustomTableContents>{data.zone_name}</CustomTableContents>
        <CustomTableContents>{data.locker_no}</CustomTableContents>
        <CustomTableContents>{data.locker_info.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.locker_info.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.locker_info.member_info.phone}</CustomTableContents>
        <CustomTableContents>{data.mbshp_to_date}</CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "층", width: "8%" },
    { title: "구역", width: "17%" },
    { title: "락커번호", width: "12%" },
    { title: "회원번호", width: "13%" },
    { title: "회원명", width: "15%" },
    { title: "연락처", width: "20%" },
    { title: "사용 종료일", width: "15%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
