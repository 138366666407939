import { Button } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { getUnspentList } from "apis/branch/unspent_api";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import {
  checkDateOrder,
  dataEmpty,
  generateObjectZeroId,
  getDefaultValue,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import UnspentDetailModal from "../CashBookList/Modal/UnspentDetailModal";
import { useUnspentStore } from "./modules/store/unspentState";

const UnspentListView = ({ hqBranchCombo }) => {
  const leftMargin = "3rem";
  const compWidth = "14rem";
  const labelWidth = "3.5rem";

  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  // 전역 상태 관리
  const {
    unspentSearchParams: searchParams,
    setSearchParams,
    unspentPage: page,
    setPage,
    unspentHqIdx: hqIdx,
    setHqIdx,
  } = useUnspentStore();

  // 페이지 상태 관리
  const [searchResult, setSearchResult] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);

  // API 상태 관리
  const [isGetUnspentList, setIsGetUnspentList] = useState(false);
  const queryData = { page: page, searchParams: searchParams };

  // [GET] 미지출 내역 리스트 가져오기
  const { isFetching, data: unspentList } = useQuery({
    queryKey: ["unspent", queryData],
    queryFn: () => getUnspentList(queryData),
    enabled: isGetUnspentList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetUnspentList(false);
    },
  });

  const onClickSearch = async (page) => {
    setPage(page);
    // 미지출 내역 가져오기
    setIsGetUnspentList(true);
  };

  const onModalDone = (res) => {
    storesDispatch(globalModalOff());
    onClickSearch(page);
  };

  const onClickRow = (data) => {
    const param = {
      docType: data.approval_type,
      approvalDocsId: data.approval_docs_id,
      docId: data.doc_info.doc_id,
    };

    storesDispatch(
      globalModalOn({
        show: true,
        title: "미지출 상세보기",
        Content: <UnspentDetailModal modalParam={param} onModalDone={onModalDone} />,
      }),
    );
  };

  const getTotalPrice = () => {
    return searchResult.reduce((sum, cur) => {
      return sum + cur.expenditure_info.total_price;
    }, 0);
  };

  useEffect(() => {
    // 첫 검색 조건 세팅
    if (hqBranchCombo && hqBranchCombo.target_list.length > 0) {
      if (searchParams.hq_info.hq_id === generateObjectZeroId()) {
        setSearchParams("hq_info", hqBranchCombo.target_list[0].hq_info);
        setSearchParams("branch_info", hqBranchCombo.target_list[0].branch_list[0]);
        setSearchParams("combo_selector", hqBranchCombo);
      }
      setBranchList(hqBranchCombo.branch_list[hqIdx]);
    }
  }, [hqBranchCombo]);

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 미지출 내역 리스트
    if (unspentList) {
      setSearchResult(unspentList.data.data);
      setTotalPage(unspentList.data.total_page);
    }
  }, [unspentList]);

  // 본부 선택 시 지점 정보 첫번째 값 세팅
  useEffect(() => {
    if (!isFirstEnterPage) {
      setSearchParams("branch_info", hqBranchCombo.target_list[hqIdx].branch_list[0]);
    }
  }, [hqIdx]);

  // 첫 페이지 진입 시 검색 결과를 가져오기
  useEffect(() => {
    setIsGetUnspentList(true);
    setIsFirstEnterPage(false);
  }, []);

  const searchResultList = new resultTable(onClickRow);

  return (
    <div>
      <CardFixed
        midDom={
          <div>
            {isFetching && <Loading />}

            <div
              style={{ display: "flex" }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onClickSearch(1);
                }
              }}
            >
              <ComboBox
                labelText="본부"
                labelWidth={labelWidth}
                labelMarginRight="0rem"
                comboItemWidth={compWidth}
                data={hqBranchCombo && hqBranchCombo.hq_list}
                defaultValue={getDefaultValue("combo", searchParams.hq_info.hq_name)}
                onChangeCallback={(e, idx) => {
                  setHqIdx(idx);
                  setSearchParams("hq_info", hqBranchCombo.target_list[idx].hq_info);
                }}
              />
              <SearchComboBox
                marginLeft={leftMargin}
                labelText="지점"
                labelWidth={labelWidth}
                labelMarginRight="0rem"
                comboItemWidth={compWidth}
                data={branchList}
                defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
                onChangeCallback={(e, v, idx) => {
                  setSearchParams("branch_info", hqBranchCombo.target_list[hqIdx].branch_list[idx]);
                }}
              />
              <DatePicker
                marginLeft={leftMargin}
                labelText="기안일"
                labelWidth={labelWidth}
                textBoxWidth="11rem"
                labelMarginRight="0rem"
                textMarginRight="0rem"
                defaultValue={getDefaultValue("field", searchParams.draft_from_date)}
                onChangeCallback={(e) => {
                  setSearchParams("draft_from_date", e);
                }}
                onBlur={(e) => {
                  if (!checkDateOrder(e, searchParams.draft_to_date)) {
                    setSearchParams("draft_from_date", "");
                  }
                }}
              />
              <DatePicker
                marginLeft="0.25rem"
                labelWidth="0.5rem"
                textBoxWidth="11rem"
                labelMarginRight="0.25rem"
                labelText="~"
                defaultValue={getDefaultValue("field", searchParams.draft_to_date)}
                onChangeCallback={(e) => {
                  setSearchParams("draft_to_date", e);
                }}
                onBlur={(e) => {
                  if (!checkDateOrder(searchParams.draft_from_date, e)) {
                    setSearchParams("draft_to_date", "");
                  }
                }}
              />

              <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <Button
                  className={clsx({
                    [btnStyleClass.btnRoot]: true,
                    [btnStyleClass.buttonType6]: true,
                  })}
                  onClick={() => onClickSearch(1)}
                >
                  검색
                </Button>
              </div>
            </div>

            <div style={{ marginTop: "1.5rem" }}>
              <CustomTable
                columns_head={searchResultList.columns_head}
                table_title={searchResultList.table_title}
                rest_call={searchResultList.get_data_from_rest}
                row_render={searchResultList.create_table}
                rest_data={searchResult}
                height="33rem"
              />
              <CustomPagination curPage={page} totalCnt={totalPage} doSearch={onClickSearch} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: `${isFirstEnterPage && restEmpty(unspentList) ? "-3rem" : ""}`,
              }}
            >
              <TextField
                labelText="미지출 내역 총 합계"
                labelWidth="8rem"
                textBoxWidth="10rem"
                textMarginRight="0rem"
                endAdornment="원"
                isNumber
                disabled
                defaultValue={getTotalPrice().toLocaleString()}
              />
            </div>
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "100%",
          height: "29rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};
export default UnspentListView;

//

class resultTable {
  table_title = "";
  onClickRow = null;

  constructor(onClickRow) {
    this.onClickRow = onClickRow;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{}}
        key={idx}
        onClick={(e) => {
          if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
            this.onClickRow(data);
          }
        }}
        hover
      >
        <CustomTableContents>{data.approve_draft_date}</CustomTableContents>
        <CustomTableContents>{data.writer.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.writer.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.writer.staff_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.approval_status}</CustomTableContents>
        <CustomTableContents>{data.approve_done_date}</CustomTableContents>
        <CustomTableContents>{data.doc_info.doc_name}</CustomTableContents>
        <CustomTableContents>{data.doc_title}</CustomTableContents>
        <CustomTableContents $isNumber>
          {data.expenditure_info.total_price.toLocaleString() + " 원"}
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "기안일시", width: "5.5%" },
    { title: "본부", width: "8%" },
    { title: "지점", width: "8%" },
    { title: "기안자", width: "6%" },
    { title: "승인상태", width: "6%" },
    { title: "승인일시", width: "5.5%" },
    { title: "서류명", width: "9%" },
    { title: "제목", width: "13%" },
    { title: "총 금액", width: "7%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
