import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import { useEffect, useState } from "react";

const JoinPathSalesCntTable = ({ reportState }) => {
  const newResultTableInst = new resultTable();
  const reRegResultTableInst = new resultTable();

  const [totalCntReg, setTotalCntReg] = useState(0);
  const [totalCntReReg, setTotalCntReReg] = useState(0);

  useEffect(() => {
    const sumReg = reportState.mbshp_join_way.reduce((sum, cur) => {
      return sum + cur.cnt;
    }, 0);
    setTotalCntReg(sumReg);

    const sumReReg = reportState.mbshp_re_join_way.reduce((sum, cur) => {
      return sum + cur.cnt;
    }, 0);
    setTotalCntReReg(sumReReg);
  }, [reportState]);

  return (
    <div>
      <div style={{ display: "flex", marginBottom: "0.5rem" }}>
        <IconButton type="border" />
        <Label labelText="등록 경로별 판매 수량" marginLeft="0.5rem" fontWeight="bold" />
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ width: "50%", paddingRight: "0.5rem" }}>
          <CustomTable
            columns_head={[
              { title: "신규등록 가입 경로", width: "10%" },
              { title: "인원", width: "10%" },
            ]}
            table_title={newResultTableInst.table_title}
            rest_call={newResultTableInst.get_data_from_rest}
            row_render={newResultTableInst.create_table}
            rest_data={reportState.mbshp_join_way}
            height="15rem"
          />
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
            <TextField
              labelText="합계"
              labelWidth="3rem"
              textBoxWidth="10rem"
              isNumber
              defaultValue={totalCntReg.toLocaleString()}
              disabled
            />
          </div>
        </div>

        <div style={{ width: "50%", paddingLeft: "0.5rem" }}>
          <CustomTable
            columns_head={[
              { title: "재등록 가입 경로", width: "10%" },
              { title: "인원", width: "10%" },
            ]}
            table_title={reRegResultTableInst.table_title}
            rest_call={reRegResultTableInst.get_data_from_rest}
            row_render={reRegResultTableInst.create_table}
            rest_data={reportState.mbshp_re_join_way}
            height="15rem"
          />
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
            <TextField
              labelText="합계"
              labelWidth="3rem"
              textBoxWidth="10rem"
              textMarginRight="0rem"
              isNumber
              defaultValue={totalCntReReg.toLocaleString()}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default JoinPathSalesCntTable;

class resultTable {
  table_title = "";

  // constructor() {  }

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents $bgColor="#dfebf3">{data.join_type}</CustomTableContents>
        <CustomTableContents>{data.cnt}</CustomTableContents>
      </CustomRow>
    );
  };

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
