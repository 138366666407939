import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";

const SearchResultTableLocker = ({
  searchResult,
  navigate,
  curPage,
  setCurPage,
  totalPage,
  onClickSearch,
}) => {
  const searchResultList = new resultTable(navigate);

  const doSearch = (page) => {
    onClickSearch(false, page);
  };

  return (
    <div style={{ margin: "0px 1px" }}>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        width="120rem"
      />
      <CustomPagination
        curPage={curPage}
        // pageSelection={setCurPage}
        totalCnt={totalPage}
        doSearch={doSearch}
      />
    </div>
  );
};
export default SearchResultTableLocker;

class resultTable {
  table_title = "";
  onNavigate = null;

  constructor(navigate) {
    this.onNavigate = navigate;
  }

  onClickEvent = (data) => {
    // this.onNavigate("/staff_info/", {
    //   state: {
    //     staff_id: data.staff_id,
    //     is_view: true,
    //   },
    // });
  };

  create_table = (data, idx) => {
    return (
      <>
        <CustomRow
          style={{}}
          key={idx}
          onClick={() => this.onClickEvent(data)}
          // sx={{
          //   "&.MuiTableRow-root:hover": {
          //     backgroundColor: "red",
          //   },
          // }}
          // className={this.styleClasses.MuiTableRow}
          hover
        >
          <CustomTableContents rowSpan={data.sub_result.length + 1}>
            {data.base_result.branch_info.branch_name}
          </CustomTableContents>
          <CustomTableContents rowSpan={data.sub_result.length + 1}>
            {data.base_result.member_type}
          </CustomTableContents>
          <CustomTableContents rowSpan={data.sub_result.length + 1}>
            {data.base_result.member_info.member_no}
          </CustomTableContents>
          <CustomTableContents rowSpan={data.sub_result.length + 1}>
            {data.base_result.member_info.member_name}
          </CustomTableContents>
          <CustomTableContents rowSpan={data.sub_result.length + 1}>
            {data.base_result.member_info.phone}
          </CustomTableContents>
          <CustomTableContents rowSpan={data.sub_result.length + 1}>
            {data.base_result.member_info.birth}
          </CustomTableContents>
          <CustomTableContents rowSpan={data.sub_result.length + 1}>
            {data.base_result.member_info.gender}
          </CustomTableContents>
          <CustomTableContents rowSpan={data.sub_result.length + 1}>
            {data.base_result.active_mbshp_status}
          </CustomTableContents>
          <CustomTableContents rowSpan={data.sub_result.length + 1}>
            {data.base_result.last_mbshp_to_date}
          </CustomTableContents>
        </CustomRow>
        {data.sub_result.map((childData, idx2) => {
          return (
            <CustomRow key={idx2}>
              <CustomTableContents>{childData.parent_purchase_code}</CustomTableContents>
              <CustomTableContents>
                {/*부가상품 상태(부가상품만 만료일 수 있음)childData.*/}
              </CustomTableContents>
              <CustomTableContents>{childData.from_date}</CustomTableContents>
              <CustomTableContents>{childData.to_date}</CustomTableContents>
              <CustomTableContentsEND>{childData.locker_info.locker_no}</CustomTableContentsEND>
            </CustomRow>
          );
        })}
      </>
    );
  };

  columns_head = [
    { title: "지점", width: "8%" },
    { title: "구분", width: "4%" },
    { title: "회원번호", width: "7%" },
    { title: "이름", width: "7%" },
    { title: "연락처", width: "7%" },
    { title: "생년월일", width: "7%" },
    { title: "성별", width: "4%" },
    { title: "회원권 상태", width: "5%" },
    { title: "최종 회원권 종료일", width: "7%" },

    { title: "회원권 구매코드", width: "7%" },
    { title: "부가상품 상태", width: "5%" },
    { title: "시작일", width: "7%" },
    { title: "종료일", width: "7%" },
    { title: "사용 번호", width: "5%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
