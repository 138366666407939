import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import TextField from "components/LabelInput/TextField";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import SubTitle from "components/PageTitle/SubTitle";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SelectCommissionModeModal from "./SelectCommissionModeModal";

const commissionInit = {
  pt_commission: [],
  pt_fee: [],
};

//당월 커미션 설정 모달
const SetBasicCommissionModal = ({ modalParam }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const viewModelRef = useRef();

  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  const [commissionArr, setCommissionArr] = useState(commissionInit.pt_commission);
  const [feeArr, setFeeArr] = useState(commissionInit.pt_fee);

  useEffect(() => {
    setCommissionArr(modalParam.pt_commission);
    setFeeArr(modalParam.pt_fee);
  }, [modalParam]);

  const popSelection = (title, param, localModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <SelectCommissionModeModal modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const ptCommissionData = new ptCommissionTable();
  const ptPriceData = new ptPriceTable();

  return (
    <div style={{ width: "32rem" }}>
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />
      <div>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="PT 매출 커미션 " />
        </div>
        <div>
          <CustomTable
            columns_head={ptCommissionData.columns_head}
            table_title={ptCommissionData.table_title}
            rest_call={ptCommissionData.get_data_from_rest}
            row_render={ptCommissionData.create_table}
            rest_data={commissionArr}
            maxHeight={"15rem"}
          />
        </div>
        <div style={{ display: "flex", marginTop: "0.7rem" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="PT 수업료 " />
        </div>
        <div>
          <CustomTable
            columns_head={ptPriceData.columns_head}
            table_title={ptPriceData.table_title}
            rest_call={ptPriceData.get_data_from_rest}
            row_render={ptPriceData.create_table}
            rest_data={feeArr}
            maxHeight={"15rem"}
          />
        </div>
        {modalParam.is_edit ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1.3rem",
            }}
          >
            <Button
              className={clsx({
                [btnStyleClass.btnRootRaw]: true,
                [btnStyleClass.buttonType5]: true,
              })}
              onClick={() => {
                popSelection("커미션 설정", {
                  onClickOpenModal: modalParam.onClickOpenModal,
                  staffId: modalParam.data.staff_id,
                  salaryReportId: modalParam.salaryReportId,
                  staffSalaryId: modalParam.staffSalaryId,
                  branchId: modalParam.branchId,
                  pt_preset: modalParam.pt_preset,
                  ptCommission: commissionArr,
                  ptFee: feeArr,
                  lastWorkInfoId: modalParam.lastWorkInfoId,
                });
              }}
            >
              수정
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default SetBasicCommissionModal;

class ptCommissionTable {
  table_title = "";
  label_width = "4rem";
  box_width = "8rem";
  mode = null;

  constructor(mode) {
    this.mode = mode;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <TextField
              endAdornment={"원"}
              textBoxWidth={this.box_width}
              defaultValue={data.from_section.toLocaleString()}
              disabled
            />
          </div>
        </CustomTableContents>
        <CustomTableContents>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <TextField
              endAdornment={"원"}
              textBoxWidth={this.box_width}
              defaultValue={data.to_section.toLocaleString()}
              disabled
            />
          </div>
        </CustomTableContents>
        <CustomTableContents>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <TextField
              endAdornment={"%"}
              textBoxWidth={"6rem"}
              defaultValue={data.rate.toLocaleString()}
              disabled
            />
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "매출 구간 시작 금액", width: "33%" },
    { title: "매출 구간 종료 금액", width: "33%" },
    { title: "매출 커미션", width: "33%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}

class ptPriceTable {
  table_title = "";
  label_width = "4rem";
  box_width = "8rem";
  mode = null;

  constructor(mode) {
    this.mode = mode;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <TextField
              textBoxWidth={this.box_width}
              endAdornment={"원"}
              defaultValue={data.from_section.toLocaleString()}
              disabled
            />
          </div>
        </CustomTableContents>
        <CustomTableContents>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <TextField
              textBoxWidth={this.box_width}
              endAdornment={"원"}
              defaultValue={data.to_section.toLocaleString()}
              disabled
            />
          </div>
        </CustomTableContents>
        <CustomTableContents>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <TextField
              endAdornment={"%"}
              textBoxWidth={"6rem"}
              defaultValue={data.rate.toLocaleString()}
              disabled
            />
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "매출 구간 시작 금액", width: "33%" },
    { title: "매출 구간 종료 금액", width: "33%" },
    { title: "지급율", width: "33%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
