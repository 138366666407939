import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";

const EditSalaryDateModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();

  const box_width = "11.5rem";
  const btnStyleClass = btnStyles();
  const [staffIdx, setStaffIdx] = useState();
  const [staffInfo, setStaffInfo] = useState(_.cloneDeep(modalParam.modalInfo));
  const [loading, setLoading] = useState(false);

  //급여일 수정 POST
  const editSalaryDate = async () => {
    try {
      const formData = new FormData();
      staffInfo.salary_report_id = modalParam.salaryReportId;
      formData.append("new_info", JSON.stringify(staffInfo));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_SALARY_SERVER + "/salary/salary_date/change",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공 =>  급여 테이블 갱신 + 모달 닫기
        simpleAlert(storesDispatch, "알림", "급여일이 수정되었습니다.", false, onModalDone);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const validationCheck = () => {
    if (staffIdx === undefined) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="직원을 선택해주세요." />
            </>
          ),

          onBtnOk: () => {},
        }),
      );
    } else if (!staffInfo.salary_type) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="급여 종류를 선택해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else if (!staffInfo.salary_date) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="급여일을 입력해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="급여일을 수정하시겠습니까?" />
            </>
          ),
          onBtnYes: () => {
            editSalaryDate();
          },
          onBtnNo: () => {},
        }),
      );
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      {loading && <Loading />}

      <div style={{ display: "flex" }}>
        <ComboBox
          labelWidth={"5rem"}
          comboItemWidth={box_width}
          labelText="직원명"
          data={modalParam.modalInfo.staff_name_list}
          textMarginRight="0.4rem"
          onChangeCallback={(e) => {
            const index = modalParam.modalInfo.staff_name_list.findIndex(
              (el) => el === e.target.value,
            );
            const staffId = modalParam.modalInfo.staff_info_list[index].staff_id;
            setStaffIdx(index);
            setStaffInfo((cur) => {
              return { ...cur, staff_id: staffId };
            });
          }}
        />
      </div>

      <TextField
        labelWidth={"5rem"}
        textBoxWidth={box_width}
        disabled
        labelText="직책"
        defaultValue={
          staffIdx !== undefined ? modalParam.modalInfo.staff_info_list[staffIdx].staff_pos : ""
        }
      />
      <ComboBox
        labelWidth={"5rem"}
        comboItemWidth={box_width}
        labelText="급여 종류"
        data={modalParam.modalInfo.salary_type_list}
        onChangeCallback={(e) => {
          setStaffInfo((cur) => {
            return { ...cur, salary_type: e.target.value };
          });
        }}
      />
      <TextField
        isNumber
        labelWidth={"5rem"}
        textBoxWidth={box_width}
        labelText="급여일"
        maxValue={31}
        onChangeCallback={(e) => {
          setStaffInfo((cur) => {
            return { ...cur, salary_date: e };
          });
        }}
      />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRootRaw]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={validationCheck}
        >
          수정
        </Button>
      </div>
    </div>
  );
};

export default EditSalaryDateModal;
