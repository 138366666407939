import { Box } from "@mui/material";
import {
  changeComboValueToEmpty,
  checkDateOrder,
  getDefaultValue,
  getPermission,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useExtendMbshpHistoryStore } from "../modules/store/extendMbshpHistoryState";
import { SearchConditionBox, SearchContainer, SearchDatePickerBox, SearchRowBox } from "./style";

const SearchCondition = ({ setIsGetExtendMbshp, resetFlag }) => {
  const storesDispatch = useDispatch();
  // 페이지 구성 변수들
  const labelWidth = "4.5rem";
  const comboWidth = "14rem";
  const datePickerWidth = "14rem";
  const textInputWidth = "14rem";
  const textPeriodInputWidth = "6.6rem";
  const EXTEND_REASON_LIST = [
    "전체",
    "이벤트 참여",
    "연기 누락",
    "지인 등록",
    "시작일 변경",
    "동반 가입",
    "PT 소진",
    "기타",
  ];
  // 페이지 상태 관리
  const [hqBranchListData, setHqBranchListData] = useState({});
  const [branchList, setBranchList] = useState([]);
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);
  // 전역 상태 관리
  const {
    extendHistorySearchParams: searchParams,
    setSearchParams,
    extendHistoryHqIdx: hqIdx,
    setHqIdx,
    setPage,
  } = useExtendMbshpHistoryStore();

  // 권한에 따른 본부와 지점 리스트 세팅
  useEffect(() => {
    getPermission(storesDispatch, "SEARCH_MEMBER_MENU/SEARCH_MANAGE", true, false, true).then(
      (res) => {
        res && setHqBranchListData(res);
        if (res.branch_list.length > 0) {
          setBranchList(res.branch_list[hqIdx]);
        }
      },
    );
  }, []);

  // 본부 선택에 따른 지점 리스트 첫번째 값 세팅 및 리스트 재세팅
  useEffect(() => {
    if (!isFirstEnterPage) {
      setSearchParams("branch_info", hqBranchListData.target_list[hqIdx].branch_list[0]);
    }
    if (hqBranchListData.branch_list) {
      setBranchList(hqBranchListData.branch_list[hqIdx]);
    }
  }, [hqIdx]);

  // 초기화 시 본부 및 지점 첫번째 값 세팅
  useEffect(() => {
    if (!isFirstEnterPage && hqBranchListData.target_list.length > 0) {
      setSearchParams("hq_info", hqBranchListData.target_list[0].hq_info);
      setSearchParams("branch_info", hqBranchListData.target_list[0].branch_list[0]);
    }
  }, [resetFlag]);

  // 첫 페이지 여부 체크
  useEffect(() => {
    setIsFirstEnterPage(false);
  }, []);

  return (
    <Box
      sx={{
        border: "1px solid #dedede",
        borderRadius: "5px",
        backgroundColor: "#f5f5f5",
        marginTop: "0.5rem",
      }}
    >
      <SearchContainer
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setPage(1);
            setIsGetExtendMbshp(true);
          }
        }}
      >
        <Label labelText="검색 조건" fontWeight="bold" justifyContent="flex-start" />
        <SearchRowBox>
          <SearchConditionBox>
            <ComboBox
              labelText="본부"
              labelWidth={labelWidth}
              comboItemWidth={comboWidth}
              data={hqBranchListData && hqBranchListData.hq_list}
              defaultValue={getDefaultValue("combo", searchParams.hq_info.hq_name)}
              onChangeCallback={(e, idx) => {
                setHqIdx(idx);
                setSearchParams("hq_info", hqBranchListData.target_list[idx].hq_info);
              }}
            />
          </SearchConditionBox>
          <SearchConditionBox>
            <SearchComboBox
              labelText="지점"
              labelWidth={labelWidth}
              comboItemWidth={comboWidth}
              data={branchList}
              defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
              onChangeCallback={(e, v, idx) => {
                setSearchParams(
                  "branch_info",
                  hqBranchListData.target_list[hqIdx].branch_list[idx],
                );
              }}
            />
          </SearchConditionBox>
          <SearchConditionBox>
            <TextField
              labelWidth={labelWidth}
              textBoxWidth={textInputWidth}
              labelText="회원번호"
              defaultValue={getDefaultValue("field", searchParams.member_no)}
              onChangeCallback={(e) => {
                setSearchParams("member_no", e);
              }}
            />
          </SearchConditionBox>
          <SearchConditionBox>
            <TextField
              labelWidth={labelWidth}
              textBoxWidth={textInputWidth}
              labelText="회원명"
              defaultValue={getDefaultValue("field", searchParams.member_name)}
              onChangeCallback={(e) => {
                setSearchParams("member_name", e);
              }}
            />
          </SearchConditionBox>
        </SearchRowBox>
        <SearchRowBox>
          <SearchConditionBox>
            <TextField
              labelText="담당자"
              labelWidth={labelWidth}
              textBoxWidth={textInputWidth}
              defaultValue={getDefaultValue("field", searchParams.staff_name)}
              onChangeCallback={(e) => {
                setSearchParams("staff_name", e);
              }}
            />
          </SearchConditionBox>
          <SearchConditionBox>
            <TextField
              labelWidth={labelWidth}
              textMarginRight={"0.1rem"}
              textBoxWidth={textPeriodInputWidth}
              labelText="추가일수"
              defaultValue={getDefaultValue("field", searchParams.extend_days_from)}
              onChangeCallback={(e) => {
                setSearchParams("extend_days_from", Number(e));
              }}
            />
            <TextField
              labelWidth={"0.1rem"}
              textBoxWidth={textPeriodInputWidth}
              labelText="~"
              defaultValue={getDefaultValue("field", searchParams.extend_days_to)}
              onChangeCallback={(e) => {
                setSearchParams("extend_days_to", Number(e));
              }}
            />
          </SearchConditionBox>
          <SearchDatePickerBox>
            <DatePicker
              labelText="등록일"
              labelWidth={labelWidth}
              textBoxWidth={datePickerWidth}
              textMarginRight="0.5rem"
              defaultValue={getDefaultValue("field", searchParams.reg_date_from)}
              onChangeCallback={(e) => {
                setSearchParams("reg_date_from", e);
              }}
              onBlur={(e) => {
                if (!checkDateOrder(e, searchParams.reg_date_to)) {
                  setSearchParams("reg_date_from", "");
                }
              }}
            />
            <DatePicker
              labelWidth={"0.5rem"}
              textBoxWidth={datePickerWidth}
              textMarginRight="0rem"
              labelMarginRight="0.5rem"
              labelText="~"
              defaultValue={getDefaultValue("field", searchParams.reg_date_to)}
              onChangeCallback={(e) => {
                setSearchParams("reg_date_to", e);
              }}
              onBlur={(e) => {
                if (!checkDateOrder(searchParams.reg_date_from, e)) {
                  setSearchParams("reg_date_to", "");
                }
              }}
            />
          </SearchDatePickerBox>
        </SearchRowBox>
        <SearchRowBox>
          <ComboBox
            labelText="추가구분"
            labelWidth={labelWidth}
            comboItemWidth={comboWidth}
            data={EXTEND_REASON_LIST}
            defaultValue={getDefaultValue("combo", searchParams.extend_type)}
            onChangeCallback={(e) => {
              changeComboValueToEmpty(setSearchParams, "extend_type", e);
            }}
          />
        </SearchRowBox>
      </SearchContainer>
    </Box>
  );
};
export default SearchCondition;
