import { useQuery } from "@tanstack/react-query";
import { getGuestBook, getGuestBookSearchParams } from "apis/member/guest_book_api";
import CardFixed from "components/Card/CardFixed";
import {
  SubLine,
  generateObjectZeroId,
  getPermission,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import _ from "lodash";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { hq_branch_search_init_state } from "pages/CommonModal/Search/Module/reducers/hq_branch_search_init_state";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GuestBookDetailModal from "../Modal/GuestBookDetailModal";
import GuestBookResultTable from "./GuestBookResultTable";
import SearchCondition from "./SearchCondition";
import { useGuestBookStore } from "./modules/store/guestBookState";

const GuestBookView = () => {
  const storesDispatch = useDispatch();

  // 전역 상태 관리
  const {
    guestBookSearchParams: searchParams,
    setSearchParams,
    guestBookPage: page,
    setPage,
    resetSearchParams,
  } = useGuestBookStore();

  // 페이지 상태 관리
  const [hqBranchData, setHqBranchData] = useState(_.cloneDeep(hq_branch_search_init_state));
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [resetFlag, setResetFlag] = useState(false);

  // API 상태 관리
  const [isGetGuestBook, setIsGetGuestBook] = useState(false);
  const queryData = { page: page, searchParams: searchParams };

  // [GET] 방명록 검색 조건 가져오기
  const { data: firstSearchParamData } = useQuery({
    queryKey: ["guestBook", "firstSearchParam"],
    queryFn: () => getGuestBookSearchParams(),
    enabled: true,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });

  // [GET] 방명록 목록 리스트 가져오기
  const { isFetching, data: guestBookData } = useQuery({
    queryKey: ["guestBook", queryData],
    queryFn: () => getGuestBook(queryData),
    enabled: isGetGuestBook,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetGuestBook(false);
    },
  });

  // 방명록 본부 및 지점 권한 Combo 가져오기
  const getHqBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "CS_MENU/CONSULT", true);
    setHqBranchData(result);
    if (result.target_list.length > 0) {
      if (searchParams.hq_info.hq_id === generateObjectZeroId()) {
        setSearchParams("hq_info", result.target_list[0].hq_info);
        setSearchParams("branch_info", result.target_list[0].branch_list[0]);
      }
    }
  };

  // 방명록 목록 검색
  const onClickSearch = async (page) => {
    setPage(page);
    setIsGetGuestBook(true);
  };

  const onModalDone = (res) => {
    // console.log(res);
    storesDispatch(globalModalOff());
  };

  const openModal = (order, param) => {
    let Modal;
    if (order === "detail") {
      Modal = <GuestBookDetailModal modalParam={param} onModalDone={onModalDone} />;
    }
    storesDispatch(
      globalModalOn({
        show: true,
        title: param.title,
        Content: Modal,
      }),
    );
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    if (firstSearchParamData && !restEmpty(firstSearchParamData.data.data)) {
      const { combo_item } = firstSearchParamData.data.data;
      setSearchParams("combo_item", combo_item);
    }
    // 방명록 리스트
    if (guestBookData && !restEmpty(guestBookData.data.data)) {
      setSearchResult(guestBookData.data.data);
      setTotalPage(guestBookData.data.total_page);
    }
  }, [firstSearchParamData, guestBookData]);

  // 첫 페이지 진입 시 검색 결과를 가져오기 및 본부 및 지점 권한 GET
  useEffect(() => {
    setIsGetGuestBook(true);
    getHqBranchCombo();
  }, []);

  return (
    <CardFixed
      midDom={
        <div>
          {isFetching && <Loading />}

          <div>
            <SearchCondition
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              hqBranchData={hqBranchData}
              resetFlag={resetFlag}
              onClickSearch={onClickSearch}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "0.75rem",
                gap: "0.3rem",
              }}
            >
              <NormalBtn
                name="초기화"
                theme="gray"
                onClick={() => {
                  resetSearchParams();
                  setResetFlag(!resetFlag);
                }}
              />
              <NormalBtn
                name="검색"
                onClick={() => {
                  onClickSearch(1);
                }}
              />
            </div>
          </div>

          <SubLine />

          <div style={{ marginTop: "1rem" }}>
            <GuestBookResultTable
              hqBranchData={hqBranchData}
              openModal={openModal}
              searchResult={searchResult}
              curPage={page}
              totalPage={totalPage}
              onClickSearch={onClickSearch}
            />
          </div>
        </div>
      }
      isLoading={false}
      rootStyle={{}}
    />
  );
};
export default GuestBookView;
