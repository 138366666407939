import { Button } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { getDailyReportList, getDailyReportSearchParam } from "apis/branch/daily_report_api";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import {
  checkDateOrder,
  dataEmpty,
  getDefaultValue,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import Loading from "components/Loading/Loading/Loading";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDailyReportStore } from "../modules/store/dailyReportState";

const DailyReportListView = () => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();
  const navigate = useNavigate();
  // 페이지 구성 변수
  const leftMargin = "3rem";
  const compWidth = "12rem";
  const labelWidth = "3.5rem";

  // 전역 상태 관리
  const {
    dailyReportSearchParams: searchParams,
    setSearchParams,
    dailyReportHqIdx: hqIdx,
    setHqIdx,
    setPage,
    dailyReportPage: page,
  } = useDailyReportStore();
  // 페이지 상태 관리
  const [hqBranchListData, setHqBranchListData] = useState({});
  const [branchList, setBranchList] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);
  // API 상태 관리
  const [isGetDailyReportList, setIsGetDailyReportList] = useState(false);
  const queryData = { page: page, searchParams: searchParams };

  // API Query
  // [GET] 운영 일지 검색 조건 받아오기 및 권한 데이터 세팅
  const { data: firstSearchParamData } = useQuery({
    queryKey: ["dailyReport", "firstSearchParam"],
    queryFn: () => getDailyReportSearchParam(),
    enabled: isFirstEnterPage,
    retry: false,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });

  // [GET] 운영 일지 내역 리스트 검색
  const { isFetching, data: dailyReportListData } = useQuery({
    queryKey: ["dailyReport", queryData],
    queryFn: () => getDailyReportList(queryData),
    enabled: isGetDailyReportList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetDailyReportList(false);
    },
  });

  // API 받아온 데이터 세팅
  useEffect(() => {
    if (firstSearchParamData) {
      const { combo_selector } = firstSearchParamData.data.data;
      // 첫 검색 조건
      if (searchParams.hq_info.hq_id === "") {
        setSearchParams("hq_info", combo_selector.target_list[0].hq_info);
        setSearchParams("branch_info", combo_selector.target_list[0].branch_list[0]);
        setSearchParams("combo_selector", combo_selector);
      }
      // 본부 및 지점 정보
      setHqBranchListData(combo_selector);
      setBranchList(combo_selector.branch_list[hqIdx]);
    }

    // 검색 결과 데이터 세팅
    if (dailyReportListData) {
      setSearchResult(dailyReportListData.data.data);
      setTotalPage(dailyReportListData.data.total_page);
    }
  }, [firstSearchParamData, dailyReportListData]);

  // 본부 선택에 따른 지점 리스트 다시 세팅
  useEffect(() => {
    if (hqBranchListData.branch_list) setBranchList(hqBranchListData.branch_list[hqIdx]);
    //  첫 페이지 진입이 아니고 본부 변경 시 지점 초기화 세팅
    if (!isFirstEnterPage) {
      const branchParams = hqBranchListData.target_list[hqIdx].branch_list[0];
      setSearchParams("branch_info", branchParams);
    }
  }, [hqIdx]);

  // 페이지 진입 시 결과 리스트
  useEffect(() => {
    if (isFirstEnterPage && searchParams.hq_info.hq_id !== "") {
      setIsGetDailyReportList(true);
      setIsFirstEnterPage(false);
    }
  }, [searchParams.hq_info.hq_id]);

  // 결과 검색
  const onClickSearch = (page) => {
    setPage(page);
    setIsGetDailyReportList(true);
  };

  const searchResultList = new resultTable(navigate);

  return (
    <div>
      <CardFixed
        midDom={
          <div>
            {isFetching && <Loading />}

            <div
              style={{ display: "flex" }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onClickSearch(1);
                }
              }}
            >
              <ComboBox
                labelText="본부"
                labelWidth={labelWidth}
                labelMarginRight="0rem"
                comboItemWidth={compWidth}
                data={hqBranchListData && hqBranchListData.hq_list}
                defaultValue={getDefaultValue("combo", searchParams.hq_info.hq_name)}
                onChangeCallback={(e, idx) => {
                  setHqIdx(idx);
                  const hqParams = hqBranchListData.target_list[idx].hq_info;
                  setSearchParams("hq_info", hqParams);
                }}
              />

              <SearchComboBox
                marginLeft={leftMargin}
                labelText="지점"
                labelWidth={labelWidth}
                labelMarginRight="0rem"
                comboItemWidth={compWidth}
                data={branchList}
                defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
                onChangeCallback={(e, v, idx) => {
                  const branchParams = hqBranchListData.target_list[hqIdx].branch_list[idx];
                  setSearchParams("branch_info", branchParams);
                }}
              />

              <DatePicker
                marginLeft={leftMargin}
                labelText="날짜"
                labelWidth={labelWidth}
                textBoxWidth="11rem"
                labelMarginRight="0rem"
                textMarginRight="0rem"
                defaultValue={getDefaultValue("field", searchParams.base_from_date)}
                onChangeCallback={(e) => {
                  setSearchParams("base_from_date", e);
                }}
                onBlur={(e) => {
                  if (!checkDateOrder(e, searchParams.base_to_date)) {
                    setSearchParams("base_from_date", "");
                  }
                }}
              />
              <DatePicker
                marginLeft="0.25rem"
                labelWidth="0.5rem"
                textBoxWidth="11rem"
                labelMarginRight="0.25rem"
                labelText="~"
                defaultValue={getDefaultValue("field", searchParams.base_to_date)}
                onChangeCallback={(e) => {
                  setSearchParams("base_to_date", e);
                }}
                onBlur={(e) => {
                  if (!checkDateOrder(searchParams.base_from_date, e)) {
                    setSearchParams("base_to_date", "");
                  }
                }}
              />

              <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <Button
                  className={clsx({
                    [btnStyleClass.btnRoot]: true,
                    [btnStyleClass.buttonType6]: true,
                  })}
                  onClick={() => {
                    onClickSearch(1);
                  }}
                >
                  검색
                </Button>
              </div>
            </div>

            <div style={{ marginTop: "1.5rem" }}>
              <CustomTable
                columns_head={searchResultList.columns_head}
                table_title={searchResultList.table_title}
                rest_call={searchResultList.get_data_from_rest}
                row_render={searchResultList.create_table}
                rest_data={searchResult}
              />
              <CustomPagination curPage={page} totalCnt={totalPage} doSearch={onClickSearch} />
            </div>
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "100%",
          height: "29rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};
export default DailyReportListView;

//

class resultTable {
  table_title = "";
  onNavigate = null;

  constructor(navigate) {
    this.onNavigate = navigate;
  }

  onClickRow = (data) => {
    this.onNavigate("/daily_info/", {
      state: {
        reportInfoId: data.report_info_id,
      },
    });
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{}}
        key={idx}
        onClick={(e) => {
          if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
            this.onClickRow(data);
          }
        }}
        hover
      >
        <CustomTableContents>{data.base_date}</CustomTableContents>
        <CustomTableContents>{data.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>
          {/* <IconButton
            type="update"
            width="2.3rem"
            tooltip="새로고침"
            onClick={() => {
              this.onClickUpdate();
            }}
          /> */}
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "날짜", width: "30%" },
    { title: "본부", width: "30%" },
    { title: "지점", width: "30%" },
    { title: "", width: "10%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
