import { useQuery } from "@tanstack/react-query";
import { getPtTeamCombo } from "apis/staff/pt_team_performance_api";
import { getCurrentMonth, getDefaultValue, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import MonthPicker from "components/LabelInput/MonthPicker";
import MultiComboBox from "components/LabelInput/MultiComboBox";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import NormalBtn from "components/NewButton/NormalBtn";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTeamRecordStore } from "../modules/store/teamRecordState";
const SearchCardRow1 = ({
  branchList,
  searchParams,
  setSearchParams,
  resetFlag,
  onClickSearch,
}) => {
  const labelWidth = "4.5rem";
  const textBoxWidth = "12rem";
  const storesDispatch = useDispatch();
  // 전역 상태 관리
  const { teamRecordBranchIdx: branchIdx, setBranchIdx } = useTeamRecordStore();

  const [teamInfo, setTeamInfo] = useState([]);
  const [branchId, setBranchId] = useState("");
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);

  // API Query
  // [GET] 지점에 따른 PT 팀 리스트 가져오기
  const { refetch, data: ptTeamComboData } = useQuery({
    queryKey: ["ptTeamPerformance", branchId],
    queryFn: () => getPtTeamCombo(branchId),
    enabled: !!branchId,
    retry: false,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });

  // pt Team 정보 세팅 및 첫번째 팀 세팅
  useEffect(() => {
    if (ptTeamComboData) {
      setTeamInfo(ptTeamComboData.data.data);
      if (ptTeamComboData.data.data.team_info_list) {
        setSearchParams("team_info", ptTeamComboData.data.data.team_info_list[0]);
      } else {
        setSearchParams("team_info", "");
      }
    }
  }, [ptTeamComboData]);

  // 첫 페이지 진입 시 팀 정보 및 월 정보 세팅
  useEffect(() => {
    if (isFirstEnterPage && branchList.branch_list.length > 0) {
      setBranchId(branchList.branch_list[branchIdx].branch_id);

      if (searchParams.base_date === "") {
        const baseMonth = getCurrentMonth();
        setSearchParams("base_date", baseMonth);
      }
      setIsFirstEnterPage(false);
    }
  }, [branchList]);

  // 초기화 시 지점 및 팀 첫번째 값 세팅
  useEffect(() => {
    if (branchList.branch_list.length > 0) {
      const baseMonth = getCurrentMonth();

      setBranchId(branchList.branch_list[0].branch_id);
      refetch();
      setSearchParams("branch_info", branchList.branch_list[0]);
      setSearchParams("base_date", baseMonth);
    }
  }, [resetFlag]);

  return (
    <div>
      <div
        style={{ display: "flex", marginTop: "0.7rem" }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch();
          }
        }}
      >
        <SearchComboBox
          labelWidth={labelWidth}
          comboItemWidth={textBoxWidth}
          data={branchList.branch_name_list}
          defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
          labelText="지점"
          onChangeCallback={(e, v, idx) => {
            const branch = branchList.branch_list[idx];
            setBranchIdx(idx);
            setBranchId(branch.branch_id); //지점바뀌면 팀정보 새로 GET
            setSearchParams("branch_info", branch);
          }}
        />
        <ComboBox
          labelMarginLeft={"2rem"}
          labelWidth={labelWidth}
          comboItemWidth={textBoxWidth}
          labelText="팀"
          defaultValue={getDefaultValue("combo", searchParams.team_info.team_name)}
          data={teamInfo && teamInfo.team_name_list}
          onChangeCallback={(e, idx) => {
            setSearchParams("team_info", teamInfo.team_info_list[idx]);
          }}
        />
      </div>
    </div>
  );
};

const SearchCardRow2 = ({ searchParams, setSearchParams }) => {
  const labelWidth = "4.5rem";
  const textBoxWidth = "12rem";

  return (
    <div style={{ display: "flex", marginTop: "0.7rem" }}>
      <MonthPicker
        labelWidth={labelWidth}
        minWidth={textBoxWidth}
        labelText="월"
        defaultValue={getDefaultValue("field", searchParams.base_date)}
        onChangeCallback={(e) => {
          setSearchParams("base_date", e);
        }}
      />
      <MultiComboBox
        labelMarginLeft={"2rem"}
        labelWidth={labelWidth}
        comboItemWidth={"29rem"}
        labelText="주차"
        defaultValue={getDefaultValue("field", searchParams.week_name_list)}
        option={["1 주차", "2 주차", "3 주차", "4 주차", "5 주차"]}
        onChangeCallback={(e, valueArr, reason) => {
          const indexArr = valueArr.map((el) => Number(el[0]) - 1).sort((a, b) => a - b);
          const temp = [
            {
              selected: false,
              week_ord: 1,
              week_ord_name: "",
            },
            {
              selected: false,
              week_ord: 2,
              week_ord_name: "",
            },
            {
              selected: false,
              week_ord: 3,
              week_ord_name: "",
            },
            {
              selected: false,
              week_ord: 4,
              week_ord_name: "",
            },
            {
              selected: false,
              week_ord: 5,
              week_ord_name: "",
            },
          ];

          for (let i = 0; i < indexArr.length; i++) {
            temp[indexArr[i]].selected = true;
          }
          setSearchParams("week_name_list", valueArr);
          setSearchParams("week_info_list", temp);
        }}
      />
    </div>
  );
};

const TeamRecordSearchCard = ({
  searchParams,
  setSearchParams,
  resetSearchParams,
  onClickSearch,
  branchList,
}) => {
  const [resetFlag, setResetFlag] = useState(false);

  return (
    <div>
      <SearchCardRow1
        branchList={branchList}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        resetFlag={resetFlag}
        onClickSearch={onClickSearch}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <SearchCardRow2 searchParams={searchParams} setSearchParams={setSearchParams} />

        <div
          style={{
            marginLeft: "0.5rem",
            marginTop: "0.7rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "0.3rem",
          }}
        >
          <NormalBtn
            name="초기화"
            theme="gray"
            onClick={() => {
              resetSearchParams();
              setResetFlag(!resetFlag);
            }}
          />
          <NormalBtn
            name="검색"
            onClick={() => {
              onClickSearch();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamRecordSearchCard;
