import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import { useEffect, useState } from "react";

const ShortTermLoanTable = ({ incomeData, expenseData }) => {
  const resultTableInst = new resultTable();

  const [resData, setResData] = useState([]);

  useEffect(() => {
    if (incomeData) {
      calcIncomeExpenses();
    }
  }, [incomeData]);

  const calcIncomeExpenses = () => {
    const income = incomeData.find((data) => data.account === "단기대여금").price;
    const expense = expenseData.find((data) => data.account === "단기상환금").price;

    const res = [];
    res.push({ key: "수입 (단기대여금)", value: income });
    res.push({ key: "지출 (단기상환금)", value: expense });

    setResData(res);
  };

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <div style={{ display: "flex", marginBottom: "0.5rem" }}>
        <IconButton type="border" />
        <Label labelText="단기대여금" marginLeft="0.5rem" fontWeight="bold" />
      </div>
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={resData}
      />
    </div>
  );
};
export default ShortTermLoanTable;

class resultTable {
  table_title = "";

  // constructor() {}

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents $bgColor="#dfebf3">{data.key}</CustomTableContents>
        <CustomTableContents>{data.value.toLocaleString()}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "구분", width: "50%" },
    { title: "금액", width: "50%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
