import TableCell from "@mui/material/TableCell";
import styled from "styled-components";

import TableRow from "@mui/material/TableRow";

// export const CustomTableCell = TableCell(() => ({
//   style: {
//     backgroundColor: "red",
//   },
// }));

export const CustomTableCell2 = styled(TableCell)`
  border: 0px solid #e5e5e5;
  && {
    padding: 0.5rem;
    font-size: 0.8rem;
    letter-spacing: 0px;
    color: #707070;
  }
`;

export const CustomTableCellFit = styled(TableCell)`
  border-right: 0.7px solid #e5e5e5;
  height: ${(props) => props.max_cnt + "rem"};

  && {
    padding: 0rem;
    font-size: 1rem;
    letter-spacing: 0px;
    color: #707070;
  }
`;
export const CustomTableCellChild = styled.div`
  border-bottom: ${(props) => (props.isLast ? "" : "0.7px solid #e5e5e5")};
  height: ${(props) => (props.height ? props.height : "1.85rem")};
  font-weight: ${(props) => (props.isBold ? "bold" : "")};
  background-color: ${(props) => (props.$bgColor ? props.$bgColor : "")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "")};
  box-sizing: border-box;
  && {
    padding: ${(props) => (props.nopadding !== undefined ? "0.38rem 0rem" : "0.38rem")};
    font-family: AppleSDGothicNeo;
    font-size: 0.9rem;
    letter-spacing: 0px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: ${(props) => (props.color ? props.color : "#707070")};
  }
`;

export const CustomTableRow = styled(TableRow)`
  border: 0px solid #e5e5e5;

  && {
    padding: 0.5rem;
    font-size: 1.8rem;
    letter-spacing: 0px;
    color: red;
  }
`;
export const CustomRow = styled(TableRow)`
  // padding: theme.spacing(1);
  text-decoration: none;
  width: "100%";

  background-color: ${(props) => (props.selected ? "#999" : "#fff")};

  && {
    border-spacing: 2px;
    padding: 2px;
  }
  &:hover {
    cursor: ${(props) =>
      props.cursor ? props.cursor : props.onClick === undefined ? "" : "pointer"};
    /* background-color: #f4f6f9; */
    color: red;
    font-size: 14px;

    // border: 1.2px solid blue;
  }
`;

export const CustomScrollDiv = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: ${(props) => (props.leng && props.leng > props.set ? "" : "center")};
  /* border: 1px solid red; */
  height: 1.75rem;
  padding: 0.1rem 0;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }
  scrollbar-width: none; /* 파이어폭스 */
`;

export const CustomTableHeadlessCell = styled(TableCell)`
  background-color: rgba(255, 255, 255, 1);

  border: solid 1px rgba(255, 255, 255, 1);
  width: ${(props) => props.width || "none"};
  && {
    padding: 0.48rem;
    font-family: AppleSDGothicNeo;
    font-size: 0.9rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    /* color: #666; */
  }
`;

export const CustomTableCell = styled(TableCell)`
  border-right: solid 1px #dedede;
  background-color: ${(props) => (props.$bgColor ? props.$bgColor : "#ebebeb")};

  width: ${(props) => props.width || "none"};

  && {
    padding: 0.48rem;
    font-family: AppleSDGothicNeo;
    font-size: 0.9rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: ${(props) => (props.$isNumber !== undefined ? "right" : "center")};
    /* text-align: center; */
    /* color: #666; */
  }
`;

export const CustomTableContents = styled(TableCell)`
  border-right: ${(props) => (props.noBorder ? "" : "1px solid #dedede")};
  background-color: ${(props) => (props.$bgColor ? props.$bgColor : "")};

  width: ${(props) => props.width || "none"};
  height: ${(props) => (props.height ? props.height : "1.75rem")};
  rowspan: ${(props) => (props.rowSpan ? props.rowSpan : undefined)};
  colspan: ${(props) => (props.colSpan ? props.colSpan : undefined)};
  white-space: pre-wrap;

  && {
    padding: ${(props) =>
      props.nopadding !== undefined
        ? "0.26rem 0rem"
        : "0.26rem"}; // nopadding: camel or snake case 사용 시 적용 안됨
    padding-right: ${(props) => (props.$isNumber !== undefined ? "0.5rem" : "")};
    font-family: AppleSDGothicNeo;
    color: ${(props) => (props.color !== undefined ? props.color : "#000")};
    font-size: 0.9rem;
    font-weight: ${(props) => (props.fontWeight !== undefined ? props.fontWeight : "normal")};
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: ${(props) => (props.$isNumber !== undefined ? "right" : "center")};
    /* color: #666; */
  }
`;

export const CustomTableContentsEND = styled(TableCell)`
  border-right: solid 0px #dedede;
  /* background-color: ${(props) =>
    props.bgColor ? props.bgColor : props.selected ? "#eee" : "#fff"}; */
  width: ${(props) => props.width || "none"};
  height: ${(props) => (props.height ? props.height : "1.75rem")};

  && {
    padding: ${(props) => (props.padding === undefined ? "0.38rem" : "0rem")};
    padding-right: ${(props) => (props.$isNumber !== undefined ? "0.5rem" : "")};
    font-family: AppleSDGothicNeo;
    font-size: 0.9rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: ${(props) => (props.$isNumber !== undefined ? "right" : "center")};
    /* color: #666; */
  }
`;
