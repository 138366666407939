import CardFixed from "components/Card/CardFixed";
import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import PageTitle from "components/PageTitle/PageTitle";
import PageFrame from "components/Panels/PageFrame";
import { HttpInstance } from "lib/HttpLib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MemberLocationStatsView from "./MemberLocationStatsView";

const MemberLocationStatsWrapper = () => {
  const storesDispatch = useDispatch();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    getAuthData();
  }, []);

  const getAuthData = async () => {
    await HttpInstance.get(process.env.REACT_APP_AUTH_SERVER + "/global/perm_auth", {})
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setIsAdmin(res.data.data.is_admin);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  return (
    <>
      <PageFrame>
        <div>
          <PageTitle titleText="회원 위치 통계" />

          <div>
            <CardFixed
              midDom={
                <div>
                  <MemberLocationStatsView isAdmin={true} />
                </div>
              }
              isLoading={false}
              rootStyle={{
                borderRadius: "10px",
                backgroundColor: "#fff",
              }}
            />
          </div>
        </div>
      </PageFrame>
    </>
  );
};
export default MemberLocationStatsWrapper;
