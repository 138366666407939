import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import { useEffect, useState } from "react";

const EtcConsultTable = ({ reportState }) => {
  const resultTableInst = new resultTable();
  const [totalCnt, setTotalCnt] = useState(0);

  useEffect(() => {
    const sum =
      reportState.cs_summary_info.call_cnt +
      reportState.cs_summary_info.tm_cnt +
      reportState.cs_summary_info.visit_cnt;
    setTotalCnt(sum);
  }, [reportState]);

  return (
    <div>
      <div style={{ display: "flex", marginBottom: "0.5rem" }}>
        <IconButton type="border" />
        <Label labelText="등록 외 상담" marginLeft="0.5rem" fontWeight="bold" />
      </div>
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={[reportState.cs_summary_info]}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
        <TextField
          labelText="합계"
          labelWidth="3rem"
          textBoxWidth="10rem"
          textMarginRight="0rem"
          isNumber
          defaultValue={totalCnt.toLocaleString()}
          disabled
        />
      </div>
    </div>
  );
};
export default EtcConsultTable;

class resultTable {
  table_title = "";

  // constructor() {}

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents $bgColor="#dfebf3">인원</CustomTableContents>
        <CustomTableContents>{data.visit_cnt}</CustomTableContents>
        <CustomTableContents>{data.tm_cnt}</CustomTableContents>
        <CustomTableContents>{data.call_cnt}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "구분", width: "25%" },
    { title: "방문 상담", width: "25%" },
    { title: "TM", width: "25%" },
    { title: "전화 상담", width: "25%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
