import { checkDateOrder, getDefaultValue, SubLine } from "components/CommonLib/CommonLib";
import CheckBox from "components/LabelInput/CheckBox";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import MultiComboBox from "components/LabelInput/MultiComboBox";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import NormalBtn from "components/NewButton/NormalBtn";
import { useRef } from "react";
import {
  SearchConditionBox,
  SearchContainer,
  SearchDatePickerBox,
  SearchMultiConditionBox,
  SearchRowBox,
} from "../style";

const OtSearchCard = ({
  searchParam,
  setSearchParam,
  resetSearchParam,
  otBranch,
  phone,
  onClickSearch,
  statusData,
}) => {
  return (
    <div>
      <SearchCardRow1
        onClickSearch={onClickSearch}
        phone={phone}
        searchParam={searchParam}
        setSearchParam={setSearchParam}
      />
      <SearchCardRow2
        onClickSearch={onClickSearch}
        searchParam={searchParam}
        setSearchParam={setSearchParam}
        otBranch={otBranch}
        statusData={statusData}
      />

      <div
        style={{
          marginTop: "0.7rem",
          display: "flex",
          justifyContent: "flex-end",
          gap: "0.3rem",
        }}
      >
        <NormalBtn
          name="초기화"
          theme="gray"
          onClick={() => {
            resetSearchParam();
          }}
        />
        <NormalBtn
          name="검색"
          onClick={() => {
            onClickSearch(1);
          }}
        />
      </div>
      <SubLine />
    </div>
  );
};
export default OtSearchCard;

//
const SearchCardRow1 = ({ onClickSearch, searchParam, setSearchParam, phone }) => {
  const labelWidth = "5.5rem";
  const labelWidthShort = "4rem";
  const textBoxWidth = "14rem";
  const textBoxWidthShort = "8.5rem";
  const phoneInputBoxWidth = "5.5rem";
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();

  // 전화번호 입력 이벤트 (모든 칸)
  const onInputPhone = () => {
    let res = "";

    if (phone.p1 && !phone.p2 && phone.p3) {
      res = "err";
    } else {
      res = phone.p1 + "-" + phone.p2 + "-" + phone.p3;
    }
    res = res.replace("--", "-");

    setSearchParam((cur) => {
      const obj = { ...cur };
      obj.member_base.phone = res;
      return obj;
    });
  };

  return (
    <SearchContainer>
      <Label labelText="검색 조건: 회원 정보" fontWeight="bold" justifyContent="flex-start" />
      <SearchRowBox>
        <SearchConditionBox>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            defaultValue={searchParam.member_base.member_no}
            labelText="회원번호"
            onChangeCallback={(e) => {
              setSearchParam((cur) => {
                const obj = { ...cur };
                obj.member_base.member_no = e;
                return obj;
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") onClickSearch(1);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            defaultValue={searchParam.member_base.member_name}
            labelText="이름"
            onChangeCallback={(e) => {
              setSearchParam((cur) => {
                const obj = { ...cur };
                obj.member_base.member_name = e;
                return obj;
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") onClickSearch(1);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <TextField
            inputRef={phoneRef1}
            labelWidth={labelWidthShort}
            textBoxWidth={phoneInputBoxWidth}
            textMarginRight="0.1rem"
            labelText="연락처"
            defaultValue={phone.p1}
            maxLength={3}
            onChangeCallback={(e) => {
              phone.p1 = e;
              onInputPhone();
              if (phone.p1.length === 3) {
                phoneRef2.current.select();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") onClickSearch(1);
            }}
          />
          <TextField
            inputRef={phoneRef2}
            textBoxWidth={phoneInputBoxWidth}
            textMarginRight="0.1rem"
            labelMarginRight="0.1rem"
            labelWidth="0.5rem"
            labelText="-"
            maxLength={4}
            defaultValue={phone.p2}
            onChangeCallback={(e) => {
              phone.p2 = e;
              onInputPhone();
              if (phone.p2.length === 4) {
                phoneRef3.current.select();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onClickSearch(1);
              } else if (e.key === "Backspace" && !phone.p2) {
                phoneRef1.current.select();
              }
            }}
          />
          <TextField
            inputRef={phoneRef3}
            textBoxWidth={phoneInputBoxWidth}
            textMarginRight="0.1rem"
            labelMarginRight="0.1rem"
            labelWidth="0.5rem"
            labelText="-"
            maxLength={4}
            defaultValue={phone.p3}
            onChangeCallback={(e) => {
              phone.p3 = e;
              onInputPhone();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onClickSearch(1);
              } else if (e.key === "Backspace" && !phone.p3) {
                phoneRef2.current.select();
              }
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <ComboBox
            marginLeft="1rem"
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            defaultValue={getDefaultValue("combo", searchParam.membership.mbshp_join_type)}
            data={["신규", "재등록"]}
            labelText="회원권 구분"
            onChangeCallback={(e) => {
              setSearchParam((cur) => {
                const obj = { ...cur };
                obj.membership.mbshp_join_type = e.target.value;
                return obj;
              });
            }}
            includeAllOption
          />
        </SearchConditionBox>
      </SearchRowBox>

      {/* Lane 2 */}
      <SearchRowBox>
        <SearchConditionBox>
          <ComboBox
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            defaultValue={getDefaultValue("combo", searchParam.sort_type)}
            data={["등록일", "회원권 시작일"]}
            labelText="정렬기준"
            onChangeCallback={(e) => {
              setSearchParam((cur) => {
                const obj = { ...cur };
                obj.sort_type = e.target.value;
                return obj;
              });
            }}
            includeAllOption
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <DatePicker
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            labelText="회원권 시작일"
            defaultValue={searchParam.membership.mbshp_from_date}
            onChangeCallback={(e) => {
              setSearchParam((cur) => {
                const obj = { ...cur };
                obj.membership.mbshp_from_date = e;
                return obj;
              });
            }}
          />
        </SearchConditionBox>
        <SearchDatePickerBox>
          <DatePicker
            labelWidth={labelWidthShort}
            textBoxWidth={textBoxWidthShort}
            textMarginRight="0rem"
            labelText="등록일"
            defaultValue={searchParam.membership.mbshp_paid_from_date}
            onChangeCallback={(e) => {
              setSearchParam((cur) => {
                const obj = { ...cur };
                obj.membership.mbshp_paid_from_date = e;
                return obj;
              });
            }}
            onBlur={(e) => {
              if (!checkDateOrder(e, searchParam.membership.mbshp_paid_to_date)) {
                setSearchParam((cur) => {
                  const obj = { ...cur };
                  obj.membership.mbshp_paid_from_date = "";
                  return obj;
                });
              }
            }}
          />
          <DatePicker
            marginLeft="0.1rem"
            labelWidth="0.1rem"
            textBoxWidth={textBoxWidthShort}
            labelText="~"
            defaultValue={searchParam.membership.mbshp_paid_to_date}
            onChangeCallback={(e) => {
              setSearchParam((cur) => {
                const obj = { ...cur };
                obj.membership.mbshp_paid_to_date = e;
                return obj;
              });
            }}
            onBlur={(e) => {
              if (!checkDateOrder(searchParam.membership.mbshp_paid_from_date, e)) {
                setSearchParam((cur) => {
                  const obj = { ...cur };
                  obj.membership.mbshp_paid_to_date = "";
                  return obj;
                });
              }
            }}
          />
        </SearchDatePickerBox>
      </SearchRowBox>
    </SearchContainer>
  );
};

const SearchCardRow2 = ({ searchParam, setSearchParam, otBranch, onClickSearch, statusData }) => {
  const labelWidth = "5.5rem";
  const textBoxWidth = "14rem";
  const labelWidthShort = "4rem";

  return (
    <SearchContainer>
      <Label labelText="검색 조건: 상세" fontWeight="bold" justifyContent="flex-start" />
      {/* Lane 1 */}
      <SearchRowBox
        onKeyDown={(e) => {
          if (e.key === "Enter") onClickSearch(1);
        }}
      >
        <SearchConditionBox>
          <SearchComboBox
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            data={otBranch.branch_name_list}
            defaultValue={getDefaultValue("combo", searchParam.branch_info.branch_name)}
            labelText="지점"
            onChangeCallback={(e, v) => {
              const branch = otBranch.branch_list.find((el) => el.branch_name === v);
              setSearchParam((cur) => {
                return { ...cur, branch_info: branch };
              });
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <ComboBox
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            data={["전체", "미배정", "배정"]}
            defaultValue={searchParam.ot_info.is_assigned || "전체"}
            labelText="배정 상태"
            onChangeCallback={(e) => {
              setSearchParam({
                ...searchParam,
                staff_text_name: "",
                ot_info: {
                  ...searchParam.ot_info,
                  is_assigned: e.target.value,
                  class_status: "",
                },
              });
            }}
          />
        </SearchConditionBox>
        <SearchMultiConditionBox>
          <MultiComboBox
            labelWidth={labelWidthShort}
            comboItemWidth={`27rem`}
            labelText="OT 상태"
            option={statusData}
            defaultValue={searchParam.ot_info.root_status}
            onChangeCallback={(e, valueArr, reason) => {
              setSearchParam((cur) => {
                const obj = { ...cur };
                obj.ot_info.root_status = valueArr;
                return obj;
              });
            }}
          />
        </SearchMultiConditionBox>
      </SearchRowBox>
      {/* Lane 2 */}
      <SearchRowBox>
        <SearchConditionBox>
          <TextField
            labelText="수업 담당자"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            defaultValue={searchParam.staff_text_name}
            onChangeCallback={(e) => {
              setSearchParam({
                ...searchParam,
                staff_text_name: e,
                ot_info: { ...searchParam.ot_info, is_assigned: "배정" },
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") onClickSearch(1);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <ComboBox
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            data={["전체", "대기", "예약", "수업완료", "부재", "거절", "보류(연기)"]}
            labelText="OT 수업상태"
            defaultValue={searchParam.ot_info.class_status || "전체"}
            onChangeCallback={(e) => {
              let value = e.target.value;
              if (value === "전체") {
                value = "";
              }

              setSearchParam({
                ...searchParam,
                ot_info: {
                  ...searchParam.ot_info,
                  class_status: value,
                  is_assigned: "배정",
                },
              });
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <ComboBox
            labelWidth={labelWidthShort}
            comboItemWidth={textBoxWidth}
            data={["전체", "권유", "안함"]}
            defaultValue={getDefaultValue("combo", searchParam.ot_info.is_suggest_pt)}
            labelText="PT 권유"
            onChangeCallback={(e) => {
              setSearchParam((cur) => {
                const obj = { ...cur };
                obj.ot_info.is_suggest_pt = e.target.value;
                return obj;
              });
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <CheckBox
            labelText="퇴사자 수업 보기"
            transform={"scale(1.2)"}
            marginLeft="-2.5rem"
            marginRight={"0.3rem"}
            marginTop={"0rem"}
            defaultValue={searchParam.manager_info.is_resigned}
            onChangeCallback={(e) => {
              setSearchParam((cur) => {
                const obj = { ...cur };
                obj.manager_info.is_resigned = e.checked;
                return obj;
              });
            }}
          />
        </SearchConditionBox>
      </SearchRowBox>
    </SearchContainer>
  );
};
