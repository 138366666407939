import { Button } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { getUnpaidExclusionList } from "apis/member/unpaid_manage_api";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import {
  generateObjectZeroId,
  getPermission,
  simpleAlert,
  SubLine,
} from "components/CommonLib/CommonLib";
import _ from "lodash";
import { branch_search_init_state } from "pages/CommonModal/Search/Module/reducers/hq_branch_search_init_state";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useUnpaidManageStore } from "./modules/store/unpaidManageState";
import UnpaidExemptResultTable from "./ResultTable/UnpaidExemptResultTable";
import DetailCondition from "./SearchCondition/DetailCondition";
import MemberInfoCondition from "./SearchCondition/MemberInfoCondition";

const UnpaidExemptTab = () => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  // 전역 상태 관리
  const { setLoading, searchParams, setSearchParams, setPage, page, resetSearchParams } =
    useUnpaidManageStore().returnStoreByType("unpaidExclusion");

  // 페이지 상태 관리
  const [branchList, setBranchList] = useState(_.cloneDeep(branch_search_init_state));
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [resetFlag, setResetFlag] = useState(false);

  // API 상태 관리
  const [isGetUnpaidExclusionList, setIsGetUnpaidExclusionList] = useState(false);
  const queryData = { page: page, searchParams: searchParams };

  // 지점 권한 받아오기
  useEffect(() => {
    getPermission(storesDispatch, "SEARCH_MEMBER_MENU/UNPAID_MANAGE", true, true).then((res) => {
      setBranchList(res);
      if (
        res.branch_list.length > 0 &&
        searchParams.branch_info.branch_id === generateObjectZeroId()
      ) {
        setSearchParams("branch_info", res.branch_list[0]);
      }
    });
  }, []);

  // [GET] 미납금 제외 이력 리스트 가져오기
  const { isFetching, data: unpaidExclusionList } = useQuery({
    queryKey: ["unpaidExclusion", queryData],
    queryFn: () => getUnpaidExclusionList(queryData),
    enabled: isGetUnpaidExclusionList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetUnpaidExclusionList(false);
      setLoading(false);
    },
  });
  /** isExcel: 엑셀 다운로드 여부, page: 페이지네이션 */
  const onClickSearch = async (isExcel, page) => {
    setPage(page);
    // setIsExcel(isExcel);
    // 미납금 제외 리스트 받아오기
    setIsGetUnpaidExclusionList(true);
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 미납금 제외 리스트
    if (unpaidExclusionList) {
      setSearchResult(unpaidExclusionList.data.data);
      setTotalPage(unpaidExclusionList.data.total_page);
    }
  }, [unpaidExclusionList]);

  // 로딩 상태
  useEffect(() => {
    if (isFetching) {
      setLoading(isFetching);
    }
  }, [isFetching]);

  // 첫 페이지 진입 시 검색 결과를 가져오기
  useEffect(() => {
    setIsGetUnpaidExclusionList(true);
  }, []);

  return (
    <div>
      <div>
        <MemberInfoCondition
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onClickSearch={onClickSearch}
        />
        <DetailCondition
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          branchList={branchList}
          resetFlag={resetFlag}
          mode="exempt"
          onClickSearch={onClickSearch}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "0.75rem",
          }}
        >
          <Button
            className={clsx({
              [btnStyleClass.btnRootWhite]: true,
              [btnStyleClass.buttonType7]: true,
            })}
            onClick={() => {
              resetSearchParams();
              setResetFlag(!resetFlag);
            }}
          >
            조건 초기화
          </Button>
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType6]: true,
            })}
            onClick={() => {
              onClickSearch(false, page);
            }}
            style={{ marginLeft: "0.5rem" }}
          >
            검색
          </Button>
        </div>
      </div>

      <SubLine />

      <div style={{ marginTop: "1rem" }}>
        <UnpaidExemptResultTable
          searchResult={searchResult}
          curPage={page}
          totalPage={totalPage}
          onClickSearch={onClickSearch}
        />
      </div>
    </div>
  );
};
export default UnpaidExemptTab;
