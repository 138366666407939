import { useQuery } from "@tanstack/react-query";
import { getTMList, getTMListSearchParams } from "apis/member/tm_list_api";
import CardFixed from "components/Card/CardFixed";
import {
  SubLine,
  generateObjectZeroId,
  getPermission,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { branch_search_init_state } from "pages/CommonModal/Search/Module/reducers/hq_branch_search_init_state";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SearchCondition from "./SearchCondition";
import TMListResultTable from "./TMListResultTable";
import { useTMListStore } from "./modules/store/tmListState";

const TMListView = () => {
  const storesDispatch = useDispatch();

  // 전역 상태 관리
  const {
    tmListSearchParams: searchParams,
    setSearchParams,
    tmListPage: page,
    setPage,
    resetSearchParams,
  } = useTMListStore();

  // 페이지 상태 관리
  const [branchList, setBranchList] = useState(_.cloneDeep(branch_search_init_state));
  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [resetFlag, setResetFlag] = useState(false);

  // API 상태 관리
  const [isGetTMList, setIsGetTMList] = useState(false);
  const queryData = { page: page, searchParams: searchParams };

  // [GET] TM 리스트 검색 조건 가져오기
  const { data: firstSearchParamData } = useQuery({
    queryKey: ["tmList", "firstSearchParam"],
    queryFn: () => getTMListSearchParams(),
    enabled: true,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
  });

  // [GET] TM 리스트 목록 가져오기
  const { isFetching, data: tmListData } = useQuery({
    queryKey: ["tmList", queryData],
    queryFn: () => getTMList(queryData),
    enabled: isGetTMList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetTMList(false);
    },
  });

  // TM 리스트 지점 권한 Combo 가져오기
  const getBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "CS_MENU/CONSULT", true, true);
    setBranchList(result);
    if (result.branch_list.length > 0) {
      if (searchParams.branch_info.branch_id === generateObjectZeroId()) {
        setSearchParams("branch_info", result.branch_list[0]);
      }
    }
  };

  // TM 리스트 목록 검색
  const onClickSearch = async (page) => {
    setPage(page);
    setIsGetTMList(true);
  };

  const validationTemp = () => {
    if (!searchParams.branch_info.branch_name) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="지점을 선택해주세요." />,
          onBtnOk: () => {},
        }),
      );
    } else {
      onClickSearch(1);
    }
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    if (firstSearchParamData && !restEmpty(firstSearchParamData.data.data)) {
      setSearchParams("combo_item", firstSearchParamData.data.data.combo_item);
    }
    // TM 리스트
    if (tmListData && !restEmpty(tmListData.data.data)) {
      setSearchResult(tmListData.data.data);
      setTotalPage(tmListData.data.total_page);
    }
  }, [firstSearchParamData, tmListData]);

  // 첫 페이지 진입 시 검색 결과를 가져오기 및 지점 권한 GET
  useEffect(() => {
    setIsGetTMList(true);
    getBranchCombo();
  }, []);

  return (
    <CardFixed
      midDom={
        <div>
          {isFetching && <Loading />}

          <div>
            <SearchCondition
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              branchList={branchList}
              resetFlag={resetFlag}
              onClickSearch={onClickSearch}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "0.75rem",
                gap: "0.3rem",
              }}
            >
              <NormalBtn
                name="초기화"
                theme="gray"
                onClick={() => {
                  resetSearchParams();
                  setResetFlag(!resetFlag);
                }}
              />
              <NormalBtn
                name="검색"
                onClick={() => {
                  //TO-DO 템프 삭제하고 주석 해제해야
                  // onClickSearch(1);
                  validationTemp();
                }}
              />
            </div>
          </div>

          <SubLine />

          <div style={{ marginTop: "1rem" }}>
            <TMListResultTable
              searchResult={searchResult}
              curPage={page}
              totalPage={totalPage}
              onClickSearch={onClickSearch}
            />
          </div>
        </div>
      }
      isLoading={false}
      rootStyle={{}}
    />
  );
};
export default TMListView;
