import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import {
  contentTypeJson,
  dataEmpty,
  dateToStr,
  getCurrentDate,
  getCurrentTime,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import RadioGroup from "components/LabelInput/RadioGroup";
import TextArea from "components/LabelInput/TextArea";
import TextField from "components/LabelInput/TextField";
import TimePicker from "components/LabelInput/TimePicker";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { add_member_tm_state } from "../Module/reducers/add_member_tm_state";
import { tm_member_detail_state } from "../Module/reducers/tm_member_detail_state";

const TmDetailModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  const [addTmState, setAddTmState] = useState(_.cloneDeep(add_member_tm_state));
  const [memberTmInfoState, setMemberTmInfoState] = useState(_.cloneDeep(tm_member_detail_state));
  const [compEnable, setCompEnable] = useState(true);
  const [loading, setLoading] = useState(false);

  const labelWidth = "4rem";

  // useEffect(() => {
  //   setAddTmState({
  //     ...addTmState,
  //     hist_id: "???",
  //     member_id: modalParam.state.member_info.member_id,
  //     member_name: modalParam.state.member_info.member_name,
  //     member_type: "???",
  //     phone: modalParam.state.base_info.phone,
  //     tm_date: getCurrentDate(),
  //     tm_time: getCurrentTime(true),
  //   });
  // }, [modalParam.state]);
  // console.log(modalParam);

  useEffect(() => {
    if (modalParam.member_id !== "") {
      setLoading(true);

      HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/cs/tm_info/detail", {
        params: {
          member_id: modalParam.member_id,
          branch_id: modalParam.branch_info.branch_id,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            // console.log(res.data.data);

            let restData = res.data.data;

            // if (restData.branch_info.branch_name === "") {
            //   restData.branch_info.branch_name = "고객";
            // }
            setMemberTmInfoState((cur) => {
              return {
                ...cur,
                base_info: restData.base_info,
                branch_info: restData.branch_info,
                guest_book_hist: restData.guest_book_hist,
                member_info: restData.member_info,
                service_status_info: restData.service_status_info,
                tm_record_list: restData.tm_record_list,
                cs_team_list: restData.cs_team_list,
                cs_team_name_list: restData.cs_team_name_list,
              };
            });

            setAddTmState((cur) => {
              return {
                ...cur,
                branch_info: modalParam.branch_info,
                member_info: restData.member_info,
                member_type: restData.member_type,
                // guest_book_hist: res.data.data.member_info.guest_book_hist,
                // member_info: res.data.data.member_info,
                phone: restData.base_info.phone,
                // tm_record_list: restData.tm_record_list,
              };
            });
          }
        })
        .catch((err) => {
          console.log("err");
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [modalParam]);

  const onClickSave = () => {
    if (!addTmState.tm_info.tm_status) {
      simpleAlert(storesDispatch, "알림", "TM 상태를 선택해주세요.");
      return;
    } else if (addTmState.tm_info.tm_status === "TM 완료" && !addTmState.tm_info.inquiry_memo) {
      simpleAlert(storesDispatch, "알림", "TM 내용을 입력해주세요.");
      return;
      // } else if (!addTmState.visit_plan.visit_date || !addTmState.visit_plan.visit_time) {
      //   simpleAlert(storesDispatch, "알림", "방문예약일시를 입력해주세요.");
      //   return;
    } else if (!addTmState.tm_info.staff_info.staff_id) {
      simpleAlert(storesDispatch, "알림", "상담자를 선택해주세요.");
      return;
    }

    // 방문일시 없을 시 현재시간 입력
    if (!addTmState.tm_info.cs_date) {
      addTmState.tm_info.cs_date = getCurrentDate();
    }
    if (!addTmState.tm_info.cs_time) {
      addTmState.tm_info.cs_time = getCurrentTime(true);
    }

    let alertText = "";
    if (!addTmState.visit_plan.visit_date || !addTmState.visit_plan.visit_time) {
      alertText = "방문 예약 일시를 선택하지 않았습니다. 저장하시겠습니까?";
    } else {
      alertText = "저장하시겠습니까?";
    }

    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText={alertText} />,
        onBtnYes: () => {
          onSave();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onSave = async () => {
    const formData = new FormData();
    formData.append("new_info", JSON.stringify(addTmState));

    setLoading(true);

    await HttpInstance.post(
      process.env.REACT_APP_USER_SERVER + "/cs/tm_info/add",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          // console.log(res);
          simpleAlert(storesDispatch, "알림", "저장되었습니다.");
          onModalDone();
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const statusTable = new resultTable("status");
  const tmListTable = new resultTable("tm_hist");
  const guestBookTable = new resultTable("guest_book");

  return (
    <div style={{ width: "60rem" }}>
      {loading && <Loading />}

      {/* Lane 1 */}
      <div>
        <div style={{ display: "flex", marginTop: "1.5rem" }}>
          <IconButton type="border" />
          <Label labelText="상담자 기본 정보" marginLeft="0.5rem" fontWeight="bold" />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "0.5rem" }}>
          <div style={{ width: "33.3%" }}>
            <TextField
              labelText="지점"
              labelWidth={labelWidth}
              labelMarginRight="0rem"
              textMarginRight="0rem"
              fullWidth
              disabled
              defaultValue={memberTmInfoState.branch_info.branch_name}
            />
          </div>
          <div style={{ width: "33.3%", marginLeft: "4rem" }}>
            <TextField
              labelText="이름"
              labelWidth={labelWidth}
              labelMarginRight="0rem"
              textMarginRight="0rem"
              fullWidth
              disabled
              defaultValue={memberTmInfoState.member_info.member_name}
            />
          </div>
          <div style={{ width: "33.3%", marginLeft: "4rem" }}>
            <TextField
              labelText="연락처"
              labelWidth={labelWidth}
              labelMarginRight="0rem"
              textMarginRight="0rem"
              fullWidth
              disabled
              defaultValue={memberTmInfoState.base_info.phone}
            />
          </div>
        </div>
      </div>

      {/* Lane 2 */}
      <div style={{ marginTop: "1.5rem" }}>
        <div style={{ display: "flex", marginBottom: "0.25rem" }}>
          <IconButton type="border" />
          <Label labelText="수강 현황" marginLeft="0.5rem" fontWeight="bold" />
        </div>
        <CustomTable
          columns_head={[
            { title: "회원권 수강상태", width: "16.6%" },
            { title: "최근 회원권 결제일", width: "16.6%" },
            { title: "최종 회원권 종료일", width: "16.6%" },
            { title: "PT 수강상태", width: "16.6%" },
            { title: "최근 PT 결제일", width: "16.6%" },
            { title: "PT 총 잔여횟수", width: "16.6%" },
          ]}
          table_title={statusTable.table_title}
          rest_call={statusTable.get_data_from_rest}
          row_render={statusTable.create_table}
          rest_data={memberTmInfoState.service_status_info}
          maxHeight="10rem"
        />
      </div>

      {/* Lane 3 */}
      <div style={{ marginTop: "1.5rem" }}>
        <div style={{ display: "flex", marginBottom: "0.25rem" }}>
          <IconButton type="border" />
          <Label labelText="TM 이력" marginLeft="0.5rem" fontWeight="bold" />
        </div>
        <CustomTable
          columns_head={[
            { title: "지점", width: "16%" },
            { title: "TM 일시", width: "16%" },
            { title: "TM 담당자", width: "16%" },
            { title: "TM 상태", width: "16%" },
            { title: "TM 내용", width: "36%" },
          ]}
          table_title={tmListTable.table_title}
          rest_call={tmListTable.get_data_from_rest}
          row_render={tmListTable.create_table}
          rest_data={memberTmInfoState.tm_record_list}
          maxHeight="10rem"
        />
      </div>

      {/* Lane 4 */}
      <div style={{ marginTop: "1.5rem" }}>
        <div style={{ display: "flex", marginBottom: "0.25rem" }}>
          <IconButton type="border" />
          <Label labelText="방명록" marginLeft="0.5rem" fontWeight="bold" />
        </div>
        <CustomTable
          columns_head={[
            { title: "지점", width: "16%" },
            { title: "방문일시", width: "16%" },
            { title: "상담 담당자", width: "16%" },
            { title: "주요 문의 내용", width: "16%" },
            { title: "방문 상담 내용", width: "36%" },
          ]}
          table_title={guestBookTable.table_title}
          rest_call={guestBookTable.get_data_from_rest}
          row_render={guestBookTable.create_table}
          rest_data={memberTmInfoState.guest_book_hist}
          maxHeight="10rem"
        />
      </div>

      {/* Lane 4 */}
      <div style={{ marginTop: "1.5rem" }}>
        <div style={{ display: "flex", marginBottom: "0.25rem" }}>
          <IconButton type="border" />
          <Label labelText="TM 내용" marginLeft="0.5rem" fontWeight="bold" />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <DatePicker
              required
              labelText="TM 일시"
              labelWidth="4.5rem"
              textBoxWidth="9rem"
              labelMarginRight="0rem"
              textMarginRight="0rem"
              maxDate={dateToStr(new Date())}
              // defaultValue={getCurrentDate()}
              defaultValue={addTmState.tm_info.cs_date}
              // defaultValue={searchParam.reg_from_date}
              onChangeCallback={(e) => {
                addTmState.tm_info.cs_date = e;
              }}
            />
            <TimePicker
              required
              textBoxWidth="9rem"
              labelMarginRight="0rem"
              textMarginRight="0rem"
              defaultValue={addTmState.tm_info.cs_time || getCurrentTime(true)}
              onBlur={(e) => {
                addTmState.tm_info.cs_time = e;
              }}
            />
          </div>
          <RadioGroup
            labelText="TM 상태"
            labelWidth="6rem"
            radioMarginRight="3rem"
            data={["TM 완료", "부재"]}
            isMulti={true}
            // defaultValue={searchParam.mbshp_include_assign}
            defaultValue={addTmState.tm_info.tm_status}
            onChangeCallback={(e, v) => {
              if (v === "TM 완료") {
                setCompEnable(true);
                addTmState.tm_info.tm_status = "TM 완료";
              } else {
                setCompEnable(false);
                addTmState.tm_info.tm_status = "부재";
              }
            }}
          />
        </div>

        <TextArea
          required
          labelText="내용"
          labelWidth={labelWidth}
          marginTop="0.5rem"
          // labelMarginRight="0rem"
          fullWidth
          minRows={4}
          maxRows={4}
          disabled={!compEnable}
          // defaultValue={}
          onChangeCallback={(e) => {
            addTmState.tm_info.inquiry_memo = e.target.value;
          }}
        />

        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "0.5rem" }}>
          <div>
            <div style={{ display: "flex" }}>
              <DatePicker
                required
                labelText="방문 예약"
                labelWidth="4.5rem"
                textBoxWidth="9rem"
                labelMarginRight="0rem"
                textMarginRight="0rem"
                minDate={dateToStr(new Date())}
                defaultValue={getCurrentDate()}
                disabled={!compEnable}
                // defaultValue={}
                onChangeCallback={(e) => {
                  addTmState.visit_plan.visit_date = e;
                }}
              />
              <TimePicker
                required
                textBoxWidth="9rem"
                labelMarginRight="0rem"
                textMarginRight="0rem"
                // defaultValue={getCurrentTime(true)}
                disabled={!compEnable}
                onBlur={(e) => {
                  addTmState.visit_plan.visit_time = e;
                }}
              />
              {/* <Button
                style={{ marginLeft: "0.25rem" }}
                className={clsx({
                  [btnStyleClass.btnRootWhite]: true,
                  [btnStyleClass.buttonType4]: true,
                  [btnStyleClass.disabled]: !compEnable,
                })}
                onClick={() => {
                  // resetSearchParam();
                }}
                disabled={!compEnable}
              >
                예약
              </Button> */}
              <Label
                marginLeft="1rem"
                labelText="* 방문 날짜 설정시 스케줄에 예약 등록됩니다."
                fontSize="0.75rem"
              />
            </div>
          </div>
          <div>
            <ComboBox
              required
              labelWidth={"4.5rem"}
              comboItemWidth={"10rem"}
              labelText="상담자"
              data={memberTmInfoState.cs_team_name_list}
              onChangeCallback={(e) => {
                const index = memberTmInfoState.cs_team_name_list
                  .map((item) => item)
                  .indexOf(e.target.value);
                if (index !== -1) {
                  addTmState.tm_info.staff_info.staff_id =
                    memberTmInfoState.cs_team_list.team[index].staff_id;
                  addTmState.tm_info.staff_info.staff_name =
                    memberTmInfoState.cs_team_list.team[index].staff_name;
                }
              }}
            />
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center", marginTop: "2.5rem" }}>
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType6]: true,
            })}
            onClick={() => {
              onClickSave();
            }}
          >
            저장
          </Button>
        </div>
      </div>
    </div>
  );
};
export default TmDetailModal;

//

class resultTable {
  table_title = "";
  mode = "";

  constructor(mode) {
    this.mode = mode;
  }

  create_table = (data, idx) => {
    if (this.mode === "status") {
      return (
        <CustomRow key={idx}>
          <CustomTableContents>{data.mbshp_status}</CustomTableContents>
          <CustomTableContents>{data.last_mbshp_paid_date}</CustomTableContents>
          <CustomTableContents>{data.last_mbshp_to_date}</CustomTableContents>
          <CustomTableContents>{data.active_pt_status}</CustomTableContents>
          <CustomTableContents>{data.last_pt_paid_date}</CustomTableContents>
          <CustomTableContentsEND>{data.total_pt_remain_cnt}</CustomTableContentsEND>
        </CustomRow>
      );
    } else if (this.mode === "tm_hist") {
      return (
        <CustomRow key={idx}>
          <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
          <CustomTableContents>
            {data.tm_info.cs_date + " " + data.tm_info.cs_time}
          </CustomTableContents>
          <CustomTableContents>{data.tm_info.staff_info.staff_name}</CustomTableContents>
          <CustomTableContents>{data.tm_info.tm_status}</CustomTableContents>
          <CustomTableContentsEND>{data.tm_info.inquiry_memo}</CustomTableContentsEND>
        </CustomRow>
      );
    } else {
      return (
        <CustomRow key={idx}>
          <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
          <CustomTableContents>{data.cs_date + " " + data.cs_time}</CustomTableContents>
          <CustomTableContents>{data.reg_info.reg_name}</CustomTableContents>
          <CustomTableContents>{data.inquiry_title}</CustomTableContents>
          <CustomTableContentsEND>{data.inquiry_memo}</CustomTableContentsEND>
        </CustomRow>
      );
    }
  };

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
