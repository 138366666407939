import { Box } from "@mui/material";
import {
  changeComboValueToEmpty,
  checkDateOrder,
  getDefaultValue,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";

const SearchCondition = ({ searchParams, setSearchParams, onClickSearch }) => {
  return (
    <Box
      sx={{
        border: "1px solid #dedede",
        borderRadius: "5px",
        backgroundColor: "#f5f5f5",
        height: "8rem",
        marginTop: "0.5rem",
      }}
    >
      <div style={{ margin: "0.75rem 0.75rem" }}>
        <Label labelText="검색 조건 : 상세" fontWeight="bold" justifyContent="flex-start" />
        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "30%", display: "flex" }}>
            <DatePicker
              labelText="최근 TM 일시"
              labelWidth="6rem"
              textBoxWidth="9rem"
              labelMarginRight="0rem"
              textMarginRight="0rem"
              defaultValue={getDefaultValue("field", searchParams.tm_from_date)}
              onChangeCallback={(e) => {
                setSearchParams("tm_from_date", e);
              }}
              onBlur={(e) => {
                if (!checkDateOrder(e, searchParams.tm_to_date)) {
                  setSearchParams("tm_from_date", "");
                }
              }}
            />
            <DatePicker
              marginLeft="0.12rem"
              marginRight="4rem"
              labelWidth="0.5rem"
              textBoxWidth="9rem"
              textMarginRight="0rem"
              labelMarginRight="0.12rem"
              labelText="~"
              defaultValue={getDefaultValue("field", searchParams.tm_to_date)}
              onChangeCallback={(e) => {
                setSearchParams("tm_to_date", e);
              }}
              onBlur={(e) => {
                if (!checkDateOrder(searchParams.tm_from_date, e)) {
                  setSearchParams("tm_to_date", "");
                }
              }}
            />
          </div>
          <div style={{ width: "25%" }}>
            <TextField
              labelText="최근 TM 담당자"
              labelWidth="7rem"
              labelMarginRight="0rem"
              textMarginRight="0rem"
              textBoxWidth="15rem"
              marginRight="4rem"
              defaultValue={getDefaultValue("field", searchParams.tm_manager_name)}
              onChangeCallback={(e) => {
                setSearchParams("tm_manager_name", e);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onClickSearch(1);
                }
              }}
            />
          </div>
          <div style={{ width: "25%" }}>
            <ComboBox
              labelText="최근 TM 상태"
              labelWidth="6rem"
              labelMarginRight="0rem"
              comboItemWidth="16rem"
              marginRight="4rem"
              data={["전체", "없음", "TM 완료", "부재"]}
              defaultValue={getDefaultValue("combo", searchParams.tm_status)}
              onChangeCallback={(e) => {
                changeComboValueToEmpty(setSearchParams, "tm_status", e);
              }}
            />
          </div>
        </div>
      </div>
    </Box>
  );
};
export default SearchCondition;
