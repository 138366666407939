import {
  changeComboValueToEmpty,
  checkDateOrder,
  getDefaultValue,
  SubLine,
} from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import NormalBtn from "components/NewButton/NormalBtn";
import { SearchConditionBox, SearchContainer, SearchRowBox } from "../style";

const SearchCardRow = ({ searchParams, setSearchParams, onClickSearch, branchInfo }) => {
  const labelWidth = "5.5rem";
  const textBoxWidth = "14rem";
  const labelWidthShort = "4rem";
  const textBoxWidthShort = "8.5rem";

  return (
    <SearchContainer>
      <Label labelText="검색 조건: GX 상세" fontWeight="bold" justifyContent="flex-start" />
      <SearchRowBox
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch(1);
          }
        }}
      >
        <SearchConditionBox>
          <SearchComboBox
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            data={branchInfo.branch_name_list}
            labelText="지점"
            defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
            onChangeCallback={(e, v, idx) => {
              const branchParams = branchInfo.branch_list[idx];
              setSearchParams("branch_info", branchParams);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            labelText="수업코드"
            defaultValue={getDefaultValue("field", searchParams.gx_code)}
            onChangeCallback={(e) => {
              setSearchParams("gx_code", e);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <TextField
            labelWidth={labelWidthShort}
            textBoxWidth={"21.7rem"}
            labelText="수업명"
            defaultValue={getDefaultValue("field", searchParams.class_name)}
            onChangeCallback={(e) => {
              setSearchParams("class_name", e);
            }}
          />
        </SearchConditionBox>
      </SearchRowBox>
      <SearchRowBox
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickSearch(1);
          }
        }}
      >
        <SearchConditionBox>
          <TextField
            labelText="수업 담당자"
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            defaultValue={getDefaultValue("field", searchParams.staff_text_name)}
            onChangeCallback={(e) => {
              setSearchParams("staff_text_name", e);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <ComboBox
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            defaultValue={getDefaultValue("combo", searchParams.class_result_type)}
            data={["전체", "수업완료", "휴강", "대강"]}
            labelText="완료 구분"
            onChangeCallback={(e) => {
              changeComboValueToEmpty(setSearchParams, "class_result_type", e);
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <DatePicker
            labelWidth={labelWidthShort}
            labelText="수업일"
            minWidth={"10rem"}
            defaultValue={getDefaultValue("field", searchParams.class_from_date)}
            onChangeCallback={(e) => {
              setSearchParams("class_from_date", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(e, searchParams.class_to_date)) {
                setSearchParams("class_from_date", "");
              }
            }}
          />
          <DatePicker
            labelWidth={"0.5rem"}
            minWidth={"10rem"}
            labelText="~"
            defaultValue={getDefaultValue("field", searchParams.class_to_date)}
            onChangeCallback={(e) => {
              setSearchParams("class_to_date", e);
            }}
            onBlur={(e) => {
              if (!checkDateOrder(searchParams.class_from_date, e)) {
                setSearchParams("class_to_date", "");
              }
            }}
          />
        </SearchConditionBox>
      </SearchRowBox>
    </SearchContainer>
  );
};

const GxHistSearchCard = ({
  searchParams,
  setSearchParams,
  resetSearchParams,
  onClickSearch,
  branchInfo,
}) => {
  return (
    <div>
      <SearchCardRow
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onClickSearch={onClickSearch}
        branchInfo={branchInfo}
      />

      <div
        style={{
          marginTop: "0.7rem",
          display: "flex",
          justifyContent: "flex-end",
          gap: "0.3rem",
        }}
      >
        <NormalBtn
          name="초기화"
          theme="gray"
          onClick={() => {
            resetSearchParams();
          }}
        />
        <NormalBtn
          name="검색"
          onClick={() => {
            onClickSearch(1);
          }}
        />
      </div>
      <SubLine />
    </div>
  );
};
export default GxHistSearchCard;
