import IconButton from "components/Button/IconButton";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import RadioGroup from "components/LabelInput/RadioGroup";
import TextField from "components/LabelInput/TextField";

const SearchDetailMemberContents = ({ searchParam }) => {
  const textBoxWidth = "20rem";
  const textBoxWidthShort = "9.5rem";
  const radioMarginRight = "3.25rem";
  const LabelTop = ({ text }) => {
    return <Label labelText={text} color="#777" justifyContent="flex-start" marginTop="0.5rem" />;
  };

  if (searchParam.pending) {
    return <div style={{ height: "50rem" }}></div>;
  } else {
    return (
      <>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <Label labelText="회원권" marginLeft="0.5rem" fontWeight="bold" />
        </div>
        <div>
          <LabelTop text={"회원권 수강 상태"} />
          <ComboBox
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            data={["전체", "유효", "만료"]}
            defaultValue={searchParam.mbshp_status || "전체"}
            onChangeCallback={(e) => {
              searchParam.mbshp_status = e.target.value === "전체" ? "" : e.target.value;
            }}
          />

          <LabelTop text={"회원권 최종 종료일"} />
          <div style={{ display: "flex" }}>
            <DatePicker
              textBoxWidth={textBoxWidthShort}
              labelMarginRight="0rem"
              textMarginRight="0.25rem"
              defaultValue={searchParam.to_date_from}
              onChangeCallback={(e) => {
                searchParam.to_date_from = e;
              }}
            />
            <DatePicker
              labelWidth="0.5rem"
              labelMarginRight="0.25rem"
              textBoxWidth={textBoxWidthShort}
              labelText="~"
              defaultValue={searchParam.to_date_to}
              onChangeCallback={(e) => {
                searchParam.to_date_to = e;
              }}
            />
          </div>

          <LabelTop text={"회원권 구매 횟수"} />
          <RadioGroup
            radioMarginRight={radioMarginRight}
            data={["전체", "1회", "2회 이상"]}
            isMulti={true}
            defaultValue={searchParam.mbshp_paid_cnt}
            onChangeCallback={(e, v) => {
              searchParam.mbshp_paid_cnt = v;
            }}
          />

          <LabelTop text={"회원권 양도자 포함 여부"} />
          <RadioGroup
            radioMarginRight={radioMarginRight}
            data={["포함", "미포함"]}
            isMulti={true}
            defaultValue={searchParam.mbshp_include_assign}
            onChangeCallback={(e, v) => {
              searchParam.mbshp_include_assign = v;
            }}
          />

          <LabelTop text={"회원권 환불자 포함 여부"} />
          <RadioGroup
            radioMarginRight={radioMarginRight}
            data={["포함", "미포함"]}
            isMulti={true}
            defaultValue={searchParam.mbshp_include_refund}
            onChangeCallback={(e, v) => {
              searchParam.mbshp_include_refund = v;
            }}
          />
        </div>

        <div style={{ display: "flex", marginTop: "1.5rem" }}>
          <IconButton type="border" />
          <Label labelText="PT" marginLeft="0.5rem" fontWeight="bold" />
        </div>
        <div>
          <LabelTop text={"PT 수강 상태"} />
          <ComboBox
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            data={["전체", "유효", "만료"]}
            defaultValue={searchParam.class_param.pt_use_state || "전체"}
            onChangeCallback={(e) => {
              searchParam.class_param.pt_use_state =
                e.target.value === "전체" ? "" : e.target.value;
            }}
          />

          <LabelTop text={"PT 총 잔여 횟수"} />
          <div style={{ display: "flex" }}>
            <TextField
              labelMarginRight="0rem"
              textMarginRight="0.25rem"
              textBoxWidth={textBoxWidthShort}
              maxValue={999}
              maxLength={3}
              isNumber
              endAdornment="회"
              defaultValue={searchParam.class_param.remain_pt_min}
              onChangeCallback={(e) => {
                searchParam.class_param.remain_pt_min = e;
              }}
            />
            <TextField
              labelWidth="0.75rem"
              labelText="~"
              labelMarginRight="0rem"
              textMarginRight="0.25rem"
              textBoxWidth={textBoxWidthShort}
              maxValue={999}
              maxLength={3}
              isNumber
              endAdornment="회"
              defaultValue={searchParam.class_param.remain_pt_max}
              onChangeCallback={(e) => {
                searchParam.class_param.remain_pt_max = e;
              }}
            />
          </div>

          <LabelTop text={"PT 구매 횟수"} />
          <RadioGroup
            radioMarginRight="1rem"
            data={["전체", "없음", "1회", "2회 이상"]}
            isMulti={true}
            defaultValue={searchParam.pt_paid_cnt}
            onChangeCallback={(e, v) => {
              searchParam.pt_paid_cnt = v;
            }}
          />

          <LabelTop text={"PT 양도자 포함 여부"} />
          <RadioGroup
            radioMarginRight={radioMarginRight}
            data={["포함", "미포함"]}
            isMulti={true}
            defaultValue={searchParam.pt_include_assign}
            onChangeCallback={(e, v) => {
              searchParam.pt_include_assign = v;
            }}
          />

          <LabelTop text={"PT 환불자 포함 여부"} />
          <RadioGroup
            radioMarginRight={radioMarginRight}
            data={["포함", "미포함"]}
            isMulti={true}
            defaultValue={searchParam.pt_include_refund}
            onChangeCallback={(e, v) => {
              searchParam.pt_include_refund = v;
            }}
          />
        </div>
      </>
    );
  }
};
export default SearchDetailMemberContents;
