import IconButton from "components/Button/IconButton";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import { useEffect, useState } from "react";

const GenderSalesCntTable = ({ reportState }) => {
  const resultTableInst = new resultTable();
  const [totalCnt, setTotalCnt] = useState(0);

  useEffect(() => {
    const sum =
      reportState.pt_gender_data.length > 0
        ? reportState.pt_gender_data[0].man + reportState.pt_gender_data[0].woman
        : 0;
    setTotalCnt(sum);
  }, [reportState]);

  return (
    <div>
      <div style={{ display: "flex", marginBottom: "0.5rem" }}>
        <IconButton type="border" />
        <Label labelText="성별별 판매 수량" marginLeft="0.5rem" fontWeight="bold" />
      </div>
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={reportState.pt_gender_data}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
        <TextField
          labelText="합계"
          labelWidth="3rem"
          textBoxWidth="10rem"
          textMarginRight="0rem"
          isNumber
          defaultValue={totalCnt.toLocaleString()}
          disabled
        />
      </div>
    </div>
  );
};
export default GenderSalesCntTable;

class resultTable {
  table_title = "";

  // constructor() {}

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents $bgColor="#dfebf3">판매 수량</CustomTableContents>
        <CustomTableContents>{data.man}</CustomTableContents>
        <CustomTableContents>{data.woman}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "구분", width: "33%" },
    { title: "남성", width: "33%" },
    { title: "여성", width: "33%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
