import IconButton from "components/Button/IconButton";
import { dataEmpty, getPermission } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import NormalBtn from "components/NewButton/NormalBtn";
import { globalAlertOn } from "modules/actions/Alert";
import { exportToCashBookExcel } from "pages/DailyReport/DailyReportInfo/DailyReportTabs/SalesTab/MakeExcel";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const IncomeDetailTable = ({ viewData, infoStateData }) => {
  const resultTableInst = new resultTable();
  const storesDispatch = useDispatch();
  const [canExcel, setCanExcel] = useState(false);

  const checkPermission = async () => {
    const result = await getPermission(storesDispatch, "ACCOUNT_PERMISSION/EXCEL");
    setCanExcel(result);
  };

  useEffect(() => {
    checkPermission();
  }, []);

  return (
    <div style={{ marginTop: "0.5rem" }}>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0.5rem" }}>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <Label labelText="수입 내역" marginLeft="0.5rem" fontWeight="bold" />
        </div>

        {canExcel && (
          <NormalBtn
            name="엑셀 다운로드"
            theme="green"
            onClick={() => {
              storesDispatch(
                globalAlertOn({
                  show: true,
                  titleText: "알림",
                  bodyText: (
                    <>
                      <Label
                        labelText={`"${infoStateData.branch_info.branch_name}_${infoStateData.base_month}_수입내역"`}
                      />
                      <Label labelText={`엑셀 파일을 다운로드 하시겠습니까?`} />
                    </>
                  ),
                  onBtnYes: () => {
                    exportToCashBookExcel(
                      "수입집계",
                      viewData,
                      `${infoStateData.branch_info.branch_name}_${infoStateData.base_month}_수입내역`,
                    );
                  },
                  onBtnNo: () => {},
                }),
              );
            }}
          />
        )}
      </div>
      <CustomTable
        columns_head={resultTableInst.columns_head}
        table_title={resultTableInst.table_title}
        rest_call={resultTableInst.get_data_from_rest}
        row_render={resultTableInst.create_table}
        rest_data={viewData}
      />
    </div>
  );
};
export default IncomeDetailTable;

class resultTable {
  table_title = "";

  // constructor() {}

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>{data.account}</CustomTableContents>
        <CustomTableContents>{data.price.toLocaleString()}</CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "계정", width: "50%" },
    { title: "수입 금액", width: "50%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
