import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useQueryClient } from "@tanstack/react-query";
import CardFixed from "components/Card/CardFixed";
import { tabBorder, tabStyle } from "components/CommonComp";
import { restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import TabPanel from "components/TabControl/TabPanel";
import { HttpInstance } from "lib/HttpLib";
import { useState } from "react";
import { useDispatch } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import CashBookAggregate from "./AggregateTab/CashBookAggregate";
import ExpenseListWrapper from "./ExpenseListTab/ExpenseListWrapper";
import IncomeListWrapper from "./IncomeListTab/IncomeListWrapper";
import { useCashBookStore } from "./modules/store/cashBookState";

const CashBookInfoView = ({ infoState }) => {
  const storesDispatch = useDispatch();
  const queryClient = useQueryClient();
  const labelWidth = "3.5rem";
  const compWidth = "14rem";

  const [tabIndex, setTabIndex] = useState(0);
  // 전역 상태 관리
  const { cashBookLoading: loading } = useCashBookStore();

  const onChangeTabIndex = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const onClickRefresh = () => {
    const doRefresh = () => {
      HttpInstance.get(process.env.REACT_APP_SALARY_SERVER + "/cashbook/refresh", {
        params: {
          cashbook_id: infoState.cashbook_id,
        },
      })
        .then((res) => {
          // 쿼리 무효화 후 다시 받아오기
          queryClient.invalidateQueries(["cashBook", infoState.cashbook_id]);
          if (restEmpty(res.data.msg)) {
            simpleAlert(storesDispatch, "알림", "새로고침 완료");
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    };

    simpleAlert(
      storesDispatch,
      "알림",
      "수입 및 지출 데이터를 새로고침하시겠습니까?",
      false,
      undefined,
      () => {
        doRefresh();
      },
    );
  };

  return (
    <div>
      {loading && <Loading />}
      <CardFixed
        midDom={
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <TextField
                  labelText="본부"
                  labelWidth={labelWidth}
                  textBoxWidth={compWidth}
                  labelMarginRight="0rem"
                  textMarginRight="3rem"
                  defaultValue={infoState.hq_info.hq_name}
                  disabled
                />
                <TextField
                  labelText="지점"
                  labelWidth={labelWidth}
                  textBoxWidth={compWidth}
                  labelMarginRight="0rem"
                  textMarginRight="3rem"
                  defaultValue={infoState.branch_info.branch_name}
                  disabled
                />
                <TextField
                  labelText="기준 연월"
                  labelWidth={labelWidth}
                  textBoxWidth={compWidth}
                  defaultValue={infoState.base_month}
                  disabled
                />
              </div>

              <NormalBtn name="새로고침" onClick={() => onClickRefresh()} margin="0 0.5rem 0 0" />
            </div>

            <Box sx={{ bgcolor: "background.paper", marginTop: "1.5rem" }}>
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                textColor="inherit"
                sx={{ minHeight: "40px" }}
                TabIndicatorProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0)" } }}
              >
                <Tab sx={{ minHeight: "40px" }} label="집계 내역" style={tabStyle(0, tabIndex)} />
                <Tab
                  sx={{ minHeight: "40px" }}
                  label="수입 내역 관리"
                  style={tabStyle(1, tabIndex, "9rem")}
                />
                <Tab
                  sx={{ minHeight: "40px" }}
                  label="지출 내역 관리"
                  style={tabStyle(2, tabIndex, "9rem")}
                />
              </Tabs>
            </Box>
            <SwipeableViews
              index={tabIndex}
              onChangeIndex={onChangeTabIndex}
              style={tabBorder}
              animateTransitions={false}
            >
              <TabPanel value={tabIndex} index={0}>
                <CashBookAggregate cashbookId={infoState.cashbook_id} infoStateData={infoState} />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <IncomeListWrapper
                  cashbookId={infoState.cashbook_id}
                  baseMonth={infoState.base_month}
                  infoStateData={infoState}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <ExpenseListWrapper
                  infoStateData={infoState}
                  cashbookId={infoState.cashbook_id}
                  baseMonth={infoState.base_month}
                />
              </TabPanel>
            </SwipeableViews>
          </div>
        }
        isLoading={false}
        rootStyle={{
          width: "100%",
          minHeight: "29rem",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      />
    </div>
  );
};
export default CashBookInfoView;
