import failIcon from "assets/icon/NO.svg";
import successIcon from "assets/icon/success.svg";
import IconButton from "components/Button/IconButton";
import { contentTypeJson, dateToStr, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextArea from "components/LabelInput/TextArea";
import TextField from "components/LabelInput/TextField";
import TimePicker from "components/LabelInput/TimePicker";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import RegisterPhoneCheckModal from "pages/ERP/MemberRegister/Register/Modal/RegisterPhoneCheckModal";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { guest_book_staff_state, guest_book_state } from "../Module/reducers/guest_book_state";

const GuestBookDetailModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const [guestBookState, setGuestBookState] = useState(_.cloneDeep(guest_book_state));
  const [guestBookStaffState, setGuestStaffBookState] = useState(
    _.cloneDeep(guest_book_staff_state),
  );
  const [hqIdx, setHqIdx] = useState(0);
  const [isExistMember, setIsExistMember] = useState(false);

  let [phone1, setPhone1] = useState("");
  let [phone2, setPhone2] = useState("");
  let [phone3, setPhone3] = useState("");
  const phoneRef1 = useRef();
  const phoneRef2 = useRef();
  const phoneRef3 = useRef();
  const [phoneCheckStatus, setPhoneCheckStatus] = useState("");
  const [regPathList, setRegPathList] = useState([]);
  const [removePhoneMemberId, setRemovePhoneMemberId] = useState("");
  const [loading, setLoading] = useState(false);

  const labelWidthShort = "4rem";
  const labelWidthShort_2 = "6.5rem";
  const labelWidth = "8rem";

  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });

  useEffect(() => {
    if (modalParam.mode === "show") {
      setGuestBookState(modalParam.show_data);
      getBranchCsStaffList(modalParam.show_data.branch_info.branch_id);
    } else {
      if (modalParam.hqBranchData.hq_list.length > 0)
        onSelectHq(modalParam.hqBranchData.hq_list[0]);
      if (modalParam.hqBranchData.branch_list.length > 0)
        onSelectBranch(modalParam.hqBranchData.branch_list[0][0]);

      if (!guestBookState.cs_date) guestBookState.cs_date = dateToStr(new Date());

      getRegPathList();
    }
  }, [modalParam]);

  useEffect(() => {
    // console.log(modalParam);
    if (modalParam.mode === "add") {
      onSelectBranch(modalParam.hqBranchData.branch_list[hqIdx][0]);
    }
  }, [hqIdx]);

  const getRegPathList = () => {
    setLoading(true);

    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/erp_set/reg_path", {
      params: { reg_type: "new" },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          setRegPathList(res.data.data);
        }
      })
      .catch((err) => {
        console.log("err");
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getBranchCsStaffList = (branchId) => {
    if (branchId !== "") {
      HttpInstance.get(process.env.REACT_APP_STAFF_SERVER + "/cs/team/list", {
        params: {
          branch_id: branchId,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            const restData = res.data.data;
            // console.log(restData);
            setGuestStaffBookState((cur) => {
              return {
                ...cur,
                cs_team_list: restData.cs_team_list,
                cs_team_name_list: restData.cs_team_name_list,
              };
            });
          }
        })
        .catch((err) => {
          console.log("err");
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    }
  };

  const onSelectHq = (hqName) => {
    const idx = modalParam.hqBranchData.target_list.findIndex((x) => x.hq_info.hq_name === hqName);
    const hInfo = modalParam.hqBranchData.target_list[idx].hq_info;
    guestBookState.hq_info = hInfo;
    setHqIdx(idx);
  };

  const onSelectBranch = (branchName) => {
    const bInfo = modalParam.hqBranchData.target_list[hqIdx].branch_list.find(
      (x) => x.branch_name === branchName,
    );
    guestBookState.branch_info = bInfo;
    getBranchCsStaffList(bInfo.branch_id);
  };

  const onCheckPhone = () => {
    if (phone1.length === 3 && phone2.length >= 3 && phone3.length === 4) {
      HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/check_phone", {
        params: {
          phone: phone1 + "-" + phone2 + "-" + phone3,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            const isDuplicate = res.data.data;
            if (isDuplicate === "N") {
              guestBookState.phone = phone1 + "-" + phone2 + "-" + phone3;
              setPhoneCheckStatus("Y");
            } else {
              setPhoneCheckStatus("N");
              guestBookState.phone = "";
            }
          }
        })
        .catch((err) => {
          console.log("err");
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    } else {
      setPhoneCheckStatus("");
    }

    setPhone1(phone1);
    setPhone2(phone2);
    setPhone3(phone3);
  };

  const onClickSave = () => {
    // console.log(guestBookState);

    // validation
    let res = "";
    if (!guestBookState.cs_date || !guestBookState.cs_time) res += "· 방문일시\n";
    if (!guestBookState.hq_info.hq_name) res += "· 본부\n";
    if (!guestBookState.branch_info.branch_name) res += "· 지점\n";
    if (!guestBookState.member_name) res += "· 고객명\n";
    if (!guestBookState.phone) res += "· 연락처\n";
    if (!guestBookState.inquiry_title) res += "· 주요 문의 내용\n";
    if (!guestBookState.inquiry_memo) res += "· 방문 상담 내용\n";
    if (!guestBookState.gender) res += "· 성별\n";
    if (!guestBookState.ages) res += "· 연령대\n";
    if (!guestBookState.visit_path) res += "· 방문경로\n";
    if (!guestBookState.cs_staff_info.staff_name) res += "· 상담자\n";

    if (res) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="필수값을 모두 입력해주셔야 합니다." />
              <br />
              <br />
              <Label labelText={res} whiteSpace="pre-wrap" />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      simpleAlert(storesDispatch, "알림", "등록하시겠습니까?", false, undefined, () => {
        onSave();
      });
    }
  };

  const onSave = async () => {
    guestBookState.member_name = guestBookState.member_name.trim();

    const formData = new FormData();
    // console.log("guestBookState ", guestBookState);
    formData.append("new_info", JSON.stringify(guestBookState));

    setLoading(true);

    // 전화번호 기존 소유자가 있으면 삭제 API call
    if (removePhoneMemberId) {
      const res = HttpInstance.patch(
        process.env.REACT_APP_USER_SERVER + "/member_info/delete_phone",
        "",
        {
          params: {
            member_id: removePhoneMemberId,
            phone: guestBookState.phone,
          },
        },
      );
      if (res.msg) {
        setLoading(false);
        simpleAlert(storesDispatch, "알림", res.msg);
        return;
      }
    }

    await HttpInstance.post(
      process.env.REACT_APP_USER_SERVER + "/cs/guest_book/add",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          // console.log(res);
          onModalDone();
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDuplModalDone = (retData) => {
    viewModelRef.current.setClose();

    setPhoneCheckStatus("Y");
    guestBookState.phone = retData.base_info.phone;

    if (retData.is_remove) {
      // 아니오 선택 시 -> 새로 방명록 정보 입력 후 기존 번호 사용자의 연락처 지움
      setRemovePhoneMemberId(retData.member_info.member_id);
      setIsExistMember(false);
    } else {
      // 예 선택 시 -> 기존 정보 가져와서 화면에 세팅
      setRemovePhoneMemberId("");
      setGuestBookState({
        ...guestBookState,
        member_id: retData.member_info.member_id,
        member_name: retData.member_info.member_name,
        gender: retData.base_info.gender,
        agree_marketing: retData.base_info.agree_marketing,
        agree_personal: retData.base_info.agree_personal,
      });
      setIsExistMember(true);
    }
  };

  const OpenDuplPopup = () => {
    const param = {
      phone: phone1 + "-" + phone2 + "-" + phone3,
      branch_info: guestBookState.branch_info,
      is_guestbook: true,
    };
    setModalInfo({
      show: true,
      title: "연락처 중복 확인",
      Content: <RegisterPhoneCheckModal modalParam={param} onModalDone={onDuplModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  return (
    <div style={{ width: "30rem" }}>
      {loading && <Loading />}
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

      <div style={{ display: "flex", marginTop: "0.5rem" }}>
        <IconButton type="border" />
        <Label labelText="방문 정보" marginLeft="0.5rem" fontWeight="bold" />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem", marginLeft: "1rem" }}>
        <DatePicker
          required
          labelText="일시"
          labelWidth={labelWidthShort}
          textBoxWidth="9.5rem"
          labelMarginRight="0rem"
          textMarginRight="0rem"
          defaultValue={guestBookState.cs_date || dateToStr(new Date())}
          onChangeCallback={(e) => {
            guestBookState.cs_date = e;
          }}
          disabled={modalParam.mode === "show"}
        />
        <TimePicker
          required
          marginLeft="0.25rem"
          labelMarginRight="0rem"
          textMarginRight={"0rem"}
          textBoxWidth="9.5rem"
          defaultValue={guestBookState.cs_time}
          onChange={(e) => {
            guestBookState.cs_time = e;
          }}
          disabled={modalParam.mode === "show"}
        />
      </div>

      <div>
        {modalParam.mode === "show" ? (
          <div style={{ display: "flex", marginTop: "0.5rem", marginLeft: "0.5rem" }}>
            <div style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}>
              <TextField
                labelText="본부"
                labelWidth={labelWidthShort}
                labelMarginRight="1.1rem"
                textMarginLeft="0rem"
                textMarginRight="0rem"
                textBoxWidth="9.5rem"
                fullWidth
                disabled={modalParam.mode === "show"}
                defaultValue={guestBookState.hq_info.hq_name}
              />
            </div>
            <div style={{ marginTop: "0.5rem", marginLeft: "1rem" }}>
              <TextField
                labelText="지점"
                labelWidth={labelWidthShort}
                labelMarginRight="0.5rem"
                labelMarginLeft="0rem"
                textBoxWidth="9.5rem"
                fullWidth
                disabled={modalParam.mode === "show"}
                defaultValue={guestBookState.branch_info.branch_name}
              />
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", marginTop: "0.5rem", marginLeft: "1rem" }}>
            <ComboBox
              labelText="본부"
              labelWidth={labelWidthShort}
              labelMarginRight="0rem"
              comboItemWidth="9.5rem"
              // data={[]}
              data={modalParam.hqBranchData.hq_list}
              defaultValue={modalParam.hqBranchData.hq_list[0]}
              disabled={modalParam.mode === "show"}
              onChangeCallback={(e) => {
                onSelectHq(e.target.value);
              }}
            />
            <SearchComboBox
              labelText="지점"
              labelWidth={labelWidthShort}
              labelMarginRight="0rem"
              comboItemWidth="9.5rem"
              marginLeft="1.5rem"
              data={hqIdx !== undefined ? modalParam.hqBranchData.branch_list[hqIdx] : []}
              defaultValue={modalParam.hqBranchData.branch_list[hqIdx][0]}
              onChangeCallback={(e, v) => {
                onSelectBranch(v);
              }}
              disabled={modalParam.mode === "show"}
            />
          </div>
        )}
      </div>

      <div style={{ display: "flex", marginTop: "2rem" }}>
        <IconButton type="border" />
        <Label labelText="고객 정보" marginLeft="0.5rem" fontWeight="bold" />
      </div>
      <div style={{ marginTop: "0.5rem", marginLeft: "1rem" }}>
        <TextField
          required
          labelText="이름"
          labelWidth={labelWidthShort_2}
          labelMarginRight="0rem"
          textMarginLeft="0rem"
          textBoxWidth="15.5rem"
          disabled={modalParam.mode === "show" || isExistMember}
          defaultValue={guestBookState.member_name}
          onChangeCallback={(e) => {
            guestBookState.member_name = e;
          }}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5rem", marginLeft: "1rem" }}>
        {modalParam.mode === "show" ? (
          <TextField
            labelText="연락처"
            labelWidth={labelWidthShort_2}
            labelMarginRight="0rem"
            textMarginLeft="0rem"
            textBoxWidth="15.5rem"
            disabled={modalParam.mode === "show"}
            defaultValue={guestBookState.phone}
            onChangeCallback={(e) => {
              guestBookState.phone = e;
            }}
          />
        ) : (
          <div style={{ display: "flex" }}>
            <TextField
              inputRef={phoneRef1}
              required
              labelMarginRight="0rem"
              textMarginRight="0.25rem"
              labelWidth={labelWidthShort_2}
              textBoxWidth={"4.5rem"}
              labelText="연락처"
              maxLength={3}
              isStringNumber
              disabled={isExistMember}
              onChangeCallback={(e) => {
                phone1 = e;
                if (phone1.length === 3) {
                  phoneRef2.current.select();
                }
                onCheckPhone();
              }}
            />
            <TextField
              inputRef={phoneRef2}
              required
              textMarginRight="0.25rem"
              labelMarginRight="0.25rem"
              labelWidth="0.5rem"
              labelText="-"
              maxLength={4}
              isStringNumber
              disabled={isExistMember}
              textBoxWidth={"4.5rem"}
              onChangeCallback={(e) => {
                phone2 = e;
                onCheckPhone();
                if (phone2.length === 4) {
                  phoneRef3.current.select();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Backspace" && !phone2) {
                  phoneRef1.current.select();
                }
              }}
            />
            <TextField
              inputRef={phoneRef3}
              required
              // textMarginRight="0.25rem"
              labelMarginRight="0.25rem"
              labelWidth="0.5rem"
              labelText="-"
              maxLength={4}
              isStringNumber
              disabled={isExistMember}
              textBoxWidth={"4.5rem"}
              onChangeCallback={(e) => {
                phone3 = e;
                onCheckPhone();
              }}
              onKeyDown={(e) => {
                if (e.key === "Backspace" && !phone3) {
                  phoneRef2.current.select();
                }
              }}
              // onBlur={() => onCheckPhone1()}
            />
            {phoneCheckStatus === "Y" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  alt="O"
                  src={successIcon}
                  style={{
                    display: "flex",
                    width: "1.4rem",
                    height: "1.4rem",
                    marginRight: "0.5rem",
                  }}
                />
              </div>
            ) : phoneCheckStatus === "N" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  alt="O"
                  src={failIcon}
                  style={{
                    display: "flex",
                    width: "1.4rem",
                    height: "1.4rem",
                    marginRight: "0.5rem",
                  }}
                />
                {/* <Label labelText="이미 등록된 번호입니다." fontSize="0.8rem" /> */}
                <NormalBtn
                  width="5.5rem"
                  name="중복확인"
                  theme="white"
                  onClick={() => {
                    OpenDuplPopup();
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>

      {/* 성별 연령대 방문경로 */}
      <div style={{ marginTop: "0.5rem", marginLeft: "1rem" }}>
        {modalParam.mode === "show" ? (
          <TextField
            labelText="성별"
            labelWidth={labelWidthShort_2}
            labelMarginRight="0rem"
            textMarginLeft="0rem"
            textBoxWidth="15.5rem"
            disabled
            defaultValue={guestBookState.gender}
          />
        ) : (
          <ComboBox
            required
            labelWidth={labelWidthShort_2}
            comboItemWidth="15.5rem"
            labelMarginRight="0rem"
            textMarginLeft="0rem"
            labelText="성별"
            data={["남", "여"]}
            disabled={isExistMember}
            defaultValue={guestBookState.gender || ""}
            onChangeCallback={(e) => {
              guestBookState.gender = e.target.value;
            }}
          />
        )}
      </div>
      <div style={{ marginTop: "0.5rem", marginLeft: "1rem" }}>
        {modalParam.mode === "show" ? (
          <TextField
            labelText="연령대"
            labelWidth={labelWidthShort_2}
            labelMarginRight="0rem"
            textMarginLeft="0rem"
            textBoxWidth="15.5rem"
            disabled
            defaultValue={guestBookState.ages}
          />
        ) : (
          <ComboBox
            required
            labelWidth={labelWidthShort_2}
            comboItemWidth="15.5rem"
            labelMarginRight="0rem"
            textMarginLeft="0rem"
            labelText="연령대"
            data={["10~20대", "30대", "40대", "50대 이상"]}
            defaultValue={guestBookState.ages || ""}
            onChangeCallback={(e) => {
              guestBookState.ages = e.target.value;
            }}
          />
        )}
      </div>
      <div style={{ marginTop: "0.5rem", marginLeft: "1rem" }}>
        {modalParam.mode === "show" ? (
          <TextField
            labelText="방문경로"
            labelWidth={labelWidthShort_2}
            labelMarginRight="0rem"
            textMarginLeft="0rem"
            textBoxWidth="15.5rem"
            disabled
            defaultValue={guestBookState.visit_path}
          />
        ) : (
          <ComboBox
            required
            labelWidth={labelWidthShort_2}
            comboItemWidth="15.5rem"
            labelMarginRight="0rem"
            textMarginLeft="0rem"
            labelText="방문경로"
            data={regPathList}
            defaultValue={guestBookState.visit_path || ""}
            onChangeCallback={(e) => {
              guestBookState.visit_path = e.target.value;
            }}
          />
        )}
      </div>
      {/* 개인정보 활용 및 마케팅 정보 수신 동의 체크 박스 */}
      {/* <div style={{ display: "flex", marginTop: "0.5rem", marginLeft: "8rem" }}>
        <CheckBox
          isTable
          labelText="개인정보 활용 동의"
          defaultValue={guestBookState.agree_personal}
          onChangeCallback={(e) => {
            guestBookState.agree_personal = e.checked;
          }}
          disabled={modalParam.mode === "show" || isExistMember}
        />
        <div style={{ marginLeft: "2rem" }}>
          <CheckBox
            isTable
            labelMarginLeft="0.25rem"
            labelText="마케팅 정보 수신 동의"
            defaultValue={guestBookState.agree_marketing}
            onChangeCallback={(e) => {
              guestBookState.agree_marketing = e.checked;
            }}
            disabled={modalParam.mode === "show" || isExistMember}
          />
        </div>
      </div> */}

      <div style={{ display: "flex", marginTop: "2rem" }}>
        <IconButton type="border" />
        <Label labelText="상담 내용" marginLeft="0.5rem" fontWeight="bold" />
      </div>
      <div style={{ marginTop: "0.5rem", marginLeft: "1rem" }}>
        <ComboBox
          required
          labelText="주요 문의 내용"
          labelWidth={labelWidth}
          labelMarginRight="0rem"
          fullWidth
          data={[
            "가격(회원권, 부가상품, 지점변경 등)",
            "PT 상담 및 예약",
            "센터 이용 날짜/시간",
            "타 지점 이용 방법",
            "시설 이용 관련",
            "기타 (직접 입력)",
          ]}
          defaultValue={guestBookState.inquiry_title}
          onChangeCallback={(e) => {
            guestBookState.inquiry_title = e.target.value;
          }}
          disabled={modalParam.mode === "show"}
        />
        <TextArea
          required
          labelText="방문 상담 내용"
          labelWidth={labelWidth}
          marginTop="0.5rem"
          labelMarginRight="0rem"
          fullWidth
          minRows={10}
          maxRows={10}
          disabled={modalParam.mode === "show"}
          defaultValue={guestBookState.inquiry_memo}
          onChangeCallback={(e) => {
            guestBookState.inquiry_memo = e.target.value;
          }}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "end", marginTop: "0.5rem" }}>
        <div>
          {modalParam.mode === "show" ? (
            <TextField
              labelText="상담자"
              labelWidth={"3rem"}
              labelMarginRight="0.5rem"
              textBoxWidth="9rem"
              textMarginLeft="0rem"
              textMarginRight="0rem"
              disabled
              defaultValue={guestBookState.cs_staff_info.staff_name}
            />
          ) : (
            <ComboBox
              required
              labelWidth={"4.5rem"}
              comboItemWidth={"10rem"}
              marginRight="0rem"
              labelText="상담자"
              data={guestBookStaffState.cs_team_name_list}
              onChangeCallback={(e) => {
                const index = guestBookStaffState.cs_team_name_list
                  .map((item) => item)
                  .indexOf(e.target.value);
                if (index !== -1) {
                  guestBookState.cs_staff_info.staff_id =
                    guestBookStaffState.cs_team_list.team[index].staff_id;
                  guestBookState.cs_staff_info.staff_name =
                    guestBookStaffState.cs_team_list.team[index].staff_name;
                  guestBookState.cs_staff_info.sabun =
                    guestBookStaffState.cs_team_list.team[index].sabun;
                  guestBookState.cs_staff_info.staff_pos =
                    guestBookStaffState.cs_team_list.team[index].position.org_name;
                  guestBookState.cs_staff_info.staff_rank =
                    guestBookStaffState.cs_team_list.team[index].rank.org_name;
                }
              }}
            />
          )}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "1.75rem" }}>
        {modalParam.mode === "show" ? (
          <NormalBtn
            name="확인"
            onClick={() => {
              onModalDone();
            }}
          />
        ) : (
          <NormalBtn
            name="저장"
            onClick={() => {
              onClickSave();
            }}
          />
        )}
      </div>
    </div>
  );
};
export default GuestBookDetailModal;
