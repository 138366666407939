import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, dataEmpty, dateToStr, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import DatePicker from "components/LabelInput/DatePicker";
import NumberTextField from "components/LabelInput/NumberTextField";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { income_item_state } from "../../Module/reducers/income_item_state";

// mode : "edit", "add"

const IncomeExpenseAddModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();

  const [itemList, setItemList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modalParam.mode === "edit") {
      setItemList([modalParam.data]);
    } else {
      onClickAdd();
    }
  }, []);

  const onClickDelete = (itemId) => {
    setItemList(itemList.filter((x) => x.item_id !== itemId));
  };

  const onClickAdd = () => {
    const newItem = _.cloneDeep(income_item_state);
    newItem.item_id = uuidv4();
    if (itemList.length === 0) {
      newItem.is_old = true;
    }

    setItemList([...itemList, newItem]);
  };

  const onClickSave = () => {
    let isOk = true;
    for (let i = 0; i < itemList.length; i++) {
      if (!itemList[i].reg_date || !itemList[i].account || !itemList[i].price) {
        isOk = false;
      }
    }
    if (isOk) {
      simpleAlert(storesDispatch, "알림", "저장하시겠습니까?", false, undefined, () => {
        onSave();
      });
    } else {
      simpleAlert(storesDispatch, "알림", "입력되지 않은 항목이 있습니다.");
    }
  };

  const onSave = async () => {
    const formData = new FormData();
    let post_api;
    let itemParamName = "";

    if (modalParam.type === "income") {
      //
      itemParamName = "income_item";
      if (modalParam.mode === "add") {
        post_api = process.env.REACT_APP_SALARY_SERVER + "/cashbook/income/add";
        for (let i = 0; i < itemList.length; i++) {
          itemList[i].item_id = "";
        }
      } else {
        post_api = process.env.REACT_APP_SALARY_SERVER + "/cashbook/income/update";
        formData.append("item_id", itemList[0].item_id);
      }
      //
    } else {
      //
      itemParamName = "expense_item";
      if (modalParam.mode === "add") {
        post_api = process.env.REACT_APP_SALARY_SERVER + "/cashbook/expense/add";
        for (let i = 0; i < itemList.length; i++) {
          itemList[i].item_id = "";
        }
      } else {
        post_api = process.env.REACT_APP_SALARY_SERVER + "/cashbook/expense/update";
        formData.append("item_id", itemList[0].item_id);
      }
      //
    }

    setLoading(true);

    if (modalParam.mode === "add") {
      formData.append("cashbook_id", modalParam.cashbookId);
      formData.append(itemParamName, JSON.stringify(itemList));

      await HttpInstance.put(post_api, formData, contentTypeJson)
        .then((res) => {
          if (res.data.msg) {
            simpleAlert(storesDispatch, "ERROR", res.data.msg);
          } else {
            simpleAlert(storesDispatch, "알림", "저장되었습니다.", true, () => {
              onModalDone();
            });
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(
            storesDispatch,
            "ERROR",
            err.response.data ? err.response.data.msg : err.code,
          );
        })
        .finally(() => {
          setLoading(false);
        });
      //
    } else {
      //
      formData.append("cashbook_id", modalParam.cashbookId);
      formData.append(itemParamName, JSON.stringify(itemList[0]));

      await HttpInstance.patch(post_api, formData, contentTypeJson)
        .then((res) => {
          if (res.data.msg) {
            simpleAlert(storesDispatch, "ERROR", res.data.msg);
          } else {
            simpleAlert(storesDispatch, "알림", "저장되었습니다.", true, () => {
              onModalDone();
            });
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(
            storesDispatch,
            "ERROR",
            err.response.data ? err.response.data.msg : err.code,
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const transferList = new itemListTable(onClickDelete, modalParam.typeList, modalParam.baseMonth);

  return (
    <div style={{ width: "60rem" }}>
      {loading && <Loading />}

      <div style={{ margin: "0.5rem 0.1rem" }}>
        <CustomTable
          columns_head={transferList.columns_head}
          table_title={transferList.table_title}
          rest_call={transferList.get_data_from_rest}
          row_render={transferList.create_table}
          rest_data={itemList}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
        {modalParam.mode === "add" ? (
          <Button
            className={clsx({
              [btnStyleClass.btnRootWhite]: true,
              [btnStyleClass.buttonType7]: true,
            })}
            onClick={() => {
              onClickAdd();
            }}
          >
            {modalParam.type === "income" ? "수입 항목 추가" : "지출 항목 추가"}
          </Button>
        ) : (
          <></>
        )}
        <Button
          style={{ marginLeft: "0.5rem" }}
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType7]: true,
          })}
          onClick={() => {
            onClickSave();
          }}
        >
          저장
        </Button>
      </div>
    </div>
  );
};
export default IncomeExpenseAddModal;

//

class itemListTable {
  table_title = "";
  onClickDelete = null;
  typeList = ["전체"];
  baseMonth = "";

  constructor(onClickDelete, paramTypeList, baseMonth) {
    this.onClickDelete = onClickDelete;
    this.typeList = paramTypeList;
    this.baseMonth = baseMonth;
  }

  getTargetYear = () => {
    return this.baseMonth ? parseInt(this.baseMonth) : new Date().getFullYear();
  };

  getTargetMonth = () => {
    return this.baseMonth ? parseInt(this.baseMonth.split("-")[1]) - 1 : new Date().getMonth();
  };

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>
          <DatePicker
            required
            fullWidth
            labelMarginRight="0rem"
            minDate={dateToStr(new Date(this.getTargetYear(), this.getTargetMonth(), 1))} // 이번 달 1일
            maxDate={dateToStr(new Date(this.getTargetYear(), this.getTargetMonth() + 1, 0))} // 이번 달 마지막 날
            defaultValue={data.reg_date}
            onChangeCallback={(e) => {
              data.reg_date = e;
            }}
          />
        </CustomTableContents>
        <CustomTableContents>
          <SearchComboBox
            required
            labelMarginRight="0rem"
            fullWidth
            data={this.typeList}
            defaultValue={data.account || ""}
            onChangeCallback={(e, v) => {
              data.account = v;
            }}
          />
        </CustomTableContents>
        <CustomTableContents>
          <NumberTextField
            required
            labelMarginRight="0rem"
            fullWidth
            endAdornment="원"
            defaultValue={data.price}
            onChangeCallback={(e) => {
              data.price = e;
            }}
          />
        </CustomTableContents>
        <CustomTableContents>
          <TextField
            labelMarginRight="0rem"
            fullWidth
            defaultValue={data.memo}
            onChangeCallback={(e) => {
              data.memo = e;
            }}
          />
        </CustomTableContents>
        <CustomTableContents>
          <TextField
            labelMarginRight="0rem"
            fullWidth
            defaultValue={data.reason}
            onChangeCallback={(e) => {
              data.reason = e;
            }}
          />
        </CustomTableContents>
        <CustomTableContentsEND>
          {!data.is_old ? (
            <IconButton type="delete" onClick={() => this.onClickDelete(data.item_id)} />
          ) : (
            <></>
          )}
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "날짜", width: "20%" },
    { title: "계정", width: "20%" },
    { title: "금액", width: "20%" },
    { title: "메모", width: "20%" },
    { title: "사유", width: "30%" },
    { title: "", width: "10%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
