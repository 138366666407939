import IconButton from "components/Button/IconButton";
import { dataEmpty, getPermission } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import NormalBtn from "components/NewButton/NormalBtn";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const IncomeResultTable = ({
  searchResult,
  onClickEdit,
  onClickDelete,
  onClickSearch,
  onClickAdd,
  curPage,
  totalPage,
  infoStateData,
}) => {
  const storesDispatch = useDispatch();

  const [canExcel, setCanExcel] = useState(false);

  const checkPermission = async () => {
    const result = await getPermission(storesDispatch, "ACCOUNT_PERMISSION/EXCEL");
    setCanExcel(result);
  };

  useEffect(() => {
    checkPermission();
  }, []);

  const doSearch = (page) => {
    onClickSearch(false, page);
  };

  const searchResultList = new resultTable(onClickEdit, onClickDelete);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "0.625rem",
        }}
      >
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <Label labelText="수입 상세 내역" marginLeft="0.5rem" fontWeight="bold" />
        </div>

        <div style={{ display: "flex", gap: "0.3rem" }}>
          {canExcel && (
            <NormalBtn
              name="엑셀 다운로드"
              theme="green"
              onClick={() => {
                storesDispatch(
                  globalAlertOn({
                    show: true,
                    titleText: "알림",
                    bodyText: (
                      <>
                        <Label
                          labelText={`"${infoStateData.branch_info.branch_name}_${infoStateData.base_month}_수입상세내역"`}
                        />
                        <Label labelText={`엑셀 파일을 다운로드 하시겠습니까?`} />
                      </>
                    ),
                    onBtnYes: () => onClickSearch(true, 1),
                    onBtnNo: () => {},
                  }),
                );
              }}
            />
          )}
          <NormalBtn name="수입 내역 추가" theme="white" onClick={onClickAdd} />
        </div>
      </div>

      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        // height="30rem"
      />
      <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={doSearch} />
    </div>
  );
};
export default IncomeResultTable;

class resultTable {
  table_title = "";
  onClickEdit = null;
  onClickDelete = null;

  constructor(onClickEdit, onClickDelete) {
    this.onClickEdit = onClickEdit;
    this.onClickDelete = onClickDelete;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>{data.reg_date}</CustomTableContents>
        <CustomTableContents>{data.create_type}</CustomTableContents>
        <CustomTableContents>{data.reason}</CustomTableContents>
        <CustomTableContents>{data.account}</CustomTableContents>
        <CustomTableContents $isNumber>{data.price.toLocaleString()}</CustomTableContents>
        <CustomTableContents>{data.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_name}</CustomTableContents>
        <CustomTableContents>{data.memo}</CustomTableContents>
        {data.create_type !== "자동" ? (
          <CustomTableContentsEND>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IconButton type="edit" onClick={() => this.onClickEdit(data)} tooltip="수정" />
              <IconButton
                type="delete"
                onClick={() => this.onClickDelete(data)}
                marginLeft="1.5rem"
                tooltip="삭제"
              />
            </div>
          </CustomTableContentsEND>
        ) : (
          <CustomTableContentsEND></CustomTableContentsEND>
        )}
      </CustomRow>
    );
  };

  columns_head = [
    { title: "날짜", width: "9%" },
    { title: "입력 구분", width: "8%" },
    { title: "사유", width: "12%" },
    { title: "계정", width: "14%" },
    { title: "금액", width: "12%" },
    { title: "회원번호", width: "12%" },
    { title: "회원명", width: "12%" },
    { title: "메모", width: "15%" },
    { title: "", width: "12%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
