import { getDefaultValue, SubLine } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import NormalBtn from "components/NewButton/NormalBtn";
import { SearchConditionBox, SearchContainer, SearchRowBox } from "../style";
const SearchCardRow = ({ searchParam, setSearchParam, onClickSearch, gxBranch }) => {
  const labelWidth = "5.5rem";
  const textBoxWidth = "14rem";

  return (
    <SearchContainer
      onKeyDown={(e) => {
        if (e.key === "Enter") onClickSearch(1);
      }}
    >
      <Label labelText="검색 조건: 상세" fontWeight="bold" justifyContent="flex-start" />
      <SearchRowBox>
        <SearchConditionBox>
          <SearchComboBox
            labelWidth={labelWidth}
            comboItemWidth={textBoxWidth}
            data={gxBranch.branch_name_list}
            labelText="지점"
            defaultValue={getDefaultValue("combo", searchParam.branch_info.branch_name)}
            onChangeCallback={(e, v) => {
              const idx = gxBranch.branch_name_list.findIndex((el) => el === v);
              setSearchParam((cur) => {
                const branch = gxBranch.branch_list[idx];
                return { ...cur, branch_info: branch };
              });
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            labelText="수업코드"
            defaultValue={searchParam.gx_code}
            onChangeCallback={(e) => {
              setSearchParam((cur) => {
                return { ...cur, gx_code: e };
              });
            }}
          />
        </SearchConditionBox>
        <SearchConditionBox>
          <TextField
            labelWidth={labelWidth}
            textBoxWidth={textBoxWidth}
            labelText="수업명"
            defaultValue={searchParam.class_name}
            onChangeCallback={(e) => {
              setSearchParam((cur) => {
                return { ...cur, class_name: e };
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onClickSearch(1);
              }
            }}
          />
        </SearchConditionBox>
      </SearchRowBox>
    </SearchContainer>
  );
};

const GxSearchCard = ({
  searchParam,
  setSearchParam,
  resetSearchParam,
  onClickSearch,
  gxBranch,
}) => {
  return (
    <div>
      <SearchCardRow
        onClickSearch={onClickSearch}
        searchParam={searchParam}
        setSearchParam={setSearchParam}
        gxBranch={gxBranch}
      />
      <div
        style={{
          marginTop: "0.7rem",
          display: "flex",
          justifyContent: "flex-end",
          gap: "0.3rem",
        }}
      >
        <NormalBtn
          name="초기화"
          theme="gray"
          onClick={() => {
            resetSearchParam();
          }}
        />
        <NormalBtn
          name="검색"
          onClick={() => {
            onClickSearch(1);
          }}
        />
      </div>
      <SubLine />
    </div>
  );
};

export default GxSearchCard;
