import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";

// 당월 매출 내역
const MonthlySalesRecordList = ({ openList, setOpenList, salesList }) => {
  const dataTableItem = new dataTable();

  return (
    <>
      <div
        style={{
          display: "flex",
          backgroundColor: `${openList[0] ? "#4C679D" : "#E8EDF7"}`,
          alignItems: "center",
          boxSizing: "border-box",
          cursor: "pointer",
        }}
        onClick={() => {
          setOpenList((cur) => {
            const arr = [...cur];
            arr[0] = !arr[0];
            return arr;
          });
        }}
      >
        <div
          style={{
            paddingLeft: "0.7rem",
            width: "100%",
            fontWeight: "bold",
            color: `${openList[0] ? "#FFFFFF" : "#032063"}`,
            textAlign: "left",
            fontSize: "0.9rem",
          }}
        >
          당월 매출 내역
        </div>
        <div style={{ width: "2rem" }}>
          {!openList[0] ? (
            <KeyboardArrowDownIcon style={{ color: "#777777", fontSize: "1.7rem" }} />
          ) : (
            <KeyboardArrowUpIcon style={{ color: "#FFFFFF", fontSize: "1.7rem" }} />
          )}
        </div>
      </div>
      <Collapse in={openList[0]} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <CustomTable
            columns_head={dataTableItem.columns_head}
            table_title={dataTableItem.table_title}
            rest_call={dataTableItem.get_data_from_rest}
            row_render={dataTableItem.create_table}
            rest_data={salesList}
            maxHeight={"28rem"}
          />
        </Box>
      </Collapse>
    </>
  );
};
export default MonthlySalesRecordList;

class dataTable {
  table_title = "";

  makeSalesType = (data) => {
    if (data.pt_info.sales_type.includes("환불")) {
      return "환불";
    } else {
      return data.pt_info.sales_type + " (" + data.pt_info.join_type + ")";
    }
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.sales_info.enroll_reg_date}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.pt_info.base_session_cnt}</CustomTableContents>
        <CustomTableContents>{data.pt_info.service_session_cnt}</CustomTableContents>
        <CustomTableContents>
          {data.pt_info.pt_price.per_price.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContents>
          {data.incentive.incentive.toLocaleString() + " 원"}
        </CustomTableContents>
        <CustomTableContentsEND align="center">{this.makeSalesType(data)}</CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "계약일", width: "12.5%" },
    { title: "회원번호", width: "12.5%" },
    { title: "회원명", width: "12.5%" },
    { title: "기본세션", width: "12.5%" },
    { title: "서비스 세션", width: "12.5%" },
    { title: "세션가", width: "12.5%" },
    { title: "매출액", width: "12.5%" },
    { title: "매출구분", width: "12.5%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
