import { contentTypeJson, generateObjectZeroId, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const StaffChangeModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [staffList, setStaffList] = useState([]);
  let selected;

  useEffect(() => {
    getStaffList();
  }, []);

  const makeStaffList = (list) => {
    const ret = [];
    for (let i = 0; i < list.length; i++) {
      const info = {
        staff_id: list[i].staff_id,
        staff_name: list[i].personal_info.staff_name,
        sabun: list[i].personal_info.sabun,
      };
      ret.push(info);
    }
    setStaffList(ret);
  };

  const getStaffList = () => {
    const param = {
      branch_info: {
        branch_id: modalParam.branch_info.branch_id,
      },
      work_status: "재직",
    };
    const formData = new FormData();
    formData.append("is_sales_record", true);
    formData.append("is_excel", true);
    formData.append("page_num", 1);
    formData.append("search_param", JSON.stringify(param));
    setLoading(true);

    HttpInstance.post(
      process.env.REACT_APP_STAFF_SERVER + "/staff/search_list",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          makeStaffList(res.data.data);
        }
      })
      .catch((err) => {
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickSave = () => {
    if (!selected || !selected.staff_id) {
      simpleAlert(storesDispatch, "알림", "직원을 선택해 주세요.");
      return;
    } else if (selected.staff_id === modalParam.staff_info.staff_id) {
      simpleAlert(storesDispatch, "알림", "동일한 직원은 선택할 수 없습니다.");
      return;
    }

    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="실적 반영 직원이 변경됩니다. 저장하시겠습니까?" />,
        width: "23rem",
        onBtnYes: () => {
          onSaveEvent();
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onSaveEvent = async () => {
    const formData = new FormData();
    formData.append("record_id", modalParam.record_id);
    formData.append("branch_id", modalParam.branch_info.branch_id);
    formData.append("staff_id", selected.staff_id);

    setLoading(true);

    await HttpInstance.post(
      process.env.REACT_APP_STAFF_SERVER + "/record/change_staff",
      formData,
      contentTypeJson,
    )
      .then((res) => {
        if (res.data.msg) {
          simpleAlert(storesDispatch, "ERROR", res.data.msg);
        } else {
          onModalDone("staff");
        }
      })
      .catch((err) => {
        simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getStaffNameList = () => {
    const ret = [];

    // 기존 상담자가 할당되어 있는 경우에만 "추후 선택" 추가
    if (modalParam.staff_info.staff_id !== generateObjectZeroId()) {
      ret.push("추후 선택");
    }

    for (let i = 0; i < staffList.length; i++) {
      ret.push(staffList[i].staff_name);
    }
    return ret;
  };

  return (
    <div
      style={{
        width: "18rem",
      }}
    >
      {loading && <Loading />}

      <div style={{ marginTop: "0.625rem" }}>
        <TextField
          labelWidth="6.5rem"
          textBoxWidth="12rem"
          labelText="기존 상담자"
          labelMarginRight="0rem"
          disabled
          defaultValue={modalParam.staff_info.staff_name}
        />
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <ComboBox
          labelText="변경할 상담자"
          labelWidth="6.5rem"
          labelMarginRight="0rem"
          comboItemWidth="12rem"
          data={getStaffNameList()}
          defaultValue={""}
          onChangeCallback={(e) => {
            if (e.target.value === "추후 선택") {
              selected = {
                sabun: "",
                staff_id: generateObjectZeroId(),
                staff_name: e.target.value,
              };
            } else {
              selected = staffList.find((x) => x.staff_name === e.target.value);
            }
          }}
        />
      </div>

      <div style={{ marginTop: "2rem", display: "flex", justifyContent: "center" }}>
        <NormalBtn
          name="확인"
          onClick={() => {
            onClickSave();
          }}
        />
      </div>
    </div>
  );
};
export default StaffChangeModal;
