import { useQuery } from "@tanstack/react-query";
import { getPtSalesList } from "apis/staff/sales_record_api";
import { generateObjectZeroId, simpleAlert, SubLine } from "components/CommonLib/CommonLib";
import NormalBtn from "components/NewButton/NormalBtn";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSalesRecordStore } from "../modules/store/salesRecordState";
import SalesPriceChangeModal from "../SalesPriceChangeModal";
import StaffChangeModal from "../StaffChangeModal";
import PtRecordResultTable from "./PtRecordResultTable";
import SearchCondition from "./SearchCondition";

const PtRecordView = ({ isAdmin }) => {
  const storesDispatch = useDispatch();
  // 전역 상태 관리
  const { setLoading, searchParams, setPage, page, resetSearchParams } =
    useSalesRecordStore().returnStoreByType("pt");

  const [searchResult, setSearchResult] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);
  // API 상태 관리
  const [isGetSalesList, setIsGetSalesList] = useState(false);
  const queryData = { page: page, searchParams: searchParams };

  // API Query
  // [GET] PT 실적 내역 리스트 검색
  const { data: salesListData } = useQuery({
    queryKey: ["salesRecord", "pt", queryData],
    queryFn: () => getPtSalesList(queryData),
    enabled: isGetSalesList,
    retry: false,
    cacheTime: 0,

    onSuccess: (res) => {
      if (res.data.msg) {
        simpleAlert(storesDispatch, "알림", res.data.msg);
      }
    },
    onError: (err) => {
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    },
    onSettled: () => {
      setIsGetSalesList(false);
      setLoading(false);
    },
  });

  // PT 실적 리스트 가져오기
  const onClickSearch = async (page) => {
    if (
      searchParams.hq_info.hq_id === generateObjectZeroId() ||
      searchParams.branch_info.branch_id === generateObjectZeroId()
    ) {
      simpleAlert(storesDispatch, "알림", "본부와 지점을 선택해주세요.");
      return;
    }
    setPage(page);
    setLoading(true);
    setIsGetSalesList(true);
  };

  // API 받아온 데이터 세팅
  useEffect(() => {
    // 검색 결과 데이터 세팅
    if (salesListData) {
      setSearchResult(salesListData.data.data);
      setTotalPage(salesListData.data.total_page);
    }
  }, [salesListData]);

  // 페이지 진입 시 리스트 받아오기
  useEffect(() => {
    if (isFirstEnterPage && searchParams.hq_info.hq_id !== generateObjectZeroId()) {
      onClickSearch(page);
      setIsFirstEnterPage(false);
    }
  }, [searchParams.hq_info.hq_id]);

  const onModalDone = (type) => {
    storesDispatch(globalModalOff());
    onClickSearch(page);
    simpleAlert(
      storesDispatch,
      "알림",
      `${type === "staff" ? "상담자가" : "실적금액이"} 변경되었습니다.`,
    );
  };

  const openModal = (param, type) => {
    let Modal = null;
    if (type === "staff") {
      // 상담자 변경
      Modal = <StaffChangeModal modalParam={param} onModalDone={onModalDone} />;
    } else {
      // 금액 변경
      Modal = <SalesPriceChangeModal modalParam={param} onModalDone={onModalDone} />;
    }
    storesDispatch(
      globalModalOn({
        show: true,
        title: type === "staff" ? "상담자 변경" : "실적금액 변경",
        Content: Modal,
      }),
    );
  };

  return (
    <div>
      <div>
        <SearchCondition onClickSearch={onClickSearch} />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "0.75rem",
            gap: "0.3rem",
          }}
        >
          <NormalBtn
            name="초기화"
            theme="gray"
            onClick={() => {
              resetSearchParams();
            }}
          />
          <NormalBtn
            name="검색"
            onClick={() => {
              onClickSearch(1);
            }}
          />
        </div>
      </div>

      <SubLine />

      <div style={{ marginTop: "1rem" }}>
        <PtRecordResultTable
          openModal={openModal}
          searchResult={searchResult}
          curPage={page}
          totalPage={totalPage}
          onClickSearch={onClickSearch}
          isAdmin={isAdmin}
        />
      </div>
    </div>
  );
};
export default PtRecordView;
