import { getDefaultValue } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import MonthPicker from "components/LabelInput/MonthPicker";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import { useSalaryListStore } from "./modules/store/salaryListState";

const SalaryListSearchCard = ({ hqBranchListData, branchList, onClickSearch }) => {
  return (
    <div style={{ width: "100%" }}>
      <SearchCardRow1
        hqBranchListData={hqBranchListData}
        branchList={branchList}
        onClickSearch={onClickSearch}
      />
    </div>
  );
};
export default SalaryListSearchCard;

//
const SearchCardRow1 = ({ hqBranchListData, branchList, onClickSearch }) => {
  const labelWidth = "3rem";
  const compWidth = "12rem";

  // 전역 상태 관리
  const {
    salaryListSearchParams: searchParams,
    setSearchParams,
    salaryListHqIdx: hqIdx,
    setHqIdx,
    setPage,
  } = useSalaryListStore();

  return (
    <div
      style={{ display: "flex" }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          setPage(1);
          onClickSearch(true);
        }
      }}
    >
      <div>
        <ComboBox
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="본부"
          data={hqBranchListData && hqBranchListData.hq_list}
          defaultValue={getDefaultValue("combo", searchParams.hq_info.hq_name)}
          onChangeCallback={(e, idx) => {
            setHqIdx(idx);
            const hqParams = {
              hq_id: hqBranchListData.target_list[idx].hq_info.hq_id,
              hq_name: e.target.value,
            };
            setSearchParams("hq_info", hqParams);
          }}
        />
      </div>
      <div style={{ marginLeft: "2rem" }}>
        <SearchComboBox
          labelWidth={labelWidth}
          comboItemWidth={compWidth}
          labelText="지점"
          data={branchList}
          defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
          onChangeCallback={(e, v, idx) => {
            const branchParams = hqBranchListData.target_list[hqIdx].branch_list[idx];
            setSearchParams("branch_info", branchParams);
          }}
        />
      </div>
      <div style={{ display: "flex", marginLeft: "2rem" }}>
        <MonthPicker
          labelWidth={"4rem"}
          minWidth={"9rem"}
          labelText="기준 연월"
          defaultValue={getDefaultValue("field", searchParams.base_from_date)}
          onChangeCallback={(e) => setSearchParams("base_from_date", e)}
        />
        <MonthPicker
          labelWidth={"0.5rem"}
          labelText="~"
          minWidth={"9rem"}
          defaultValue={getDefaultValue("field", searchParams.base_to_date)}
          onChangeCallback={(e) => setSearchParams("base_to_date", e)}
        />
      </div>
      <div style={{ marginLeft: "3rem" }}>
        <ComboBox
          labelWidth="4rem"
          comboItemWidth={compWidth}
          labelText="결재 상태"
          defaultValue={getDefaultValue("combo", searchParams.approval_status)}
          data={["전체", "임시저장", "승인", "승인대기", "대기", "반려", "회수"]}
          onChangeCallback={(e) => {
            const res = e.target.value === "전체" ? "" : e.target.value;
            setSearchParams("approval_status", e.target.value);
          }}
        />
      </div>
    </div>
  );
};
