import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

const AddGxSalaryModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const [isActiveGx, setIsActiveGx] = useState(true);
  const [filteredArr, setFilteredArr] = useState([]);
  const [classList, setClassList] = useState([]);
  const [rawClassList, setRawClassList] = useState([]);

  const normalStyle = useMemo(() => {
    return {
      backgroundColor: "#fff",
      border: "1px solid #EBEBEB",
      padding: "0.3rem 0.6rem",
      // overflowY: "auto",
    };
  }, []);

  const selectStyle = (color_code, staffName) => {
    return {
      marginTop: "0.2rem",
      borderRadius: "5px",
      textAlign: "center",
      padding: "0.25rem 0.5rem",
      fontSize: "0.8rem",
      backgroundColor: color_code,
      cursor: "pointer",
      opacity: staffName && "0.3",
      // color: "#fff",
    };
  };
  const getGxSalaryInfo = async () => {
    try {
      const res = await HttpInstance.get(
        process.env.REACT_APP_STAFF_SERVER + "/staff/work_info/gx/list",
        {
          params: {
            branch_id: modalParam.branchId,
            staff_id: modalParam.staffId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        //성공

        const result = res.data.data;

        //01~24시중 수업 있는 거만 달력 표시
        const filtered = result.grid_item.filter((el) => {
          const newArr = el.filter((day) => day.schedule_list.length !== 0);
          return newArr.length > 0;
        });
        //지점에 등록된 수업이 없으면 화면표시 X
        if (filtered.length === 0) {
          setIsActiveGx(false);
        } else {
          setFilteredArr(filtered);
        }

        //원본은 rawList
        setRawClassList(result.all_class);

        const myClassFiltered = result.all_class.filter(
          (el) => el.cur_manager.staff_info.staff_id === modalParam.staffId,
        );
        //내가 맡은 수업만
        setClassList(myClassFiltered);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    }
  };

  useEffect(() => {
    if (modalParam.branchId) {
      getGxSalaryInfo();
    }
  }, [modalParam]);

  // 위 달력에서 수업을 선택
  const onClickClassBox = (gxId) => {
    //전체 리스트에서 현재 선택된 수업을 찾기
    const found = rawClassList.find((el) => el.gx_info.gx_id === gxId);

    // const foundIdx = classList.findIndex((el) => el.gx_info.gx_code === gxCode);

    setClassList((cur) => {
      //baseArr는 현재 내가 맡고 있는 수업들
      const baseArr = [...cur];
      const foundIdx = baseArr.findIndex((el) => el.gx_info.gx_id === found.gx_info.gx_id);
      if (foundIdx === -1) {
        //기존 리스트에 없는 새로운 거니까 추가
        baseArr.push(found);
      } else {
        //기존 리스트에 있는거니까 빠꾸
        simpleAlert(storesDispatch, "알림", "이미 선택된 수업입니다.");
      }

      return baseArr;
    });
  };

  const deleteRow = (data) => {
    if (data.cur_manager.staff_info.staff_name) {
      //아예삭제
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="담당 수업에서 삭제하시겠습니까?" />
            </>
          ),

          onBtnYes: () => {
            deleteGxSalary(data.cur_manager.staff_info, data.gx_info.gx_id);
            const deleteArr = classList.filter((el) => el.gx_info.gx_id !== data.gx_info.gx_id);
            setClassList(deleteArr);
          },
          onBtnNo: () => {},
        }),
      );
    } else {
      //리스트에서만 제외
      const deleteArr = classList.filter((el) => el.gx_info.gx_id !== data.gx_info.gx_id);
      setClassList(deleteArr);
    }
  };

  const deleteGxSalary = async (curStaffInfo, gxId) => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const formData = new FormData();
      formData.append("staff_info", JSON.stringify(curStaffInfo));
      formData.append("branch_id", modalParam.branchId);
      formData.append("gx_id", gxId);
      const res = await HttpInstance.patch(
        process.env.REACT_APP_STAFF_SERVER + "/staff/work_info/gx/detach",
        formData,
        config,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //수업삭제 성공
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    }
  };

  const onSaveEvt = () => {
    if (classList.length === 0) {
      simpleAlert(storesDispatch, "알림", "추가할 수업이 없습니다.");
    } else if (classList.some((el) => el.session_price <= 0)) {
      simpleAlert(storesDispatch, "알림", "회당 금액을 입력해주세요.");
    } else {
      simpleAlert(storesDispatch, "알림", "저장하시겠습니까?", false, undefined, attachGxSalary);
    }
  };

  const attachGxSalary = async () => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const formData = new FormData();
      formData.append("branch_id", modalParam.branchId);
      formData.append("staff_info", JSON.stringify(modalParam.staffInfo));
      formData.append("selected_class", JSON.stringify(classList));
      const res = await HttpInstance.patch(
        process.env.REACT_APP_STAFF_SERVER + "/staff/work_info/gx/attach",
        formData,
        config,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        //모달 닫히면서 classList를 보내줘야함
        onModalDone(classList);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    }
  };

  const gxClassListData = new gxClassListTable(deleteRow);
  return (
    <div style={{ width: "52rem" }}>
      {isActiveGx ? (
        <>
          <div style={{ display: "flex" }}>
            <IconButton type="border" />
            <div style={{ width: "0.5rem" }}></div>
            <SubTitle titleText="수업 선택" />
          </div>
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(7,1fr)",
                textAlign: "center",
                marginBottom: "0.7rem",
                fontWeight: "bold",
                color: "#222222",
              }}
            >
              <div style={{ color: "red" }}>일 </div>
              <div>월 </div>
              <div>화 </div>
              <div>수</div>
              <div>목 </div>
              <div>금 </div>
              <div>토 </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(7,1fr)",
                gridAutoRows: "minmax(2.5rem,auto)",
                maxHeight: "30rem",
                gridAutoColumns: "1fr",
                boxSizing: "border-box",
                overflowY: "auto",
              }}
            >
              {filteredArr.length !== 0 &&
                filteredArr.map((time, timeIdx) => (
                  <>
                    {time.map((day, dayIdx) => (
                      <div style={normalStyle} key={dayIdx}>
                        {day.schedule_list &&
                          day.schedule_list.map((el) => (
                            <div
                              style={selectStyle(
                                el.view_info.color_code,
                                el.schedule_base.charger_staff_info.staff_name,
                              )}
                              onClick={() => {
                                onClickClassBox(el.gx_info.gx_id);
                              }}
                            >
                              {el.card_title.split("\n")[0]}
                              <br />
                              {el.card_title.split("\n")[1]}
                            </div>
                          ))}
                      </div>
                    ))}
                  </>
                ))}
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "0.7rem" }}>
            <IconButton type="border" />
            <div style={{ width: "0.5rem" }}></div>
            <SubTitle titleText="수업 목록" />
          </div>
          <CustomTable
            columns_head={gxClassListData.columns_head}
            table_title={gxClassListData.table_title}
            rest_call={gxClassListData.get_data_from_rest}
            row_render={gxClassListData.create_table}
            rest_data={classList}
          />
          <div style={{ display: "flex", justifyContent: "center", marginTop: "1.2rem" }}>
            <Button
              className={clsx({
                [btnStyleClass.btnRootRaw]: true,
                [btnStyleClass.buttonType4]: true,
              })}
              onClick={onSaveEvt}
            >
              저장
            </Button>
          </div>
        </>
      ) : (
        <Label labelText="지점에 등록된 GX수업이 없습니다." />
      )}
    </div>
  );
};

export default AddGxSalaryModal;

class gxClassListTable {
  table_title = "";
  deleteRow = null;

  constructor(deleteRow) {
    this.deleteRow = deleteRow;
  }

  convertDayText = (array) => {
    let temp = [];
    array.forEach((el) => {
      if (el === 0) {
        temp.push("일");
      } else if (el === 1) {
        temp.push("월");
      } else if (el === 2) {
        temp.push("화");
      } else if (el === 3) {
        temp.push("수");
      } else if (el === 4) {
        temp.push("목");
      } else if (el === 5) {
        temp.push("금");
      } else if (el === 6) {
        temp.push("토");
      }
    });
    const text = temp.join(", ");
    return text;
  };

  create_table = (data, key) => {
    return (
      <CustomRow key={key}>
        <CustomTableContents>{data.gx_info.gx_code}</CustomTableContents>
        <CustomTableContents>{data.class_name}</CustomTableContents>
        <CustomTableContents>
          {this.convertDayText(data.schedule_week_idx_list)}
        </CustomTableContents>
        <CustomTableContents>
          {data.class_from_time} ~ {data.class_to_time}
        </CustomTableContents>
        <CustomTableContents>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <NumberTextField
              labelWidth="0rem"
              labelMarginRight="0rem"
              textBoxWidth="7rem"
              endAdornment="원"
              defaultValue={data.session_price}
              onChangeCallback={(e) => {
                data.session_price = e;
              }}
            />
          </div>
        </CustomTableContents>
        <CustomTableContentsEND align="center">
          <IconButton
            type="delete"
            onClick={() => {
              this.deleteRow(data);
            }}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "수업 코드", width: "18%" },
    { title: "수업명", width: "18%" },
    { title: "수업요일", width: "18%" },
    { title: "수업시간", width: "18%" },
    { title: "회당 금액", width: "20%" },
    { title: "", width: "8%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }

    return stateData;
  };
}
