import ComboBox from "components/LabelInput/ComboBox";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import RadioGroup from "components/LabelInput/RadioGroup";
import { useEffect } from "react";

const SearchDetailSideContents = ({ searchParam, setDetailOption }) => {
  const textBoxWidth = "20rem";
  const textBoxWidthShort = "9.5rem";
  const LabelTop = ({ text }) => {
    return <Label labelText={text} color="#777" justifyContent="flex-start" marginTop="0.5rem" />;
  };

  useEffect(() => {
    searchParam.other_type = "운동복";
  }, [searchParam]);

  if (searchParam.pending) {
    return <div style={{ height: "50rem" }}></div>;
  } else {
    return (
      <>
        {/* <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <Label labelText="부가상품" marginLeft="0.5rem" fontWeight="bold" />
      </div> */}
        <div>
          <LabelTop text={"부가상품 종류"} />
          <ComboBox
            labelMarginRight="0rem"
            comboItemWidth={textBoxWidth}
            data={["운동복", "GX", "락커"]}
            defaultValue={searchParam.other_type || "운동복"}
            onChangeCallback={(e) => {
              if (e.target.value === "락커") {
                setDetailOption("locker");
              } else {
                setDetailOption("side");
              }
              searchParam.other_type = e.target.value;
            }}
          />

          <LabelTop text={"부가상품 사용 여부"} />
          <RadioGroup
            radioMarginRight="3.5rem"
            data={["사용", "사용 안함"]}
            isMulti={true}
            defaultValue={searchParam.is_use || "사용"}
            onChangeCallback={(e, v) => {
              searchParam.is_use = e.target.value;
            }}
          />

          <LabelTop text={"기간 내 사용 기록"} />
          <div style={{ display: "flex", marginTop: "0.25rem" }}>
            <DatePicker
              // id="mbshp_date_from"
              textBoxWidth={textBoxWidthShort}
              labelMarginRight="0rem"
              textMarginRight="0.25rem"
              defaultValue={searchParam.use_date_from}
              onChangeCallback={(e) => {
                searchParam.use_date_from = e;
              }}
            />
            <DatePicker
              // id="mbshp_date_to"
              labelWidth="0.5rem"
              textBoxWidth={textBoxWidthShort}
              labelMarginRight="0.25rem"
              labelText="~"
              defaultValue={searchParam.use_date_to}
              onChangeCallback={(e) => {
                searchParam.use_date_to = e;
              }}
            />
          </div>
        </div>
      </>
    );
  }
};
export default SearchDetailSideContents;
