import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const LoadConditionModal = ({ modalParam, onModalDone }) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  const [conditionList, setConditionList] = useState([]);
  const [curPage, setCurPage] = useState();
  const [totalPage, setTotalPage] = useState(1);

  const getConditionList = (page) => {
    setCurPage(page);

    HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/search/preset/list", {
      params: {
        page_num: page,
        branch_id: modalParam.branch_id,
      },
    })
      .then((res) => {
        if (!restEmpty(res.data.data)) {
          console.log(res);
          setTotalPage(res.data.total_page);
          setConditionList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  useEffect(() => {
    // load data (GET)
    // setConditionList([
    //   { condition_name: "2023년 01월 만료예정자 조회" },
    //   { condition_name: "이번달 생일 회원~!~!" },
    // ]); // temp data

    getConditionList(1);
  }, []);

  const onClickRow = (condition) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="해당 검색조건을 불러오시겠습니까?" />,
        onBtnYes: () => {
          onModalDone("load", condition.preset_id);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onClickDelete = (data) => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: (
          <>
            <Label labelText="다음 검색조건을 삭제하시겠습니까?" />
            <br />
            <Label labelText={data.preset_name} fontWeight="bold" marginTop="0.5rem" />
          </>
        ),
        onBtnYes: () => {
          onDelete(data.preset_id);
        },
        onBtnNo: () => {},
      }),
    );
  };

  const onDelete = (id) => {
    HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/search/preset/delete", {
      params: {
        preset_id: id,
      },
    })
      .then((res) => {
        console.log(res);
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText="삭제되었습니다." />,
            onBtnOk: () => {},
          }),
        );
        getConditionList();
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const condListTable = new resultTable(onClickRow, onClickDelete);

  return (
    <div style={{ width: "40rem" }}>
      <CustomTable
        columns_head={condListTable.columns_head}
        table_title={condListTable.table_title}
        rest_call={condListTable.get_data_from_rest}
        row_render={condListTable.create_table}
        rest_data={conditionList}
        // width="160rem"
      />
      <CustomPagination
        curPage={curPage}
        totalCnt={totalPage}
        doSearch={(e) => {
          getConditionList(e);
        }}
      />
    </div>
  );
};
export default LoadConditionModal;

//
class resultTable {
  table_title = "";
  onClickDelete = null;
  onClickRow = null;

  constructor(onClickRow, onClickDelete) {
    this.onClickRow = onClickRow;
    this.onClickDelete = onClickDelete;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow
        onClick={(e) => {
          if (e.target.nodeName === "DIV" || e.target.nodeName === "TD") {
            this.onClickRow(data);
          }
        }}
        key={idx}
      >
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.preset_name}</CustomTableContents>
        <CustomTableContentsEND>
          <IconButton type="delete" onClick={() => this.onClickDelete(data)} tooltip="삭제" />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "등록일", width: "20%" },
    { title: "조건명", width: "70%" },
    { title: "", width: "10%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
