import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import {
  contentTypeJson,
  dataEmpty,
  generateObjectZeroId,
  restEmpty,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import CheckBox from "components/LabelInput/CheckBox";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { studentDetailInfoInit } from "../PtSalaryInfo/dumm";

const SessionEditModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const box_width = "9rem";
  const label_width = "5rem";

  const [studentDetail, setStudentDetail] = useState(studentDetailInfoInit);
  const [totalPay, setTotalPay] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getStudentDetail();
  }, []);

  const getStudentDetail = async () => {
    try {
      setLoading(true);
      const res = await HttpInstance.get(
        process.env.REACT_APP_SALARY_SERVER + "/salary/my_class/detail",
        {
          params: {
            staff_salary_id: modalParam.staffSalaryId,
            staff_id: modalParam.staffId,
            pt_detail_id: modalParam.ptDetailId,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setStudentDetail(res.data.data);
        onCalcTotalPay(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  const onClickSaveBtn = () => {
    storesDispatch(
      globalAlertOn({
        show: true,
        titleText: "알림",
        bodyText: <Label labelText="저장하시겠습니까?" />,
        onBtnYes: () => {
          changeSessionPrice();
        },
        onBtnNo: () => {},
      }),
    );
  };

  //세션가 변경 POST
  const changeSessionPrice = async () => {
    try {
      const formData = new FormData();
      formData.append("new_info", JSON.stringify(studentDetail));
      setLoading(true);

      let getQuery = "?salary_report_id=" + modalParam.salaryReportId;
      getQuery += "&staff_salary_id=" + modalParam.staffSalaryId;

      const res = await HttpInstance.post(
        process.env.REACT_APP_SALARY_SERVER + "/salary/class/detail/session/update" + getQuery,
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공 -> 메인탭 갱신, pt탭 갱신, pt상세탭 갱신, 모달 닫기
        simpleAlert(storesDispatch, "알림", "저장되었습니다.");
        onModalDone(modalParam.pId);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const onCalcTotalPay = (param) => {
    let curPtDetail = studentDetail;
    if (curPtDetail.pt_detail_id === "") {
      curPtDetail = param;
    }

    if (curPtDetail === undefined) {
      return;
    }

    let curtotalPay = 0;
    for (let n = 0; n < curPtDetail.pt_class.length; n++) {
      if (curPtDetail.pt_class[n].staff_info.staff_id === modalParam.staffId) {
        if (curPtDetail.pt_class[n].view_info.is_cur_record) {
          curtotalPay += curPtDetail.pt_class[n].view_info.payout_price;
        }
      }
    }
    setTotalPay(curtotalPay);
  };

  const sessionListTableItem = new sessionListTable(
    modalParam.base_date,
    studentDetail,
    setStudentDetail,
    onCalcTotalPay,
    modalParam.is_edit,
  );

  return (
    <div>
      {loading && <Loading />}

      <div>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="회원 정보" />
        </div>
        <div style={{ display: "flex" }}>
          <TextField
            labelMarginLeft="1rem"
            labelWidth={label_width}
            textBoxWidth={box_width}
            labelText="회원명"
            defaultValue={studentDetail.membership_info.member_name}
            disabled
          />
          <TextField
            labelMarginLeft="2rem"
            labelWidth={label_width}
            textBoxWidth={box_width}
            labelText="회원번호"
            defaultValue={studentDetail.membership_info.member_no}
            disabled
          />
          <TextField
            labelMarginLeft="2rem"
            labelWidth={label_width}
            textBoxWidth={box_width}
            labelText="상담자"
            defaultValue={studentDetail.cs_staff.staff_name}
            disabled
          />
        </div>
      </div>

      <div>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="수업 정보" />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <TextField
          labelMarginLeft="1rem"
          labelWidth={label_width}
          textBoxWidth={box_width}
          labelText="기본 세션"
          endAdornment={"회"}
          isNumber
          defaultValue={studentDetail.pt_info.base_session_cnt.toLocaleString()}
          disabled
        />
        <TextField
          labelMarginLeft="2rem"
          labelWidth={label_width}
          textBoxWidth={box_width}
          labelText="서비스 세션"
          endAdornment={"회"}
          isNumber
          defaultValue={studentDetail.pt_info.service_session_cnt.toLocaleString()}
          disabled
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.7rem" }}>
        <TextField
          labelMarginLeft="1rem"
          labelWidth={label_width}
          textBoxWidth={box_width}
          labelText="계약 세션가"
          endAdornment={"원"}
          isNumber
          defaultValue={studentDetail.pt_price.per_price.toLocaleString()}
          disabled
        />
        <TextField
          labelMarginLeft="2rem"
          labelWidth={label_width}
          textBoxWidth={box_width}
          labelText="수업 담당자"
          defaultValue={studentDetail.cur_manager.staff_info.staff_name}
          disabled
        />
        <TextField
          labelMarginLeft="2rem"
          labelWidth={label_width}
          textBoxWidth={box_width}
          labelText="총 상품금액"
          endAdornment={"원"}
          isNumber
          defaultValue={studentDetail.pt_price.total_price.toLocaleString()}
          disabled
        />
      </div>

      <div>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="내용" />
        </div>

        <CustomTable
          columns_head={sessionListTableItem.columns_head}
          table_title={sessionListTableItem.table_title}
          rest_call={sessionListTableItem.get_data_from_rest}
          row_render={sessionListTableItem.create_table}
          rest_data={studentDetail.pt_class}
          maxHeight={"26rem"}
        />

        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.7rem" }}>
          <Label
            labelText="※ 지급 금액 합계는 총 상품 금액보다 클 수 없습니다."
            color="#DE0909"
            fontSize="0.7rem"
          />
          <TextField
            labelMarginLeft="0.7rem"
            labelWidth={"7rem"}
            textBoxWidth={"9rem"}
            labelText="지급 금액 합계"
            endAdornment={"원"}
            defaultValue={totalPay.toLocaleString()}
            disabled
          />
        </div>
      </div>

      {modalParam.is_edit ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className={clsx({
              [btnStyleClass.btnRoot]: true,
              [btnStyleClass.buttonType4]: true,
            })}
            onClick={onClickSaveBtn}
          >
            저장
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default SessionEditModal;

class sessionListTable {
  table_title = "";
  studentDetail = null;
  setStudentDetail = null;
  onCalcTotalPay = null;
  targetMonth = "";
  isEdit = false;

  constructor(targetMonth, studentDetail, setStudentDetail, onCalcTotalPay, isEdit) {
    this.studentDetail = studentDetail;
    this.setStudentDetail = setStudentDetail;
    this.onCalcTotalPay = onCalcTotalPay;
    this.targetMonth = targetMonth;
    this.isEdit = isEdit; // view mode
  }

  // 표시해야 할 row인지 체크
  isValidClass = (data) => {
    if (data.staff_info.staff_id !== generateObjectZeroId()) {
      return true;
    } else {
      return false;
    }
  };

  // 수업일과 현재 급여 month가 같은 달인지 체크
  getIsSameMonth = (data) => {
    if (
      data.proceed_class.class_done_date &&
      this.targetMonth === data.proceed_class.class_done_date.substring(0, 7)
    ) {
      return true;
    }
    return false;
  };

  canEditCheckbox = (data) => {
    const isSameMonth = this.getIsSameMonth(data);

    if (
      data.view_info.is_fix_payout_rate_lock ||
      !this.isEdit ||
      !isSameMonth ||
      data.session_type.includes("서비스")
    ) {
      return true;
    }
  };

  canEditPrice = (data) => {
    const isSameMonth = this.getIsSameMonth(data);

    if (!data.view_info.fix_payout_rate || !this.isEdit || !isSameMonth) {
      return true;
    }
  };

  getRemarks = (classStatus) => {
    if (classStatus === "수업완료") {
      return "";
    } else {
      return classStatus;
    }
  };

  create_table = (data, idx) => {
    console.log("data", data);
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.session_type}</CustomTableContents>
        <CustomTableContents>{data.no}</CustomTableContents>

        {this.isValidClass(data) ? (
          <>
            <CustomTableContents>{data.proceed_class.class_done_date}</CustomTableContents>
            <CustomTableContents>{data.staff_info.staff_name}</CustomTableContents>
            <CustomTableContents $isNumber>
              {data.session_price.toLocaleString() + " 원"}
            </CustomTableContents>
            <CustomTableContents>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CheckBox
                  marginTop="0rem"
                  marginLeft="0rem"
                  marginRight="0rem"
                  defaultValue={data.view_info.fix_payout_rate}
                  onChangeCallback={(e) => {
                    this.setStudentDetail((cur) => {
                      const obj = { ...cur };
                      obj.pt_class[idx].view_info.fix_payout_rate = e.checked;
                      return obj;
                    });
                  }}
                  disabled={this.canEditCheckbox(data)}
                />
              </div>
            </CustomTableContents>
            <CustomTableContents>
              <NumberTextField
                endAdornment={"원"}
                textBoxWidth={"7rem"}
                defaultValue={data.view_info.payout_price}
                maxValue={data.session_price}
                onChangeCallback={(e) => {
                  this.setStudentDetail((cur) => {
                    const obj = { ...cur };
                    obj.pt_class[idx].view_info.payout_price = e;
                    return obj;
                  });
                  this.onCalcTotalPay();
                }}
                onBlur={(e) => {
                  // onChangeCallback 에서 간혹 업데이트가 안되는 경우가 있어서 onBlur 추가
                  this.setStudentDetail((cur) => {
                    const obj = { ...cur };
                    obj.pt_class[idx].view_info.payout_price = e;
                    return obj;
                  });
                  this.onCalcTotalPay();
                }}
                disabled={this.canEditPrice(data)}
              />
            </CustomTableContents>
            <CustomTableContents>
              {this.getRemarks(data.proceed_class.class_status)}
            </CustomTableContents>
          </>
        ) : (
          <>
            <CustomTableContents>{data.proceed_class.class_done_date}</CustomTableContents>
            <CustomTableContents>{data.staff_info.staff_name}</CustomTableContents>
            <CustomTableContents></CustomTableContents>
            <CustomTableContents></CustomTableContents>
            <CustomTableContents></CustomTableContents>
            <CustomTableContents></CustomTableContents>
          </>
        )}
      </CustomRow>
    );
  };
  columns_head = [
    { title: "세션구분", width: "12%" },
    { title: "수업차수", width: "9%" },
    { title: "수업일", width: "13%" },
    { title: "수업담당자", width: "12%" },
    { title: "계약 세션가", width: "14%" },
    { title: "지급금액\n고정", width: "10%" },
    { title: "지급 금액", width: "12%" },
    { title: "비고", width: "12%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
