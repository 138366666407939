import IconButton from "components/Button/IconButton";
import CardFixed from "components/Card/CardFixed";
import { getPermission } from "components/CommonLib/CommonLib";
import Label from "components/LabelInput/Label";
import NormalBtn from "components/NewButton/NormalBtn";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SearchResultTableLocker from "./SearchResult/SearchResultTableLocker";
import SearchResultTableMbshp from "./SearchResult/SearchResultTableMbshp";
import SearchResultTableMember from "./SearchResult/SearchResultTableMember";
import SearchResultTablePt from "./SearchResult/SearchResultTablePt";
import SearchResultTableSide from "./SearchResult/SearchResultTableSide";
import SearchResultTableTotal from "./SearchResult/SearchResultTableTotal";

const SearchResultCard = ({
  searchResult,
  detailOption,
  navigate,
  onClickExcelDown,
  curPage,
  setCurPage,
  totalPage,
  onClickSearch,
}) => {
  const [canExcel, setCanExcel] = useState(false);
  const storesDispatch = useDispatch();

  const checkPermission = async () => {
    const result = await getPermission(storesDispatch, "ACCOUNT_PERMISSION/EXCEL");
    setCanExcel(result);
  };

  const getTableText = () => {
    if (detailOption === "member") return "기준 : 회원";
    else if (detailOption === "mbshp") return "기준 : 회원권";
    else if (detailOption === "pt") return "기준 : PT";
    else if (detailOption === "side" || detailOption === "locker") return "기준 : 부가상품";
    else return "";
  };

  useEffect(() => {
    checkPermission();
  }, []);

  return (
    <CardFixed
      topDom={<div>검색 결과</div>}
      midDom={
        <div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                width: "100%",

                justifyContent: "space-between",
                marginBottom: "0.3rem",
              }}
            >
              <div style={{ display: "flex", marginTop: "0.5rem", marginBottom: "0.25rem" }}>
                <IconButton type="border" />
                <Label labelText="검색 결과" marginLeft="0.5rem" fontWeight="bold" />
                <Label labelText={getTableText()} marginLeft="2rem" />
              </div>
              {searchResult.length !== 0 && canExcel && (
                <NormalBtn
                  name="엑셀 다운로드"
                  theme="green"
                  onClick={() => {
                    onClickExcelDown();
                  }}
                />
              )}
            </div>

            {/* <div
              style={{
                display: "flex",
                float: "right",
                marginLeft: "auto",
                marginBottom: "0.625rem",
              }}
            >
              <NormalBtn name='목록 저장' theme='white' onClick={() => {}}/>
            </div> */}
          </div>

          {detailOption === "member" ? (
            <SearchResultTableMember
              searchResult={searchResult}
              navigate={navigate}
              curPage={curPage}
              setCurPage={setCurPage}
              totalPage={totalPage}
              onClickSearch={onClickSearch}
            />
          ) : detailOption === "mbshp" ? (
            <SearchResultTableMbshp
              searchResult={searchResult}
              navigate={navigate}
              curPage={curPage}
              setCurPage={setCurPage}
              totalPage={totalPage}
              onClickSearch={onClickSearch}
            />
          ) : detailOption === "pt" ? (
            <SearchResultTablePt
              searchResult={searchResult}
              navigate={navigate}
              curPage={curPage}
              setCurPage={setCurPage}
              totalPage={totalPage}
              onClickSearch={onClickSearch}
            />
          ) : detailOption === "side" ? (
            <SearchResultTableSide
              searchResult={searchResult}
              navigate={navigate}
              curPage={curPage}
              setCurPage={setCurPage}
              totalPage={totalPage}
              onClickSearch={onClickSearch}
            />
          ) : detailOption === "locker" ? (
            <SearchResultTableLocker
              searchResult={searchResult}
              navigate={navigate}
              curPage={curPage}
              setCurPage={setCurPage}
              totalPage={totalPage}
              onClickSearch={onClickSearch}
            />
          ) : (
            <SearchResultTableTotal
              searchResult={searchResult}
              navigate={navigate}
              curPage={curPage}
              setCurPage={setCurPage}
              totalPage={totalPage}
              onClickSearch={onClickSearch}
            />
          )}
        </div>
      }
      isLoading={false}
      rootStyle={{
        width: "81.25rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default SearchResultCard;
