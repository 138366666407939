import IconButton from "components/Button/IconButton";
import { dataEmpty, getPermission } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableCellChild,
  CustomTableCellFit,
  CustomTableContents,
} from "components/CustomTable/tableStyle";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import NormalBtn from "components/NewButton/NormalBtn";
import SubTitle from "components/PageTitle/SubTitle";
import { globalModalOff } from "modules/actions/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loadSalaryInfoViewStore, loadSalaryStatusViewStore } from "../Module/actions";
import { onClickExcel } from "../Module/api/restGet";

const SalaryEmployee = ({
  salaryOverallData,
  onClickOpenModal,
  finalTotalSalary,
  staffListInfo,
  salaryReportId,
  is_edit,
  branchName,
  baseDate,
}) => {
  const storesDispatch = useDispatch();
  const [canExcel, setCanExcel] = useState(false);
  const [loading, setLoading] = useState(false);

  const refreshTable = () => {
    storesDispatch(loadSalaryStatusViewStore(salaryReportId, is_edit));
    storesDispatch(globalModalOff());
  };

  const refreshAllView = () => {
    storesDispatch(loadSalaryInfoViewStore(salaryReportId, is_edit));
    refreshTable();
  };

  const popEditSalaryDateModal = () => {
    const param = {
      title: "급여일 수정",
      modalParam: {
        modalInfo: staffListInfo,
        salaryReportId,
      },
    };
    onClickOpenModal("급여일수정", param, refreshAllView);
  };

  const popDeductSalaryModal = () => {
    const param = {
      title: "급여 차감",
      modalParam: {
        modalInfo: staffListInfo,
        salaryReportId,
        salaryOverallData,
      },
    };
    onClickOpenModal("급여차감", param, refreshAllView);
  };

  const checkPermission = async () => {
    const result = await getPermission(storesDispatch, "ACCOUNT_PERMISSION/EXCEL");
    setCanExcel(result);
  };

  useEffect(() => {
    checkPermission();
  }, []);

  const doExcelDownload = () => {
    // summaryDatabranch_info
    let fileName = branchName + "_" + baseDate + "_";
    onClickExcel(storesDispatch, setLoading, salaryReportId, fileName);
  };

  const staffSalaryTableItem = new staffSalaryTable();
  return (
    <div>
      {loading ? <Loading /> : <></>}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div style={{ display: "flex" }}>
            <IconButton type="border" />
            <div style={{ width: "0.5rem" }}></div>
            <SubTitle titleText="급여 현황" />
          </div>
        </div>

        <div style={{ display: "flex", gap: "0.5rem" }}>
          {is_edit && (
            <>
              <NormalBtn
                name="급여일 수정"
                theme="white"
                onClick={() => {
                  popEditSalaryDateModal();
                }}
              />
              <NormalBtn
                name="급여 차감"
                theme="white"
                onClick={() => {
                  popDeductSalaryModal();
                }}
              />
            </>
          )}
          {canExcel && (
            <NormalBtn
              name="엑셀 다운로드"
              theme="green"
              onClick={() => {
                doExcelDownload();
              }}
            />
          )}
        </div>
      </div>
      <div style={{ marginTop: "0.7rem" }}>
        <div>
          <CustomTable
            columns_head={staffSalaryTableItem.columns_head}
            table_title={staffSalaryTableItem.table_title}
            rest_call={staffSalaryTableItem.get_data_from_rest}
            row_render={staffSalaryTableItem.create_table}
            rest_data={salaryOverallData}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem" }}>
          <TextField
            labelWidth={"7rem"}
            textBoxWidth={"9rem"}
            labelText="최종 지급액 합계"
            endAdornment={"원"}
            defaultValue={finalTotalSalary.toLocaleString()}
            isNumber
            disabled
          />
        </div>
      </div>
    </div>
  );
};
export default SalaryEmployee;

class staffSalaryTable {
  table_title = "";
  totalSalaryArr = null;
  totalDeducArr = null;
  totalArr = null;

  constructor(totalSalaryArr, totalDeducArr, totalArr) {
    this.totalSalaryArr = totalSalaryArr;
    this.totalDeducArr = totalDeducArr;
    this.totalArr = totalArr;
  }

  getChildSalaryType = (data) => {
    return (
      <CustomTableCellFit align="center" max_cnt={1}>
        {data.salary_data_list.map((el, idx) => (
          <CustomTableCellChild key={idx} isLast={false}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 1rem",
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              <div style={{ width: "50%", textAlign: "left", borderRight: "1px solid #e5e5e5" }}>
                {el.salary_type}
              </div>
              <div style={{ width: "50%", textAlign: "right" }}>
                {el.salary_info_data.salary_date} 일
              </div>
            </div>
          </CustomTableCellChild>
        ))}
        {data.salary_data_list.length === 0 ? (
          ""
        ) : (
          <CustomTableCellChild $bgColor="#DFEBF3" isBold isLast={true}>
            합계
          </CustomTableCellChild>
        )}
      </CustomTableCellFit>
    );
  };

  getChildMoney = (data, idx) => {
    return (
      <CustomTableCellFit align="center" max_cnt={1}>
        {data.salary_data_list.map((el, idx) => (
          <CustomTableCellChild key={idx} isLast={false} textAlign="right" padding="0 1rem 0 0">
            {el.salary_info_data.salary.toLocaleString()} 원
          </CustomTableCellChild>
        ))}
        {data.salary_data_list.length === 0 ? (
          ""
        ) : (
          <CustomTableCellChild
            $bgColor="#F4F6F9"
            textAlign="right"
            padding="0 1rem 0 0"
            isLast={true}
          >
            {data.salary_info_sum.salary.toLocaleString()} 원
          </CustomTableCellChild>
        )}
      </CustomTableCellFit>
    );
  };

  getChildMinus = (data, idx) => {
    return (
      <CustomTableCellFit align="center" max_cnt={1}>
        {data.salary_data_list.map((el, idx) => (
          <CustomTableCellChild key={idx} isLast={false} textAlign="right" padding="0 1rem 0 0">
            {el.salary_info_data.salary_deduct.toLocaleString()} 원
          </CustomTableCellChild>
        ))}
        {data.salary_data_list.length === 0 ? (
          ""
        ) : (
          <CustomTableCellChild
            $bgColor="#F4F6F9"
            color="#DE0909"
            textAlign="right"
            padding="0 1rem 0 0"
            isLast={true}
          >
            {data.salary_info_sum.salary_deduct.toLocaleString()} 원
          </CustomTableCellChild>
        )}
      </CustomTableCellFit>
    );
  };

  getChildReason = (data) => {
    return (
      <CustomTableCellFit align="center" max_cnt={1}>
        {data.salary_data_list.map((el, idx) => (
          <CustomTableCellChild key={idx} isLast={false} textAlign="left" padding="0 0 0 1rem">
            {el.salary_info_data.deduct_reason ? el.salary_info_data.deduct_reason : " "}
          </CustomTableCellChild>
        ))}
        {data.salary_data_list.length === 0 ? (
          ""
        ) : (
          <CustomTableCellChild $bgColor="#F4F6F9" color="#F4F6F9" isLast={true}>
            .
          </CustomTableCellChild>
        )}
      </CustomTableCellFit>
    );
  };

  getChildTotal = (data, idx) => {
    return (
      <CustomTableCellFit align="center" max_cnt={1}>
        {data.salary_data_list.map((el, idx) => (
          <CustomTableCellChild key={idx} isLast={false} textAlign="right" padding="0 1rem 0 0">
            {el.salary_info_data.final_salary.toLocaleString()} 원
          </CustomTableCellChild>
        ))}
        {data.salary_data_list.length === 0 ? (
          ""
        ) : (
          <CustomTableCellChild
            $bgColor="#F4F6F9"
            textAlign="right"
            padding="0 1rem 0 0"
            isLast={true}
          >
            {data.salary_info_sum.final_salary.toLocaleString()} 원
          </CustomTableCellChild>
        )}
      </CustomTableCellFit>
    );
  };

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{data.personal_info.sabun}</CustomTableContents>
        <CustomTableContents>{data.personal_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.staff_info.staff_pos}</CustomTableContents>
        {data.salary_data_list !== null && this.getChildSalaryType(data)}
        {data.salary_data_list !== null && this.getChildMoney(data, idx)}
        {data.salary_data_list !== null && this.getChildMinus(data, idx)}
        {data.salary_data_list !== null && this.getChildReason(data)}
        {data.salary_data_list !== null && this.getChildTotal(data, idx)}
      </CustomRow>
    );
  };
  columns_head = [
    { title: "사번", width: "9%" },
    { title: "직원명", width: "9%" },
    { title: "직책", width: "9%" },
    { title: "급여 종류 및 급여일", width: "18%" },
    { title: "지급액", width: "9%" },
    { title: "차감 금액", width: "9%" },
    { title: "차감 사유", width: "19%" },
    { title: "최종 지급액", width: "9%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }

    return stateData;
  };
}
