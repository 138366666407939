import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import { generateObjectZeroId, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import TextField from "components/LabelInput/TextField";
import { HttpInstance } from "lib/HttpLib";
import { globalAlertOn } from "modules/actions/Alert";
import { useRegisterStore } from "pages/ERP/MemberRegister/Register/modules/store/registerState";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const OthersHeader = ({ btnOtherPush }) => {
  const btnStyleClass = btnStyles();

  return (
    <div style={{}}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "1.25rem",
          }}
        >
          기타상품
        </div>

        <Button
          className={clsx({
            [btnStyleClass.btnRootRaw]: true,
            [btnStyleClass.buttonType2]: true,
          })}
          onClick={btnOtherPush}
        >
          추가
        </Button>
      </div>
    </div>
  );
};

const PriceDiscountInput = ({ data, selectedItem, onChange, payInfoListAction }) => {
  const labelWidth = "1.75rem";
  const textBoxWidth = "10rem";

  const [discountMode, setDiscountMode] = useState("총액");
  const [disPrice, setDisPrice] = useState(data.content_obj.dis);

  useEffect(() => {
    setDiscountMode("총액");
    setDisPrice(0);
    data.content_obj.dis = 0;
  }, [selectedItem]);

  useEffect(() => {
    data.content_obj.dis = 0;
    setDisPrice(0);
  }, [discountMode]);

  const onChangeDisPrice = () => {
    if (discountMode === "개당") {
      data.content_obj.dis = data.content_obj.dis * data.content_obj.count;
    }
    data.content_obj.dis_type = discountMode;
    payInfoListAction("discount", data.type_key, data.content_obj, data.orderIdx, 0);

    // onChange();
  };

  const getMaxValue = () => {
    if (selectedItem) {
      if (discountMode === "개당") {
        return selectedItem.price;
      } else {
        return selectedItem.price * data.content_obj.count;
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        float: "right",
        marginLeft: "auto",
      }}
    >
      <NumberTextField
        isNumber
        labelWidth={labelWidth}
        textMarginRight="0.25rem"
        textBoxWidth={textBoxWidth}
        labelText="금액"
        disabled
        endAdornment="원"
        defaultValue={selectedItem && selectedItem.price * data.content_obj.count}
      />

      <ComboBox
        marginLeft="1.5rem"
        comboItemWidth={"8.25rem"}
        labelWidth={labelWidth}
        labelText="할인"
        data={["총액", "개당"]}
        defaultValue={discountMode}
        onChangeCallback={(e) => {
          setDiscountMode(e.target.value);
          onChangeDisPrice();
        }}
        // disabled={selectedItem ? false : true}
      />

      <NumberTextField
        isNumber
        labelMarginRight="0rem"
        textMarginRight="0.25rem"
        textBoxWidth={textBoxWidth}
        defaultValue={disPrice}
        maxValue={getMaxValue()}
        name={data.view_id}
        id={data.view_id}
        endAdornment="원"
        onChangeCallback={(e) => {
          setDisPrice(e);
          data.content_obj.dis = e;
          onChangeDisPrice();
        }}
        disabled={selectedItem ? false : true}
      />
    </div>
  );
};

const OthersListItem = ({
  data,
  btnOtherPop,
  itemList,
  setOtherList,
  onChange,
  payInfoListAction,
}) => {
  const btnStyleClass = btnStyles();
  const [selectedItem, setSelectedItem] = useState();
  // 전역 상태 관리
  const { onChangeSubmitDocState } = useRegisterStore();

  return (
    <div>
      <div
        style={{
          display: "flex",
          marginTop: "0.5rem",
        }}
      >
        <ComboBox
          labelWidth={"4.5rem"}
          comboItemWidth={"12rem"}
          labelText="상품명"
          data={itemList.map((x) => x.item_name)}
          defaultValue={data.content_obj.type === "기타상품(미선택)" ? "" : data.content_obj.type}
          onChangeCallback={(e) => {
            const idx = itemList.findIndex((x) => x.item_name === e.target.value);
            setSelectedItem(itemList[idx]);
            data.content_obj.price = itemList[idx].price;
            data.content_obj.type = itemList[idx].item_name;
            data.content_obj.item_id = itemList[idx].item_id;
            data.content_obj.count = 1;
            onChange();
          }}
        />

        <TextField
          marginLeft="2rem"
          labelWidth={"2.5rem"}
          textBoxWidth={"4rem"}
          labelText="수량"
          defaultValue={data.content_obj.count}
          maxLength={3}
          isNumber
          name={data.view_id}
          id={data.view_id}
          onChangeCallback={(e) => {
            data.content_obj.count = e;
            data.content_obj.price = selectedItem.price * e;
            onChange();
          }}
        />
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType2]: true,
          })}
          onClick={() => {
            btnOtherPop(data.view_id);
          }}
        >
          삭제
        </Button>
        <PriceDiscountInput
          data={data}
          selectedItem={selectedItem}
          onChange={onChange}
          payInfoListAction={payInfoListAction}
        />
      </div>
    </div>
  );
};

const OthersEdit = ({
  otherList,
  setOtherList,
  btnOtherPop,
  itemList,
  onChange,
  payInfoListAction,
}) => {
  const classes = btnStyles();

  return (
    <div style={{ marginTop: "1.5rem" }}>
      {otherList.map((item, idx) => (
        <OthersListItem
          key={idx}
          data={item}
          itemList={itemList}
          setOtherList={setOtherList}
          btnOtherPop={btnOtherPop}
          onChange={onChange}
          payInfoListAction={payInfoListAction}
        />
      ))}
    </div>
  );
};

const OthersInfo = ({ payInfoListAction, onAddPayList, branchId, onUpdatePayList }) => {
  const storesDispatch = useDispatch();
  const [otherList, setOtherList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const payOrderIdx = useRef(90);

  useEffect(() => {
    if (branchId) {
      HttpInstance.get(process.env.REACT_APP_USER_SERVER + "/member/register/etc_item/list", {
        params: {
          branch_id: branchId,
        },
      })
        .then((res) => {
          if (!restEmpty(res.data.data)) {
            setOtherList([]);
            setItemList(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
        });
    }
  }, [branchId]);

  const [, updateState] = useState(); // 강제 렌더링용
  const forceUpdate = useCallback(() => updateState({}), []);
  const onChange = () => {
    // 필요없나?
    for (let i = 0; i < otherList.length; i++) {
      payInfoListAction(
        "discount",
        otherList[i].type_key,
        otherList[i].content_obj,
        otherList[i].orderIdx,
        0,
        0,
      );
    }

    forceUpdate();
  };

  const btnOtherPush = (item) => {
    if (otherList.findIndex((x) => x.content_obj.type === "기타상품(미선택)") !== -1) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: <Label labelText="기존 기타상품을 선택 후 추가해주세요." />,
          onBtnOk: () => {},
        }),
      );
      return;
    }

    const uuId = uuidv4();
    const itemBase = {
      view_id: uuId,
      MembershipIdx: 0,
      content_obj: {
        dis: 0,
        dis_type: "총액",
        item_type: "기타상품/없음/없음/없음/없음",
        pay_order: payOrderIdx.current,
        price: 0,
        tab_idx: 0,
        type: "기타상품(미선택)",
        count: 1,
        item_id: generateObjectZeroId(),
      },
      orderIdx: payOrderIdx.current,
      type_key: uuId,
    };
    payOrderIdx.current++;

    payInfoListAction("push", itemBase.type_key, itemBase.content_obj, itemBase.orderIdx, 0);

    if (!otherList) {
      setOtherList([itemBase]);
    } else {
      setOtherList([...otherList, itemBase]);
    }
  };

  const btnOtherPop = (uuid) => {
    var array = [...otherList];
    const index = array.findIndex((item) => item.view_id === uuid);

    if (index !== -1) {
      array.splice(index, 1);
      // if (setOtherList !== null) {
      setOtherList(array);
      payInfoListAction("pop", uuid);
      // }
    }
  };

  return (
    <CardFixed
      topDom={<OthersHeader btnOtherPush={btnOtherPush} />}
      midDom={
        <OthersEdit
          otherList={otherList}
          setOtherList={setOtherList}
          itemList={itemList}
          btnOtherPop={btnOtherPop}
          onChange={onChange}
          payInfoListAction={payInfoListAction}
        />
      }
      isLoading={false}
      // titleHeight={"2.25rem"}
      rootStyle={{
        width: "76.88rem",
        // height: "170px",
        marginTop: "1rem",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    />
  );
};
export default OthersInfo;
