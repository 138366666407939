import { InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import TextFieldComp from "@mui/material/TextField";
import { checkNumber } from "components/CommonLib/CommonLib";
import React, { useEffect, useState } from "react";
import Label from "./Label";

const TextField = (props) => {
  // const defaultValue = props.isNumber ? (props.defaultValue !== undefined ? 0 : props.defaultValue) : props.defaultValue;
  const [value, setValue] = useState(props.defaultValue);

  useEffect(() => {
    if (props.defaultValue !== undefined) {
      if (props.isNumber) {
        setValue(props.defaultValue || "0");
      } else {
        setValue(props.defaultValue);
      }
    }
  }, [props.defaultValue]);

  useEffect(() => {
    if (props.inputMode === "numeric") {
      setValue(props.value);
    }
  }, [props.value]);

  // useEffect(() => {
  //   if (props.isNumber && value === "") {
  //     console.log("asdf");
  //   }
  // }, [value]);

  const cNumberCheck = (e) => {
    if (props.isNumber !== undefined && props.isNumber === true) {
      if ("" === e.target.value) {
        if (props.minValue) {
          setValue(props.minValue);
          return props.minValue;
        } else {
          setValue(0);
          return 0;
        }
      } else if (isNaN(e.target.value)) {
        ///숫자여야 하는데 문자인 경우
        if (props.minValue) {
          setValue(props.minValue);
          e.target.value = props.minValue;
          if (props.onChange === undefined) {
            return props.minValue;
          }
        } else {
          setValue("0");
          e.target.value = "0";
          if (props.onChange === undefined) {
            return 0;
          }
        }
      } else {
        if (props.onChange === undefined) {
          return Number(e.target.value);
        }
      }
    }

    return e.target.value;
  };

  const cOnChange = (e) => {
    if (props.maxValue === 0) {
      setValue(0);
      e.target.value = 0;
    }

    if (props.maxValue && e.target.value > props.maxValue) {
      setValue(props.maxValue);
      e.target.value = props.maxValue;
    } else if (props.minValue && e.target.value < props.minValue) {
      setValue(props.minValue);
      e.target.value = props.minValue;
    } else {
      setValue(e.target.value);
    }

    if (props.onChangeCallback !== undefined) {
      props.onChangeCallback(cNumberCheck(e));
    }
  };

  const hStyle = {
    display: "flex",
    alignItems: "center",
    width: props.fullWidth ? "100%" : null,
    justifyContent: props.justifyContent,
    marginTop: props.marginTop,
    marginRight: props.marginRight,
    marginLeft: props.marginLeft,
  };

  const fontStyle = {
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: props.labelLetterSpacing ? props.labelLetterSpacing : "normal",
    textAlign: "left",
    color: "#000",
  };

  const labelStyle = {
    width: props.labelWidth,
    height: "2.25rem",
    marginRight: props.labelMarginRight === undefined ? "0.625rem" : props.labelMarginRight,
    marginLeft: props.labelMarginLeft,
    alignItems: "center",
    display: "flex",
    ...fontStyle,
  };

  const textBoxStyle = {
    width: props.textBoxWidth,
    height: "2.25rem",
    paddingRight: "3px",
    marginRight: props.textMarginRight === undefined ? "0.625rem" : props.textMarginRight,
    borderRadius: "5px",
    backgroundColor: "#fff",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000",
  };

  const textBoxStyleDisabled = {
    width: props.textBoxWidth,
    height: "2.25rem",
    paddingRight: "3px",
    marginRight: props.textMarginRight === undefined ? "0.625rem" : props.textMarginRight,
    borderRadius: "5px",
    backgroundColor: "#ebebeb",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  };

  const handleStringNumber = (val) => {
    if (checkNumber(val) || val === "") {
      if (props.maxValue && val > props.maxValue) {
        setValue(props.maxValue);
        val = props.maxValue;
      } else if (props.minValue && val < props.minValue) {
        setValue(props.minValue);
        val = props.minValue;
      } else {
        setValue(val);
        if (props.onChangeCallback) {
          props.onChangeCallback(val);
        }
      }
    } else {
      if (props.onChangeCallback) {
        props.onChangeCallback(value);
      }
    }
  };

  const getTextAlign = () => {
    if (props.isNumber) {
      return "right";
    }

    if (props.textAlign === "right") {
      return "right";
    } else if (props.textAlign === "center") {
      return "center";
    } else {
      return "left";
    }
  };

  return (
    <Box style={props.style || hStyle}>
      <div style={labelStyle}>
        <div>{props.labelText}</div>
      </div>
      <TextFieldComp
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#555",
          },
        }}
        fullWidth={props.fullWidth}
        inputProps={{
          style: {
            textAlign: getTextAlign(),
            // textAlign: props.isNumber ? "right" : "left",
            // paddingLeft: "3px",
            // paddingRight: "3px",
          },
          maxLength: props.maxLength,
          // autocomplete: props.autocomplete,
        }} // only for inputBox text style
        InputProps={{
          readOnly: props.readonly,
          endAdornment: (
            <InputAdornment position="end">
              <Label labelText={props.endAdornment} color="#777" fontSize="0.9rem" />
            </InputAdornment>
          ),
          style: props.disabled === true ? textBoxStyleDisabled : textBoxStyle,
        }}
        InputLabelProps={{ style: { fontSize: "0.8rem" } }}
        id={props.id}
        placeholder={props.placeholder}
        disabled={props.disabled}
        type={props.password ? "password" : ""}
        size="small"
        inputRef={props.inputRef}
        onKeyPress={props.onKeyPress}
        value={value || ""}
        name={props.name}
        onChange={(e) => {
          if (props.isStringNumber) {
            handleStringNumber(e.target.value);
          } else {
            cOnChange(e);

            if (props.onChange) {
              if (props.isNumber) {
                if (e.target.value === "") {
                  if (props.minValue) {
                    setValue(props.minValue);
                    e.target.value = props.minValue;
                  } else {
                    setValue(0);
                    e.target.value = "0";
                  }
                }
              }
              cNumberCheck(e);
              props.onChange(e);
            }
          }
        }}
        onKeyDown={props.onKeyDown}
        error={props.required && (value === undefined || value === "")}
        onBlur={(e) => {
          props.onBlur && props.onBlur(e);
        }}
        autoFocus={props.autoFocus}
        helperText={props.helperText}
        label={props.label}
        autoComplete={props.autoComplete ? props.autoComplete : "off"}
      />
    </Box>
  );
};

export default React.memo(TextField);
