import { Button } from "@material-ui/core";
import clsx from "clsx";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, simpleAlert } from "components/CommonLib/CommonLib";
import ComboBox from "components/LabelInput/ComboBox";
import NumberTextField from "components/LabelInput/NumberTextField";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { useState } from "react";
import { useDispatch } from "react-redux";

const DeductSalaryModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const box_width = "11.5rem";
  const btnStyleClass = btnStyles();
  const [staffIdx, setStaffIdx] = useState();
  const [staffInfo, setStaffInfo] = useState(_.cloneDeep(modalParam.modalInfo));
  const [loading, setLoading] = useState(false);
  const [salaryTypes, setSalaryTypes] = useState([]);

  const validationCheck = () => {
    if (staffIdx === undefined) {
      simpleAlert(storesDispatch, "알림", "직원을 선택해주세요.");
      return;
    }

    if (!staffInfo.salary_type) {
      simpleAlert(storesDispatch, "알림", "급여 종류를 선택해주세요.");
      return;
    }

    // 차감 가능한 급여 정보 가져와서 validation
    const selectedStaffSalaries = modalParam.salaryOverallData.find(
      (x) => x.staff_id === staffInfo.staff_id,
    );
    const selectedSalaryInfo = selectedStaffSalaries.salary_data_list.find(
      (x) => x.salary_type === staffInfo.salary_type,
    );
    if (!selectedSalaryInfo) {
      simpleAlert(storesDispatch, "알림", "차감 가능한 급여 종류가 없습니다.");
      return;
    }

    if (staffInfo.salary_deduct === undefined) {
      simpleAlert(storesDispatch, "알림", "차감 금액을 입력해주세요.");
      return;
    }

    const curMaxSalary = selectedSalaryInfo.salary_info_data.salary;
    if (curMaxSalary < staffInfo.salary_deduct) {
      simpleAlert(storesDispatch, "알림", "차감 가능한 금액이 모자랍니다.");
      return;
    }

    if (!staffInfo.reason) {
      simpleAlert(storesDispatch, "알림", "차감 사유를 입력해주세요.");
      return;
    }

    simpleAlert(storesDispatch, "알림", "급여를 차감하시겠습니까?", false, undefined, () => {
      deductSalary();
    });
  };

  const deductSalary = async () => {
    try {
      const formData = new FormData();
      staffInfo.salary_report_id = modalParam.salaryReportId;
      formData.append("new_info", JSON.stringify(staffInfo));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_SALARY_SERVER + "/salary/deduct",
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공 => 메인급여현황 갱신 + 급여 테이블 갱신 + 모달 닫기
        simpleAlert(
          storesDispatch,
          "알림",
          "정상적으로 차감 되었습니다.\n\n새로고침 해주시기 바랍니다.",
          false,
          onModalDone,
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      {loading && <Loading />}

      <div style={{ display: "flex" }}>
        <ComboBox
          labelWidth={"5rem"}
          comboItemWidth={box_width}
          labelText="직원명"
          textMarginRight="0.4rem"
          data={modalParam.modalInfo.staff_name_list}
          onChangeCallback={(e) => {
            const index = modalParam.modalInfo.staff_name_list.findIndex(
              (el) => el === e.target.value,
            );
            const staffId = modalParam.modalInfo.staff_info_list[index].staff_id;
            setStaffIdx(index);
            setStaffInfo((cur) => {
              return { ...cur, staff_id: staffId };
            });

            // 선택된 직원에게 차감 가능한 급여 종류만을 가져와 Type으로 설정
            const selectedStaffSalaries = modalParam.salaryOverallData.find(
              (x) => x.staff_id === staffId,
            );
            const list = selectedStaffSalaries.salary_data_list.map((x) => x.salary_type);
            setSalaryTypes(list);
          }}
        />
      </div>

      <TextField
        labelWidth={"5rem"}
        textBoxWidth={box_width}
        disabled
        labelText="직책"
        defaultValue={
          staffIdx !== undefined ? modalParam.modalInfo.staff_info_list[staffIdx].staff_pos : ""
        }
      />
      <ComboBox
        labelWidth={"5rem"}
        comboItemWidth={box_width}
        labelText="급여 종류"
        data={salaryTypes}
        onChangeCallback={(e) => {
          setStaffInfo((cur) => {
            return { ...cur, salary_type: e.target.value };
          });
        }}
      />
      <NumberTextField
        labelWidth={"5rem"}
        textBoxWidth={box_width}
        labelText="차감 금액"
        endAdornment={"원"}
        onBlur={(e) => {
          //e -> NumberType
          setStaffInfo((cur) => {
            return { ...cur, salary_deduct: e };
          });
        }}
      />

      <TextField
        labelWidth={"5rem"}
        textBoxWidth={box_width}
        labelText="차감 사유"
        onBlur={(e) => {
          setStaffInfo((cur) => {
            return { ...cur, reason: e.target.value };
          });
        }}
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRootRaw]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={validationCheck}
        >
          저장
        </Button>
      </div>
    </div>
  );
};
export default DeductSalaryModal;
