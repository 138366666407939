import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import CardFixed from "components/Card/CardFixed";
import {
  dataEmpty,
  generateObjectZeroId,
  getPermission,
  simpleAlert,
} from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";

const ReceiverList = ({
  openModal,
  searchResult,
  setSearchResult,
  curPage,
  totalPage,
  loadCondition,
  availSendMsg,
  messageState,
  setMessageState,
}) => {
  const btnStyleClass = btnStyles();
  const storesDispatch = useDispatch();

  const [branchList, setBranchList] = useState([
    {
      hq_info: { hq_id: "", hq_name: "" },
      branch_info: { branch_id: "", branch_name: "" },
    },
  ]);
  const [selectedBranch, setSelectedBranch] = useState({
    hq_info: {},
    branch_info: {},
  });

  // branch_id: "", branch_name: "" };

  useEffect(() => {
    getHqBranchCombo();
  }, []);

  const getHqBranchCombo = async () => {
    const result = await getPermission(storesDispatch, "CS_MENU/MESSAGE", true, false, true);
    // console.log(result);

    const bList = [];
    for (let i = 0; i < result.target_list.length; i++) {
      for (let j = 0; j < result.target_list[i].branch_list.length; j++) {
        if (result.target_list[i].branch_list[j].branch_id !== generateObjectZeroId()) {
          bList.push({
            hq_info: result.target_list[i].hq_info,
            branch_info: result.target_list[i].branch_list[j],
          });
        }
      }
    }

    setBranchList(bList);
    if (bList.length > 0) {
      setSelectedBranch(bList[0]);
      setMessageState({ ...messageState, branch_info: bList[0].branch_info });
    }
  };

  const onClickDelete = (memberId) => {
    simpleAlert(storesDispatch, "알림", "삭제하시겠습니까?", false, null, () => {
      setSearchResult(searchResult.filter((x) => x.member_info.member_id !== memberId));
    });
  };

  const resetList = () => {
    setSearchResult([]);
  };

  const searchResultList = new resultTable(onClickDelete);

  return (
    <div>
      <CardFixed
        topDom={<div>수신 대상</div>}
        midDom={
          <div>
            <div style={{ display: "flex", marginTop: "1rem", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <IconButton type="border" />
                <Label labelText="수신자 선택" marginLeft="0.5rem" fontWeight="bold" />
              </div>
              <div>
                <SearchComboBox
                  marginLeft="1.5rem"
                  labelWidth="2.25rem"
                  comboItemWidth="10rem"
                  labelText="지점"
                  data={
                    branchList &&
                    branchList.map((it) => {
                      return it.branch_info.branch_name;
                    })
                  }
                  defaultValue={branchList && branchList[0].branch_info.branch_name}
                  onChangeCallback={(e, v, idx) => {
                    setSelectedBranch(branchList[idx]);
                    setMessageState({
                      ...messageState,
                      branch_info: branchList[idx].branch_info,
                    });
                  }}
                />
              </div>
              <TextField
                labelText="수신 예정자"
                labelWidth="5rem"
                textMarginRight="0rem"
                textBoxWidth="6rem"
                isNumber
                endAdornment={"명"}
                defaultValue={searchResult.length}
                disabled
              />
            </div>

            <div style={{ margin: "0.5rem 1px" }}>
              <CustomTable
                columns_head={searchResultList.columns_head}
                table_title={searchResultList.table_title}
                rest_call={searchResultList.get_data_from_rest}
                row_render={searchResultList.create_table}
                rest_data={searchResult}
                height="33rem"
              />
              {/* <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={loadCondition} /> */}
            </div>

            <div style={{ display: "flex", marginTop: "1.5rem" }}>
              <IconButton type="border" />
              <Label labelText="현재 발송 가능량" marginLeft="0.5rem" fontWeight="bold" />
              <div
                id="desc"
                style={{
                  width: "1.2rem",
                  height: "1.2rem",
                  backgroundColor: "#003CCB",
                  borderRadius: "50%",
                  color: "#fff",
                  marginLeft: "0.5rem",
                  marginRight: "0.2rem",
                  fontSize: "0.8rem",
                }}
              >
                ?
              </div>
              <ReactTooltip
                anchorId="desc"
                place="top"
                variant="dark"
                style={{ borderRadius: "8px" }}
                content={
                  <div style={{ textAlign: "left", fontSize: "0.75rem" }}>
                    <div>
                      <Label
                        labelText={
                          "잔여 포인트로 발송 가능한 각 문자 유형별 전송 가능 수입니다.\n발송 가능량이 수신자 수보다 적은 경우, 알리고 사이트\n(https://smartsms.aligo.in/main.html)에서 충전해 주시기 바랍니다."
                        }
                        color="#BBBBBB"
                        fontSize="0.7rem"
                        whiteSpace="pre-wrap"
                      />
                    </div>
                  </div>
                }
              />
            </div>
            <div style={{ display: "flex", marginTop: "0.5rem", justifyContent: "space-between" }}>
              <TextField
                labelText="SMS"
                labelWidth="2.5rem"
                textMarginRight="0rem"
                textBoxWidth="7rem"
                endAdornment={"건"}
                isNumber
                defaultValue={availSendMsg.SMS_CNT}
                disabled
              />
              <TextField
                labelText="LMS"
                labelWidth="2.5rem"
                textMarginRight="0rem"
                textBoxWidth="7rem"
                endAdornment={"건"}
                isNumber
                defaultValue={availSendMsg.LMS_CNT}
                disabled
              />
              <TextField
                labelText="MMS"
                labelWidth="2.5rem"
                textMarginRight="0rem"
                textBoxWidth="7rem"
                endAdornment={"건"}
                isNumber
                defaultValue={availSendMsg.MMS_CNT}
                disabled
              />
            </div>

            <div style={{ display: "flex", justifyContent: "center", marginTop: "1.5rem" }}>
              <Button
                className={clsx({
                  [btnStyleClass.btnRootWhite]: true,
                  [btnStyleClass.buttonType7]: true,
                })}
                onClick={() => {
                  resetList();
                }}
              >
                목록 초기화
              </Button>
              <Button
                style={{ marginLeft: "0.5rem" }}
                className={clsx({
                  [btnStyleClass.btnRootWhite]: true,
                  [btnStyleClass.buttonType6]: true,
                })}
                onClick={() => {
                  openModal("member", {
                    title: "회원 검색",
                    mode: "multiple",
                    isFixedBranch: true,
                    hqBranchData: selectedBranch,
                  });
                }}
              >
                직접 선택
              </Button>
              <Button
                style={{ marginLeft: "0.5rem" }}
                className={clsx({
                  [btnStyleClass.btnRoot]: true,
                  [btnStyleClass.buttonType8]: true,
                })}
                onClick={() => {
                  openModal("load", {
                    title: "검색조건 불러오기",
                    branch_id: selectedBranch.branch_info.branch_id,
                  });
                }}
              >
                조건 불러오기
              </Button>
            </div>
          </div>
        }
        isLoading={false}
        rootStyle={{
          maxHeight: "51rem",
          borderRadius: "10px",
          padding: "0.75rem 0.5rem",
          backgroundColor: "#fff",
          overflowY: "auto",
        }}
      />
    </div>
  );
};
export default ReceiverList;

class resultTable {
  table_title = "";
  onClickDelete = null;

  constructor(onClickDelete) {
    this.onClickDelete = onClickDelete;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContentsEND>
          <IconButton
            type="delete"
            onClick={() => {
              this.onClickDelete(data.member_info.member_id);
            }}
          />
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "지점", width: "30%" },
    { title: "회원번호", width: "30%" },
    { title: "이름", width: "30%" },
    { title: "", width: "10%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
