import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import DatePicker from "components/LabelInput/DatePicker";
import NumberTextField from "components/LabelInput/NumberTextField";
import TextField from "components/LabelInput/TextField";
import { globalModalOff, globalModalOn } from "modules/actions/Modal";
import { getIsOnlyView } from "pages/ApprovalManage/ApprovalCommon/checker";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TransferReqListModal from "./TransferReqListModal";

const TransferReqForm = ({ mode, baseState, contentState, setContentState, onClickLedger }) => {
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const [refundTransferList, setRefundTransferList] = useState(
    contentState && contentState.item_list,
  );

  const readOnly = mode === "approver" || mode === "unspent" || mode === "ledger";

  useEffect(() => {
    if (mode === "new") {
      setRefundTransferList([]);
    }
  }, [baseState.writer.hq_info.hq_name, baseState.writer.branch_info.branch_name]);

  const onModalDone = (result) => {
    // {selectedIdx resultList}

    const rtl = [...refundTransferList];
    for (let i = 0; i < result.resultList.length; i++) {
      rtl.push(result.resultList[i]);
    }
    setRefundTransferList(rtl);
    contentState.item_list = rtl;

    storesDispatch(globalModalOff());
  };

  const onClickDelete = (data) => {
    contentState.item_list = refundTransferList.filter((x) => x.item_id !== data.item_id);
    setRefundTransferList(contentState.item_list);
  };

  const onClickAddRow = () => {
    if (
      !baseState.writer.branch_info.branch_name ||
      !baseState.writer.hq_info.hq_name ||
      baseState.writer.branch_info.branch_name === "전체"
    ) {
      simpleAlert(storesDispatch, "알림", "지점을 선택해주세요.");
    } else {
      const param = {
        branch_id: baseState.writer.branch_info.branch_id,
        current_list: refundTransferList,
      };

      storesDispatch(
        globalModalOn({
          show: true,
          title: "이체 요청 목록",
          Content: <TransferReqListModal modalParam={param} onModalDone={onModalDone} />,
        }),
      );
    }
  };

  const getBoxSize = () => {
    let boxSize = {
      width: "140rem",
      height: "35rem",
    };
    if (mode === "unspent" || mode === "ledger") {
      return {
        width: "100%",
        height: "100%",
      };
    } else {
      return boxSize;
    }
  };

  const contentList = new contentTable(mode, readOnly, onClickDelete, onClickLedger);

  if (mode === "unspent") {
    return (
      <div style={{ margin: "0.5rem 0.1rem" }}>
        <CustomTable
          columns_head={contentList.columns_head_unspent}
          table_title={contentList.table_title}
          rest_call={contentList.get_data_from_rest}
          row_render={contentList.create_table}
          rest_data={refundTransferList}
          width={getBoxSize().width}
          height={getBoxSize().height}
        />
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ margin: "0.5rem 0.1rem" }}>
          <CustomTable
            columns_head={contentList.columns_head}
            table_title={contentList.table_title}
            rest_call={contentList.get_data_from_rest}
            row_render={contentList.create_table}
            rest_data={refundTransferList}
            width={getBoxSize().width}
            height={getBoxSize().height}
          />
        </div>
        {mode === "ledger" ? (
          <div></div>
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.25rem" }}>
            <Button
              className={clsx({
                [btnStyleClass.btnRootWhite]: true,
                [btnStyleClass.buttonType7]: true,
              })}
              onClick={() => {
                onClickAddRow();
              }}
              disabled={getIsOnlyView(baseState)}
            >
              행 추가
            </Button>
          </div>
        )}
      </div>
    );
  }
};
export default TransferReqForm;

//

class contentTable {
  table_title = "";
  onClickDelete = null;
  readOnly = null;
  mode = null;
  onClickLedger = null;

  constructor(mode, readOnly, onClickDelete, onClickLedger) {
    this.mode = mode;
    this.onClickDelete = onClickDelete;
    this.readOnly = readOnly;
    this.onClickLedger = onClickLedger;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow style={{}} key={idx}>
        <CustomTableContents>{data.account}</CustomTableContents>
        <CustomTableContents>{data.type}</CustomTableContents>
        <CustomTableContents>{data.reg_info.created_at}</CustomTableContents>
        <CustomTableContents>{data.hq_info.hq_name}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_no}</CustomTableContents>
        <CustomTableContents>{data.member_info.member_name}</CustomTableContents>
        <CustomTableContents>{data.payment_info.purchase_code}</CustomTableContents>
        <CustomTableContents>{data.payment_info.pay_method}</CustomTableContents>
        <CustomTableContents>{data.payment_info.buyer}</CustomTableContents>
        <CustomTableContents>{data.payment_info.price.toLocaleString()}</CustomTableContents>
        <CustomTableContents>
          <NumberTextField
            required
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.transfer_req_info.price}
            maxValue={data.payment_info.price}
            onChangeCallback={(e) => {
              data.transfer_req_info.price = e;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <TextField
            required
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.transfer_req_info.bank}
            onChangeCallback={(e) => {
              data.transfer_req_info.bank = e;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <TextField
            required
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.transfer_req_info.bank_account}
            onChangeCallback={(e) => {
              data.transfer_req_info.bank_account = e;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        <CustomTableContents>
          <TextField
            required
            labelMarginRight="0rem"
            textMarginRight="0rem"
            fullWidth
            defaultValue={data.transfer_req_info.account_name}
            onChangeCallback={(e) => {
              data.transfer_req_info.account_name = e;
            }}
            disabled={this.readOnly}
          />
        </CustomTableContents>
        {this.mode === "unspent" ? (
          <>
            <CustomTableContents>
              <DatePicker
                fullWidth
                labelMarginRight="0rem"
                textMarginRight="0rem"
                defaultValue={data.ledger_date}
                onChangeCallback={(e) => {
                  data.ledger_date = e;
                }}
                disabled={data.is_expenditure}
              />
            </CustomTableContents>
            <CustomTableContentsEND>
              {data.is_expenditure ? (
                <></>
              ) : (
                <IconButton
                  type="attendance"
                  onClick={() => this.onClickLedger(data)}
                  tooltip="지출 내역을 확정합니다."
                />
              )}
            </CustomTableContentsEND>
          </>
        ) : (
          <CustomTableContentsEND>
            {!this.readOnly ? (
              <IconButton type="delete" onClick={() => this.onClickDelete(idx)} />
            ) : (
              <></>
            )}
          </CustomTableContentsEND>
        )}
      </CustomRow>
    );
  };

  columns_head = [
    { title: "계정", width: "6%" },
    { title: "구분", width: "6%" },
    { title: "일시", width: "9%" },
    { title: "본부", width: "6%" },
    { title: "지점", width: "6%" },
    { title: "회원번호", width: "6%" },
    { title: "회원명", width: "6%" },
    { title: "구매코드", width: "7%" },
    { title: "결제수단", width: "5%" },
    { title: "매입사", width: "5%" },
    { title: "결제금액", width: "6%" },
    { title: "이체요청금액", width: "6%" },
    { title: "은행", width: "6%" },
    { title: "계좌번호", width: "9%" },
    { title: "입금자명", width: "6%" },
    { title: "", width: "4%" },
  ];

  columns_head_unspent = [
    { title: "계정", width: "6%" },
    { title: "구분", width: "6%" },
    { title: "일시", width: "9%" },
    { title: "본부", width: "6%" },
    { title: "지점", width: "6%" },
    { title: "회원번호", width: "6%" },
    { title: "회원명", width: "6%" },
    { title: "구매코드", width: "7%" },
    { title: "결제수단", width: "5%" },
    { title: "매입사", width: "5%" },
    { title: "결제금액", width: "6%" },
    { title: "이체요청금액", width: "6%" },
    { title: "은행", width: "6%" },
    { title: "계좌번호", width: "9%" },
    { title: "입금자명", width: "6%" },
    { title: "지출일", width: "5%" },
    { title: "", width: "4%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
