import IconButton from "components/Button/IconButton";
import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import {
  CustomRow,
  CustomTableContents,
  CustomTableContentsEND,
} from "components/CustomTable/tableStyle";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { useDispatch } from "react-redux";

const GuestBookResultTable = ({
  openModal,
  searchResult,
  curPage,
  totalPage,
  onClickSearch,
  hqBranchData,
}) => {
  const storesDispatch = useDispatch();

  const onClickShow = (data) => {
    openModal("detail", {
      title: "방명록 상세",
      mode: "show",
      show_data: data,
    });
  };

  const onClickDelete = (data) => {
    simpleAlert(storesDispatch, "알림", "삭제하시겠습니까?", false, undefined, () => {
      onDelete(data);
    });
  };

  const onDelete = (data) => {
    // console.log(data);
    HttpInstance.delete(process.env.REACT_APP_USER_SERVER + "/cs/guest_book/remove", {
      params: {
        hist_id: data.hist_id,
      },
    })
      .then((res) => {
        if (restEmpty(res.data.msg)) {
          simpleAlert(storesDispatch, "알림", "삭제되었습니다.");
          onClickSearch(curPage);
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
      });
  };

  const searchResultList = new resultTable(onClickShow, onClickDelete);

  const doSearch = (page) => {
    onClickSearch(page);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "0.5rem",
        }}
      >
        <NormalBtn
          name="방명록 추가"
          width="6.5rem"
          onClick={() => {
            openModal("detail", { title: "방명록 추가", mode: "add", hqBranchData: hqBranchData });
          }}
        />
      </div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
        // width="160rem"
      />
      <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={doSearch} />
    </div>
  );
};
export default GuestBookResultTable;

class resultTable {
  table_title = "";
  onClickShow = null;
  onClickDelete = null;

  constructor(onClickShow, onClickDelete) {
    this.onClickShow = onClickShow;
    this.onClickDelete = onClickDelete;
  }

  getIsBool = (data) => {
    if (data) {
      return "Y";
    }
    return "N";
  };

  create_table = (data, idx) => {
    return (
      <CustomRow
        style={{}}
        key={idx}
        onClick={(e) => {
          if (e.target.nodeName === "TD") {
            this.onClickShow(data);
          }
        }}
        hover
      >
        <CustomTableContents>{data.member_name}</CustomTableContents>
        <CustomTableContents>{data.phone}</CustomTableContents>
        <CustomTableContents>{data.visit_path}</CustomTableContents>
        <CustomTableContents>{data.gender}</CustomTableContents>
        <CustomTableContents>{data.ages}</CustomTableContents>
        <CustomTableContents>{this.getIsBool(data.is_trans_member)}</CustomTableContents>
        <CustomTableContents>{this.getIsBool(data.agree_marketing)}</CustomTableContents>
        <CustomTableContents>
          {data.cs_date} {data.cs_time}
        </CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{data.cs_staff_info.staff_name}</CustomTableContents>
        <CustomTableContents>{data.inquiry_title}</CustomTableContents>
        <CustomTableContents>{data.inquiry_memo}</CustomTableContents>
        <CustomTableContentsEND>
          <div
            style={{ display: "flex", justifyContent: "center" }}
            onClick={() => this.onClickDelete(data)}
          >
            <IconButton type="delete" marginLeft="0.5rem" marginRight="0.5rem" tooltip="삭제" />
          </div>
        </CustomTableContentsEND>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "이름", width: "7%" },
    { title: "연락처", width: "8%" },
    { title: "방문경로", width: "6%" },
    { title: "성별", width: "5%" },
    { title: "연령대", width: "6%" },
    { title: "회원 전환 여부", width: "7%" },
    { title: "마케팅 수신동의", width: "7%" },
    { title: "방문일시", width: "10%" },
    { title: "지점", width: "8%" },
    { title: "방문상담 담당자", width: "8%" },
    { title: "주요 문의 내용", width: "10%" },
    { title: "방문 상담 내용", width: "28%" },
    { title: "", width: "6%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
