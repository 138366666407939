import { Box, InputAdornment } from "@mui/material";
import TextFieldComp from "@mui/material/TextField";
import { parseCommaNumberToNumber } from "components/CommonLib/CommonLib";
import { forwardRef, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import Label from "./Label";

const NumericFormatCustom = forwardRef(function NumericFormatCustom(props, ref) {
  const [canMaxIncrease, setCanMaxIncrease] = useState(true);
  const [canMinIncrease, setCanMinIncrease] = useState(true);

  return (
    <NumericFormat
      {...props}
      thousandSeparator=","
      onFocus={(e) => {
        e.target.select();
      }}
      isAllowed={(val) => {
        // 최대값 이상 입력 제한
        if (val.floatValue >= props.max) {
          val.formattedValue = props.max.toLocaleString();
          val.value = props.max.toString();
          val.floatValue = props.max;

          // props.setValue(val.floatValue);

          if (canMaxIncrease) {
            setCanMaxIncrease(false);
            return true;
          } else {
            return false;
          }
        } else {
          setCanMaxIncrease(true);
        }
        // 최소값 이하 입력 제한
        if (val.floatValue <= props.min) {
          val.formattedValue = props.min.toLocaleString();
          val.value = props.min.toString();
          val.floatValue = props.min;

          // props.setValue(val.floatValue);

          if (canMinIncrease) {
            setCanMinIncrease(false);
            return true;
          } else {
            return false;
          }
        } else {
          setCanMinIncrease(true);
        }

        return true;
      }}
    />
  );
});

const NumberTextField = (props) => {
  const [value, setValue] = useState(props.defaultValue || "0");

  useEffect(() => {
    if (props.defaultValue !== undefined) {
      setValue(props.defaultValue || "0");
    }
  }, [props.defaultValue]);

  const hStyle = {
    display: "flex",
    alignItems: "center",
    width: props.fullWidth ? "100%" : null,
    justifyContent: props.justifyContent,
    marginTop: props.marginTop,
    marginRight: props.marginRight,
    marginLeft: props.marginLeft,
  };

  const fontStyle = {
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  };

  const labelStyle = {
    width: props.labelWidth,
    height: "2.25rem",
    marginRight: props.labelMarginRight === undefined ? "0.625rem" : props.labelMarginRight,
    marginLeft: props.labelMarginLeft,
    alignItems: "center",
    display: "flex",
    ...fontStyle,
  };

  const textBoxStyle = {
    width: props.textBoxWidth,
    height: "2.25rem",
    paddingRight: "3px",
    marginRight: props.textMarginRight === undefined ? "0.625rem" : props.textMarginRight,
    borderRadius: "5px",
    backgroundColor: "#fff",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000",
  };

  const textBoxStyleDisabled = {
    width: props.textBoxWidth,
    height: "2.25rem",
    paddingRight: "3px",
    marginRight: props.textMarginRight === undefined ? "0.625rem" : props.textMarginRight,
    borderRadius: "5px",
    backgroundColor: "#ebebeb",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.9rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  };

  const getValidValue = (e) => {
    let curValue = parseCommaNumberToNumber(e.target.value);

    // // 마이너스 체크
    // if (props.minValue !== undefined && props.minValue >= 0 && curValue < 0) {
    //   return;
    // }

    if (props.maxValue === 0) {
      setValue(0);
      curValue = 0;
    }

    if (props.maxValue && curValue > props.maxValue) {
      setValue(props.maxValue);
      curValue = props.maxValue;
      e.target.value = props.maxValue;
    } else if (props.minValue !== undefined && curValue < props.minValue) {
      setValue(props.minValue);
      curValue = props.minValue;
      e.target.value = props.minValue;
    } else {
      setValue(curValue);
    }

    if (props.isString) {
      return curValue.toString();
    }
    return curValue;
  };

  const checkIsError = () => {
    if (props.required && (value === undefined || value === "" || value === "0")) {
      return true;
    }
    if (value < props.minValue || value > props.maxValue) {
      return true;
    }
  };

  const getTextAlign = () => {
    if (props.isNumber) {
      return "right";
    }

    if (props.textAlign === "right") {
      return "right";
    } else if (props.textAlign === "center") {
      return "center";
    } else {
      return "left";
    }
  };

  return (
    <Box style={props.style || hStyle}>
      <div style={labelStyle}>
        <div>{props.labelText}</div>
      </div>
      <TextFieldComp
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#555",
          },
        }}
        fullWidth={props.fullWidth}
        InputProps={{
          inputComponent: NumericFormatCustom,
          readOnly: props.readonly,
          endAdornment: (
            <InputAdornment position="end">
              <Label labelText={props.endAdornment} color="#777" fontSize="0.9rem" />
            </InputAdornment>
          ),
          style: props.disabled === true ? textBoxStyleDisabled : textBoxStyle,
        }}
        inputProps={{
          style: {
            textAlign: getTextAlign(), // props.textAlign ? props.textAlign : "right",
            // paddingLeft: "3px",
            // paddingRight: "3px",
          },
          maxLength: props.maxLength, // ? props.maxLength : getDefaultMaxLength(),
          min: props.minValue ? props.minValue : 0,
          max: props.maxValue,
          // setValue: setValue,
        }}
        InputLabelProps={{ style: { fontSize: "0.8rem" } }}
        id={props.id}
        placeholder={props.placeholder}
        disabled={props.disabled}
        size="small"
        inputRef={props.inputRef}
        onKeyPress={props.onKeyPress}
        value={value || "0"}
        name={props.name}
        onChange={(e) => {
          if (props.onChange) {
            // onChange를 쓰는 경우도 있음
            e.target.value = getValidValue(e);
            props.onChange(e);
          } else {
            props.onChangeCallback && props.onChangeCallback(getValidValue(e));
          }
        }}
        onKeyDown={(event) => {
          // 음수 값 입력 막기
          if (!props.minusValue && event?.key === "-") {
            event.preventDefault();
          }
          return props.onKeydown;
        }}
        error={checkIsError()}
        onBlur={(e) => {
          // minValue보다 작으면 강제로 minValue값 넣기

          if (props.minValue) {
            const val = getValidValue(e);
            if (val < props.minValue) {
              setValue(props.minValue);
              e.target.value = props.minValue;
            }
          }

          if (props.onBlur) {
            props.onBlur(getValidValue(e));
          }
        }}
        autoFocus={props.autoFocus}
        helperText={props.helperText}
        label={props.label}
        autoComplete={props.autoComplete ? props.autoComplete : "off"}
      />
    </Box>
  );
};
export default NumberTextField;
