import { Box } from "@mui/material";
import { checkDateOrder, getDefaultValue } from "components/CommonLib/CommonLib";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import SearchComboBox from "components/LabelInput/SearchComboBox";
import TextField from "components/LabelInput/TextField";
import { useEffect, useState } from "react";

const SearchCondition = ({
  searchParams,
  setSearchParams,
  resetFlag,
  branchList,
  onClickSearch,
}) => {
  // 페이지 상태 관리
  const [isFirstEnterPage, setIsFirstEnterPage] = useState(true);

  // 초기화 시 지점 첫번째 값 세팅
  useEffect(() => {
    if (!isFirstEnterPage && branchList.branch_list.length > 0) {
      setSearchParams("branch_info", branchList.branch_list[0]);
    }
  }, [resetFlag]);

  // 첫 페이지 여부 체크
  useEffect(() => {
    setIsFirstEnterPage(false);
  }, []);

  return (
    <Box
      sx={{
        border: "1px solid #dedede",
        borderRadius: "5px",
        backgroundColor: "#f5f5f5",
        height: "8rem",
        marginTop: "0.5rem",
      }}
    >
      <div style={{ margin: "0.75rem 0.75rem" }}>
        <Label labelText="검색 조건" fontWeight="bold" justifyContent="flex-start" />
        <div
          style={{ display: "flex", marginTop: "0.5rem" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSearch(1);
            }
          }}
        >
          <div style={{ width: "25%" }}>
            <SearchComboBox
              labelText="지점"
              labelWidth="4.5rem"
              labelMarginRight="0rem"
              comboItemWidth="16rem"
              marginRight="4rem"
              data={branchList.branch_name_list}
              defaultValue={getDefaultValue("combo", searchParams.branch_info.branch_name)}
              onChangeCallback={(e, v, idx) => {
                setSearchParams("branch_info", branchList.branch_list[idx]);
              }}
            />
          </div>
          <div style={{ width: "25%", display: "flex" }}>
            <DatePicker
              labelText="등록일"
              labelWidth="4.5rem"
              textBoxWidth="9rem"
              labelMarginRight="0rem"
              textMarginRight="0rem"
              defaultValue={getDefaultValue("field", searchParams.reg_from_date)}
              onChangeCallback={(e) => {
                setSearchParams("reg_from_date", e);
              }}
              onBlur={(e) => {
                if (!checkDateOrder(e, searchParams.reg_to_date)) {
                  setSearchParams("reg_from_date", "");
                }
              }}
            />
            <DatePicker
              marginLeft="0.12rem"
              marginRight="4rem"
              labelWidth="0.5rem"
              textBoxWidth="9rem"
              textMarginRight="0rem"
              labelMarginRight="0.12rem"
              labelText="~"
              defaultValue={getDefaultValue("field", searchParams.reg_to_date)}
              onChangeCallback={(e) => {
                setSearchParams("reg_to_date", e);
              }}
              onBlur={(e) => {
                if (!checkDateOrder(searchParams.reg_from_date, e)) {
                  setSearchParams("reg_to_date", "");
                }
              }}
            />
          </div>
          <div style={{ width: "50%", marginLeft: "2rem" }}>
            <TextField
              labelText="조건명"
              labelWidth="4.5rem"
              labelMarginRight="0rem"
              textMarginRight="0rem"
              fullWidth
              defaultValue={getDefaultValue("field", searchParams.title)}
              onChangeCallback={(e) => {
                setSearchParams("title", e);
              }}
            />
          </div>
        </div>
      </div>
    </Box>
  );
};
export default SearchCondition;
