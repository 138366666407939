import { dataEmpty, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import NormalBtn from "components/NewButton/NormalBtn";
import { HttpInstance } from "lib/HttpLib";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const MemberLocationStatsResultTable = ({ searchResult, curPage, totalPage, onClickSearch }) => {
  const storesDispatch = useDispatch();

  const doSearch = (page) => {
    onClickSearch(page);
  };

  const navigate = useNavigate();
  const onClickMove = (data) => {
    HttpInstance.get(process.env.REACT_APP_BRANCH_SERVER + "/statistics/location/html", {
      params: {
        document_id: data._id,
        branch_id: data.branch_info.branch_id,
      },
    })
      .then((res) => {
        if (restEmpty(res.data.msg)) {
          navigate("/member_location_map", { state: { html: res.data } });
        }
      })
      .catch((err) => {
        console.log(err);
        simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR :", err);
      })
      .finally(() => {});
  };

  const searchResultList = new resultTable(onClickMove, storesDispatch);

  return (
    <div>
      <CustomTable
        columns_head={searchResultList.columns_head}
        table_title={searchResultList.table_title}
        rest_call={searchResultList.get_data_from_rest}
        row_render={searchResultList.create_table}
        rest_data={searchResult}
      />
      <CustomPagination curPage={curPage} totalCnt={totalPage} doSearch={doSearch} />
    </div>
  );
};
export default MemberLocationStatsResultTable;

//
class resultTable {
  table_title = "";
  onClickMove = null;
  dispatch = null;

  constructor(onClickMove, dispatch) {
    this.onClickMove = onClickMove;
    this.dispatch = dispatch;
  }

  joinStringData = (data) => {
    // string array를 받아서, string으로 변환해서 리턴. 구분자는 쉼표
    let result = "";
    if (data.length > 0) {
      result = data.join(", ");
    }
    return result;
  };

  getDataCnt = (data) => {
    return data.length;
  };

  create_table = (data, idx) => {
    return (
      <CustomRow>
        <CustomTableContents>{data.created_at}</CustomTableContents>
        <CustomTableContents>{data.branch_info.branch_name}</CustomTableContents>
        <CustomTableContents>{this.joinStringData(data.reg_type)}</CustomTableContents>
        <CustomTableContents>{this.joinStringData(data.mbshp_option)}</CustomTableContents>
        <CustomTableContents>{data.reg_date_from + " ~ " + data.reg_date_to}</CustomTableContents>
        <CustomTableContents>{this.getDataCnt(data.member_list)}</CustomTableContents>
        <CustomTableContents>
          {
            // 생성결과가 '실패'면 버튼을 눌러서 결과 로그 텍스트 보기?
            data.error_msg !== "" ? (
              <NormalBtn
                theme="red"
                name="실패"
                onClick={() => {
                  simpleAlert(this.dispatch, "오류 내용", data.error_msg);
                }}
              />
            ) : (
              "성공"
            )
          }
        </CustomTableContents>

        <CustomTableContents>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <NormalBtn
              name="보기"
              onClick={() => {
                this.onClickMove(data);
              }}
            />
          </div>
        </CustomTableContents>
      </CustomRow>
    );
  };

  columns_head = [
    { title: "생성일시", width: "15%" },
    { title: "지점명", width: "15%" },
    { title: "등록구분", width: "12%" },
    { title: "회원권 옵션", width: "12%" },
    { title: "등록기간", width: "20%" },
    { title: "갯수", width: "10%" },
    { title: "생성결과", width: "8%" },
    { title: "", width: "8%" },
  ];

  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      stateData = [];
    }
    return stateData;
  };
}
