import IconButton from "components/Button/IconButton";
import { simpleAlert } from "components/CommonLib/CommonLib";
import { downloadFile } from "components/File/downloadFile";
import TextField from "components/LabelInput/TextField";
import _ from "lodash";
import { getIsViewLock } from "pages/ApprovalManage/ApprovalCommon/checker";
import { useDispatch } from "react-redux";

const FileUploadBox = ({ fileGroupId, nowService, mode, fileList, setFileList }) => {
  const storesDispatch = useDispatch();

  // const [postRowState, setPostRowState] = useState(getPostRowState(nowService));
  // const [file, setFile] = useState();

  // useEffect(() => {
  //   if (fileGroupId) {
  //     loadFiles();
  //   }
  // }, [fileGroupId]);

  // const loadFiles = () => {
  //   if (nowService === "전자결재") {
  //     getUploadedFiles(storesDispatch, fileGroupId, setFileList);
  //   } else {
  //     getUploadedFiles(storesDispatch, fileGroupId, setFileList); // 게시판?
  //   }
  // };

  const onUploadedFile = (e) => {
    // 중복 파일이면 이 함수에 들어오지 않음..
    // if (fileList.findIndex((data) => data.name === e.target.files[0].name) !== -1) {
    //   simpleAlert(storesDispatch, "알림", "이미 업로드된 파일입니다.");
    //   return;
    // }

    if (e.target.files.length > 0) {
      const newFile = _.cloneDeep(fileObjectFormat);
      newFile.file = e.target.files[0];
      newFile.file_info.file_name = e.target.files[0].name;
      newFile.file_info.file_group_id = fileGroupId;

      setFileList([...fileList, newFile]);
      // simpleAlert(storesDispatch, "알림", "파일 업로드가 완료되었습니다.");
    }
  };

  const onDeleteFile = (data, idx) => {
    const doDelete = () => {
      setFileList(fileList.filter((_, index) => index !== idx));
    };

    simpleAlert(storesDispatch, "알림", "파일을 삭제하시겠습니까?", false, undefined, doDelete);
  };

  return (
    <div
      style={{
        marginTop: "3.5rem",
        display: "inline-block",
        width: "100%",
        padding: "0.7rem",
        boxSizing: "border-box",
        border: "1px solid #BBBBBB",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          paddingLeft: "1rem",
          paddingBottom: "0.5rem",
        }}
      >
        <div
          style={{
            display: "inline-block",
            width: "100%",
          }}
        >
          {fileList.map((data, idx) => (
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
              key={`file-${idx}`}
            >
              <TextField
                // labelWidth={"2.2rem"}
                textBoxWidth={"20rem"}
                labelMarginRight={"0rem"}
                textMarginRight={"0rem"}
                labelText=""
                defaultValue={decodeURIComponent(data.file_info.file_name)}
                disabled
              />
              <IconButton
                type="download"
                width="2.2rem"
                onClick={() => {
                  downloadFile(storesDispatch, data);
                }}
              />
              <IconButton
                type="deleteBox"
                width="2.2rem"
                onClick={() => {
                  onDeleteFile(data, idx);
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ height: "5rem" }}>
          {getIsViewLock(mode) ? (
            <div></div>
          ) : (
            <>
              <input id="fileInput" hidden type="file" onChange={onUploadedFile} />
              <IconButton
                marginLeft="1rem"
                type="upload"
                onClick={() => {
                  document.getElementById("fileInput").click();
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUploadBox;

export const fileObjectFormat = {
  file: null, // 업로드 API에서 사용하는 파일 원본
  file_info: {
    file_id: "000000000000000000000000",
    file_group_id: "000000000000000000000000",
    file_name: "",
    url_path: "",
    file_due_date: "",
    doc_name: "",
    reason: "",
    belong_type: "",
    user_id: "000000000000000000000000",
  },
  owner_info: null,
  is_owner_set_up: false,
  is_missed_file: false,
  is_full_doc: false,
  purchase_code: "",
  is_use: false,
  reg_info: null,
};
