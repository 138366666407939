import { makeStyles } from "@material-ui/styles";

export const btnStyles = makeStyles((theme) => ({
  buttonType1: {
    width: "3rem",
    height: "2.25rem",
  },
  buttonType2: {
    width: "3.375rem",
    height: "2.25rem",
  },
  buttonType3: {
    width: "4.188rem",
    height: "2.25rem",
  },
  buttonType4: {
    width: "5rem",
    height: "2.25rem",
  },
  buttonType5: {
    width: "5.25rem",
    height: "2.25rem",
  },
  buttonType6: {
    width: "6.25rem",
    height: "2.25rem",
  },
  buttonType7: {
    width: "7.25rem",
    height: "2.25rem",
  },
  buttonType8: {
    width: "8.5rem",
    height: "2.25rem",
  },
  buttonType9: {
    width: "9.5rem",
    height: "2.25rem",
  },
  buttonType10: {
    width: "10.5rem",
    height: "2.25rem",
  },
  buttonType11: {
    width: "11.5rem",
    height: "2.25rem",
  },
  buttonType12: {
    width: "12.5rem",
    height: "2.25rem",
  },
  buttonType99: {
    width: "100%",
    height: "2.25rem",
  },

  disabled: {
    "&.Mui-disabled": {
      color: "#fff",
      backgroundColor: "#c6c6c6",
      border: "none",
    },
  },
  btnRoot: {
    borderRadius: "5px",
    backgroundColor: "#032063",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#fff",
    "&:hover": {
      background: "#2b4b8b",
    },
  },
  btnRootRaw: {
    zIndex: 10,
    borderRadius: "5px",
    backgroundColor: "#032063",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#fff",
    "&:hover": {
      background: "#2b4b8b",
    },
  },
  btnRootWhite: {
    borderRadius: "5px",
    border: "1px solid #032063",
    backgroundColor: "#FFFFFF",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#032063",
    // "&:hover": {
    //   background: "#2b4b8b",
    // },
  },

  btnExcel: {
    borderRadius: "5px",
    backgroundColor: "#217346",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "400",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#FFFFFF",
    "&:hover": {
      background: "#217346",
    },
  },
  btnRootGrayWhite: {
    borderRadius: "5px",
    border: "1px solid #BBBBBB",
    backgroundColor: "#FFFFFF",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#222222",
    // "&:hover": {
    //   background: "#2b4b8b",
    // },
  },
  //
  //
  btnMarginTop: {
    marginTop: "0.625rem",
  },
  btnMarginRight: {
    marginRight: "0.225rem",
  },
  btnMinusTop: {
    top: "-0.325rem",
  },
  btnGrpActive: {
    borderRadius: "0",
    backgroundColor: "#032063",

    fontFamily: "AppleSDGothicNeo",
    fontSize: "0.938rem",
    fontWeight: "400",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#fff",

    border: "1px solid #b1bdff",
    "&:hover": {
      background: "#2B4B8B",
      color: "#fff",
    },
  },
  btnGrpDeactive: {
    backgroundColor: "#fff",
    color: "#7e7e7e",
    border: "1px solid #b9b9b9",
  },
  btnGrpDisabled: {
    backgroundColor: "#bbb1d8",
    color: "#490000",
    border: "1px solid #acacac",
  },
}));

export const btnSubmitStyles = makeStyles((theme) => ({
  buttonType2: {
    width: "23.312rem",
    height: "3.125rem",
  },
  buttonType1: {
    width: "20.25rem",
    height: "2.25rem",
    backgroundColor: "#032063",
    color: "#fff",
    marginTop: "1.25rem",
  },
  btnRoot: {
    marginTop: "0.625rem",
    borderRadius: "5px",
    border: "solid 2px #032063",
    backgroundColor: "#fff",
    fontFamily: "AppleSDGothicNeo",
    fontSize: "1.125rem",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#032063",
  },
  disabled: {
    border: "solid 2px #999da5",
  },
}));
