import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { contentTypeJson, dateToStr, restEmpty, simpleAlert } from "components/CommonLib/CommonLib";
import DatePicker from "components/LabelInput/DatePicker";
import Label from "components/LabelInput/Label";
import NumberTextField from "components/LabelInput/NumberTextField";
import RadioGroup from "components/LabelInput/RadioGroup";
import TextField from "components/LabelInput/TextField";
import Loading from "components/Loading/Loading/Loading";
import ModalPopupLocal from "components/ModalPopup/ModalPopupLocal";
import SubTitle from "components/PageTitle/SubTitle";
import { HttpInstance } from "lib/HttpLib";
import _ from "lodash";
import { globalAlertOn } from "modules/actions/Alert";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SearchStaffModal from "./SearchStaffModal";

const INIT = {
  etc_id: "",
  etc_info: {
    reason: "",
    salary_date: 0,
    wage: 0,
  },
  personal_info: {
    account_holder: "",
    account_no: "",
    address_1: "",
    address_2: "",
    bank: "",
    birthday: "",
    email: "",
    gender: "",
    phone: "",
    sabun: "",
    social_number: "",
    staff_name: "",
    verify: false,
    zip_code: "",
  },
  staff_id: "",
};

const EtcSalaryAddModal = ({ modalParam, onModalDone }) => {
  const storesDispatch = useDispatch();
  const label_width = "4rem";
  const box_width = "9rem";
  const btnStyleClass = btnStyles();
  const viewModelRef = useRef();
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: "",
    Content: "",
    callback: null,
    Buttons: undefined,
  });
  const [radioMode, setRadioMode] = useState("");
  const [hqBranch, setHqBranch] = useState({
    branch_list: [[]],
    hq_list: [],
    target_list: [],
  });
  const [searchedInfo, setSearchedInfo] = useState({
    staff_pos: "",
  });

  const [newInfo, setNewInfo] = useState(_.cloneDeep(INIT));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getStaffSearchHqCombo();
  }, []);

  useEffect(() => {
    return () => {
      setNewInfo(_.cloneDeep(INIT));
      setSearchedInfo({ staff_pos: "" });
    };
  }, [radioMode]);

  const localModalDone = (data) => {
    setSearchedInfo((cur) => {
      return { ...cur, staff_pos: data.base_info.position.org_name };
    });
    setNewInfo((cur) => {
      const obj = { ...cur };
      obj.personal_info.sabun = data.personal_info.sabun;
      obj.personal_info.staff_name = data.personal_info.staff_name;
      obj.staff_id = data.staff_id;
      obj.work_info_id = data.work_info_id; // work_info_id 추가
      return obj;
    });

    viewModelRef.current.setClose();
  };

  const popSearchStaffModal = (title, param, onModalDone) => {
    setModalInfo({
      show: true,
      title: title,
      Content: <SearchStaffModal modalParam={param} onModalDone={localModalDone} />,
      callback: null,
      Buttons: undefined,
    });
  };

  const getStaffSearchHqCombo = async () => {
    try {
      setLoading(true);
      const res = await HttpInstance.get(
        process.env.REACT_APP_STAFF_SERVER + "/staff/search_view",
        {
          params: {
            hq_only_add: true,
          },
        },
      );
      if (!restEmpty(res.data.data)) {
        setHqBranch(res.data.data);
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", "GET DATA ERROR");
    } finally {
      setLoading(false);
    }
  };

  //기타수당 추가 POST
  const addEtcSalary = async () => {
    try {
      const formData = new FormData();
      formData.append("new_info", JSON.stringify(newInfo));
      setLoading(true);

      const res = await HttpInstance.post(
        process.env.REACT_APP_SALARY_SERVER +
          "/salary/etc/add?salary_report_id=" +
          modalParam.salaryReportId,
        formData,
        contentTypeJson,
      );
      if (res.data.msg) {
        simpleAlert(storesDispatch, "ERROR", res.data.msg);
      } else {
        //성공
        simpleAlert(
          storesDispatch,
          "알림",
          "등록되었습니다.\n\n새로고침 해주시기 바랍니다.",
          false,
          onModalDone,
        );
      }
    } catch (err) {
      console.log(err);
      simpleAlert(storesDispatch, "ERROR", err.response.data ? err.response.data.msg : err.code);
    } finally {
      setLoading(false);
    }
  };

  const validationCheck = () => {
    if (radioMode === "외부 인원" || !radioMode) {
      if (newInfo.personal_info.staff_name === "") {
        simpleAlert(storesDispatch, "알림", "이름을 입력해주세요.");
      } else if (!newInfo.personal_info.social_number) {
        simpleAlert(storesDispatch, "알림", "주민번호를 입력해주세요.");
      } else if (!newInfo.personal_info.bank) {
        simpleAlert(storesDispatch, "알림", "은행을 입력해주세요.");
      } else if (!newInfo.personal_info.account_holder) {
        simpleAlert(storesDispatch, "알림", "예금주를 입력해주세요.");
      } else if (!newInfo.personal_info.account_no) {
        simpleAlert(storesDispatch, "알림", "계좌번호를 입력해주세요.");
      } else if (!newInfo.etc_info.salary_date) {
        simpleAlert(storesDispatch, "알림", "급여일을 입력해주세요.");
      } else if (!newInfo.etc_info.wage) {
        simpleAlert(storesDispatch, "알림", "급여액을 입력해주세요.");
      } else if (!newInfo.etc_info.reason) {
        simpleAlert(storesDispatch, "알림", "사유를 입력해주세요.");
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText="등록 하시겠습니까?" />,
            onBtnYes: () => {
              addEtcSalary();
            },
            onBtnNo: () => {},
          }),
        );
      }
    } else {
      if (!newInfo.personal_info.sabun) {
        simpleAlert(storesDispatch, "알림", "직원을 선택해주세요.");
      } else if (!newInfo.etc_info.salary_date) {
        simpleAlert(storesDispatch, "알림", "급여일을 입력해주세요.");
      } else if (!newInfo.etc_info.wage) {
        simpleAlert(storesDispatch, "알림", "급여액을 입력해주세요.");
      } else if (!newInfo.etc_info.reason) {
        simpleAlert(storesDispatch, "알림", "사유를 입력해주세요.");
      } else {
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: <Label labelText="등록 하시겠습니까?" />,
            onBtnYes: () => {
              addEtcSalary();
            },
            onBtnNo: () => {},
          }),
        );
      }
    }
  };

  const getTargetYear = () => {
    return modalParam.baseDate ? parseInt(modalParam.baseDate) : new Date().getFullYear();
  };

  const getTargetMonth = () => {
    return modalParam.baseDate
      ? parseInt(modalParam.baseDate.split("-")[1]) - 1
      : new Date().getMonth();
  };

  return (
    <div>
      {loading && <Loading />}
      <ModalPopupLocal ref={viewModelRef} params={modalInfo} />

      <div style={{ display: "flex" }}>
        <IconButton type="border" />
        <div style={{ width: "0.5rem" }}></div>
        <SubTitle titleText="직원 정보" />
      </div>

      <div style={{ display: "flex" }}>
        <RadioGroup
          data={["외부 인원", "임직원"]}
          isMulti={true}
          labelWidth="2rem"
          labelText="구분"
          labelMarginLeft="1rem"
          radioMarginLeft="2rem"
          radioMarginRight="0.5rem"
          defaultValue={"외부 인원"}
          onChangeCallback={(e, v) => {
            setRadioMode(v);
          }}
        />
        {radioMode === "임직원" ? (
          <IconButton
            type="search"
            width="1.6rem"
            height="1.6rem"
            onClick={() => {
              popSearchStaffModal("직원검색", hqBranch);
            }}
          />
        ) : (
          ""
        )}
      </div>

      {radioMode === "임직원" ? (
        <div style={{ display: "flex", marginTop: "0.7rem" }}>
          <TextField
            labelMarginLeft="1rem"
            labelWidth={label_width}
            textMarginRight="2rem"
            textBoxWidth={box_width}
            labelText="사번"
            defaultValue={newInfo.personal_info.sabun}
            disabled
          />
          <TextField
            labelWidth={label_width}
            textMarginRight="2rem"
            textBoxWidth={box_width}
            labelText="직원명"
            defaultValue={newInfo.personal_info.staff_name}
            disabled
          />
          <TextField
            labelWidth={label_width}
            textBoxWidth={box_width}
            defaultValue={searchedInfo.staff_pos}
            labelText="직책"
            disabled
          />
        </div>
      ) : (
        <div style={{ marginTop: "0.7rem" }}>
          <div style={{ display: "flex" }}>
            <TextField
              labelMarginLeft="1rem"
              labelWidth={label_width}
              textMarginRight="2rem"
              textBoxWidth={box_width}
              labelText="이름"
              defaultValue={newInfo.personal_info.staff_name}
              onBlur={(e) => {
                setNewInfo((cur) => {
                  const obj = { ...cur };
                  obj.personal_info.staff_name = e.target.value;
                  return obj;
                });
              }}
            />
            <TextField
              labelWidth={label_width}
              textMarginRight="2rem"
              textBoxWidth={box_width}
              labelText="주민번호"
              defaultValue={newInfo.personal_info.social_number}
              onBlur={(e) => {
                setNewInfo((cur) => {
                  const obj = { ...cur };
                  obj.personal_info.social_number = e.target.value;
                  return obj;
                });
              }}
            />
          </div>
          <div style={{ display: "flex", marginTop: "0.7rem" }}>
            <TextField
              labelMarginLeft="1rem"
              labelWidth={label_width}
              textMarginRight="2rem"
              textBoxWidth={box_width}
              labelText="은행"
              defaultValue={newInfo.personal_info.bank}
              onBlur={(e) => {
                setNewInfo((cur) => {
                  const obj = { ...cur };
                  obj.personal_info.bank = e.target.value;
                  return obj;
                });
              }}
            />
            <TextField
              labelWidth={label_width}
              textMarginRight="2rem"
              textBoxWidth={box_width}
              labelText="예금주"
              defaultValue={newInfo.personal_info.account_holder}
              onBlur={(e) => {
                setNewInfo((cur) => {
                  const obj = { ...cur };
                  obj.personal_info.account_holder = e.target.value;
                  return obj;
                });
              }}
            />
            <TextField
              labelWidth={label_width}
              textMarginRight="2rem"
              textBoxWidth={box_width}
              labelText="계좌번호"
              defaultValue={newInfo.personal_info.account_no}
              onBlur={(e) => {
                setNewInfo((cur) => {
                  const obj = { ...cur };
                  obj.personal_info.account_no = e.target.value;
                  return obj;
                });
              }}
            />
          </div>
        </div>
      )}

      <div style={{ marginTop: "0.7rem" }}>
        <div style={{ display: "flex" }}>
          <IconButton type="border" />
          <div style={{ width: "0.5rem" }}></div>
          <SubTitle titleText="수당 정보" />
        </div>
        <div style={{ display: "flex", marginBottom: "0.7rem" }}>
          <DatePicker
            labelMarginLeft="1rem"
            labelWidth={label_width}
            minWidth={box_width}
            labelText="급여일"
            textMarginRight="2rem"
            minDate={dateToStr(new Date(getTargetYear(), getTargetMonth() + 1, 1))} // 다음 달 1일
            maxDate={dateToStr(new Date(getTargetYear(), getTargetMonth() + 2, 0))} // 다음 달 마지막 날
            defaultValue={newInfo.etc_info.salary_date}
            onChangeCallback={(e) => {
              setNewInfo((cur) => {
                const obj = { ...cur };
                obj.etc_info.salary_date = e;
                return obj;
              });
            }}
          />
          <NumberTextField
            labelWidth={label_width}
            textBoxWidth={box_width}
            endAdornment={"원"}
            labelText="급여액"
            defaultValue={newInfo.etc_info.wage}
            onBlur={(e) => {
              setNewInfo((cur) => {
                const obj = { ...cur };
                obj.etc_info.wage = e;
                return obj;
              });
            }}
          />
        </div>
        <TextField
          labelMarginLeft="1rem"
          labelWidth={label_width}
          textBoxWidth={"24.5rem"}
          labelText="사유"
          defaultValue={newInfo.etc_info.reason}
          onBlur={(e) => {
            setNewInfo((cur) => {
              const obj = { ...cur };
              obj.etc_info.reason = e.target.value;
              return obj;
            });
          }}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType4]: true,
          })}
          onClick={validationCheck}
        >
          등록
        </Button>
      </div>
    </div>
  );
};
export default EtcSalaryAddModal;
