import { Button } from "@material-ui/core";
import clsx from "clsx";
import IconButton from "components/Button/IconButton";
import { btnStyles } from "components/ButtonStyle/ButtonStyle";
import { dataEmpty } from "components/CommonLib/CommonLib";
import CustomTable from "components/CustomTable/CustomTableTypeA";
import { CustomRow, CustomTableContents } from "components/CustomTable/tableStyle";
import Label from "components/LabelInput/Label";
import TimePicker from "components/LabelInput/TimePicker";
import NormalBtn from "components/NewButton/NormalBtn";
import { globalAlertOn } from "modules/actions/Alert";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";

//스케줄 시간수정 모달
const ScheduleTimeEditModal = ({ modalParam, onModalDone }) => {
  //modalParam : idx(요일인덱스), timeArr
  const storesDispatch = useDispatch();
  const btnStyleClass = btnStyles();
  const [timeList, setTimeList] = useState(modalParam.timeArr);

  const fromTimeRef = useRef([]);
  const toTimeRef = useRef([]);

  //추가 BTN (ROW 생성)
  const btnItemPush = () => {
    setTimeList((cur) => {
      const arr = [...cur];
      arr.push({ from_time: "", to_time: "" });
      return arr;
    });
  };

  //삭제 BTN (ROW 삭제)
  const btnItemPop = (idx) => {
    setTimeList((cur) => {
      const arr = [...cur];
      arr.splice(idx, 1);
      return arr;
    });
  };

  const timeOverlayCheck = (data, idx) => {
    //data는 바껴서 선택된 시간
    //현재 idx = 2 = > 0,1,3,4 번째랑 다 비교해야함
    //배열의 길이는 5
    let resultArr = [];
    const checkNo = Number(data.slice(0, 2) + data.slice(3, 5)); //8
    for (let i = 0; i < timeList.length; i++) {
      if (i === idx) {
        //
      } else {
        const from = Number(timeList[i].from_time.slice(0, 2) + timeList[i].from_time.slice(3, 5));
        const to = Number(timeList[i].to_time.slice(0, 2) + timeList[i].to_time.slice(3, 5));

        if (from <= checkNo && to >= checkNo) {
          resultArr.push(true);
        } else {
          resultArr.push(false);
        }
      }
    }

    return resultArr;
  };

  //FROM_TIME onBlur 함수
  const btnItemEditFrom = (data, idx, ref) => {
    const startNo = Number(data.slice(0, 2) + data.slice(3, 5));
    const endNo = Number(timeList[idx].to_time.slice(0, 2) + timeList[idx].to_time.slice(3, 5));
    //1.시작시간이 설정되면 시간겹침 테스트부터
    const result = timeOverlayCheck(data, idx);
    const overlapIdx = result.findIndex((el) => el === true);
    if (overlapIdx !== -1) {
      //겹치는게 있어
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="중복되는 시간이 존재합니다." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
      ref.current[idx].focus();
    } else {
      //겹침테스트 통과
      //대소비교 테스트 해야지
      if (startNo >= endNo && startNo !== 0 && endNo !== 0) {
        //대소비교 탈락
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: (
              <>
                <Label labelText="출입 종료 시간이 시작 시간보다 빠릅니다." />
              </>
            ),
            onBtnOk: () => {},
          }),
        );
        ref.current[idx].focus();
      } else {
        //대소비교 통과
        setTimeList((cur) => {
          const arr = [...cur];
          arr[idx].from_time = data;
          return arr;
        });
      }
    }
  };

  //TO_TIME onBlur 함수
  const btnItemEditTo = (data, idx, ref) => {
    const endNo = Number(data.slice(0, 2) + data.slice(3, 5));
    const startNo = Number(
      timeList[idx].from_time.slice(0, 2) + timeList[idx].from_time.slice(3, 5),
    );

    if (timeList[idx].from_time === "") {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="시작시간을 입력해주세요" />
            </>
          ),
          onBtnOk: () => {
            setTimeList((cur) => {
              const arr = [...cur];
              arr[idx].to_time = "";
              return arr;
            });
          },
        }),
      );

      //시작타임에 포커스
    } else {
      //1. 시작시간과 대소비교
      if (startNo >= endNo) {
        //대소비교 탈락
        storesDispatch(
          globalAlertOn({
            show: true,
            titleText: "알림",
            bodyText: (
              <>
                <Label labelText="출입 종료 시간이 시작 시간보다 빠릅니다." />
              </>
            ),
            onBtnOk: () => {},
          }),
        );
        ref.current[idx].focus();
      } else {
        //대소비교 통과
        //2. 시간겹침 테스트
        const result = timeOverlayCheck(data, idx);
        const overlapIdx = result.findIndex((el) => el === true);
        if (overlapIdx !== -1) {
          //겹치는거 있음
          storesDispatch(
            globalAlertOn({
              show: true,
              titleText: "알림",
              bodyText: (
                <>
                  <Label labelText="중복되는 시간이 존재합니다." />
                </>
              ),
              onBtnOk: () => {},
            }),
          );
          ref.current[idx].focus();
        } else {
          //겹치기 없어
          setTimeList((cur) => {
            const arr = [...cur];
            arr[idx].to_time = data;
            return arr;
          });
        }
      }
    }
  };

  //저장버튼 validation
  const btnApply = () => {
    const isEmpty = timeList.some((el) => !el.from_time || !el.to_time);
    if (timeList.length === 0) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="최소 1개이상 시간을 추가해주세요." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else if (isEmpty) {
      storesDispatch(
        globalAlertOn({
          show: true,
          titleText: "알림",
          bodyText: (
            <>
              <Label labelText="입력되지 않은 시간이 있습니다." />
            </>
          ),
          onBtnOk: () => {},
        }),
      );
    } else {
      //저장
      onModalDone(timeList, modalParam.idx);
    }
  };

  const scheduleTimeInfoTableItem = new scheduleTimeInfoTable(
    btnItemPop,
    btnItemEditFrom,
    btnItemEditTo,
    fromTimeRef,
    toTimeRef,
  );

  return (
    <div
      style={{
        width: "40rem",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button
          className={clsx({
            [btnStyleClass.btnRoot]: true,
            [btnStyleClass.buttonType5]: true,
          })}
          onClick={() => btnItemPush()}
        >
          추가
        </Button>
      </div>

      <div style={{ marginTop: "0.5rem" }}>
        <CustomTable
          columns_head={scheduleTimeInfoTableItem.columns_head}
          table_title={scheduleTimeInfoTableItem.table_title}
          rest_call={scheduleTimeInfoTableItem.get_data_from_rest}
          row_render={scheduleTimeInfoTableItem.create_table}
          rest_data={timeList}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "0.7rem" }}>
        <NormalBtn name="저장" onClick={() => btnApply()} />
      </div>
    </div>
  );
};
export default ScheduleTimeEditModal;

class scheduleTimeInfoTable {
  table_title = "";
  btnStyleClass = btnStyles();
  btnItemPop = null;
  btnItemEditFrom = null;
  btnItemEditTo = null;
  fromTimeRef = null;
  toTimeRef = null;

  constructor(btnItemPop, btnItemEditFrom, btnItemEditTo, fromTimeRef, toTimeRef) {
    this.btnItemPop = btnItemPop;
    this.btnItemEditFrom = btnItemEditFrom;
    this.btnItemEditTo = btnItemEditTo;
    this.fromTimeRef = fromTimeRef;
    this.toTimeRef = toTimeRef;
  }

  create_table = (data, idx) => {
    return (
      <CustomRow key={idx}>
        <CustomTableContents>{idx}</CustomTableContents>
        <CustomTableContents>
          <TimePicker
            inputRef={(el) => (this.fromTimeRef.current[idx] = el)}
            textBoxWidth="10rem"
            defaultValue={data.from_time}
            onBlur={(e) => {
              this.btnItemEditFrom(e, idx, this.fromTimeRef);
            }}
          />
        </CustomTableContents>
        <CustomTableContents>
          <TimePicker
            inputRef={(el) => (this.toTimeRef.current[idx] = el)}
            textBoxWidth="10rem"
            defaultValue={data.to_time}
            onBlur={(e) => {
              this.btnItemEditTo(e, idx, this.toTimeRef);
            }}
          />
        </CustomTableContents>

        <CustomTableContents>
          <IconButton
            type="delete"
            onClick={() => {
              this.btnItemPop(idx, data);
            }}
          />
        </CustomTableContents>
      </CustomRow>
    );
  };
  columns_head = [
    { title: "NO", width: "15%" },
    { title: "시작 시간", width: "35%" },
    { title: "종료 시간", width: "35%" },
    { title: "", width: "15%" },
  ];
  get_data_from_rest = (stateData) => {
    if (dataEmpty(stateData)) {
      return [];
    }
    return stateData;
  };
}
